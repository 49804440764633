import React from 'react';
import { VendorInvoice } from 'utils';
import UploadSuccessIcon from '../icons/UploadSuccessIcon';
import ChatIcon from '../icons/ChatIcon';
import moment from 'moment';
import { truncateString } from 'utils/format';
import UploadedDocumentPreview from 'components/modals/UploadedDocumentPreviewModal';

interface Props {
  invoice: VendorInvoice;
}
const InvoiceItem: React.FC<Props> = ({ invoice }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const flattenedDocs = invoice?.supportingDocuments?.flat(Infinity);

  const invoiceDoc = (
    flattenedDocs?.[0]?.file_locations?.[0] || invoice?.documentUrl
  )
    ?.split('/')
    ?.pop() as string;
  const purchaseOrderDoc = flattenedDocs?.[1]?.file_locations?.[0]
    ?.split('/')
    ?.pop() as string;

  function handleSuccessCancel() {
    setIsOpen(false);
  }
  return (
    <>
      <div className="p-6 space-y-4 border rounded-lg">
        <div className="pb-2 space-y-2 border-b">
          <div className="flex items-center justify-between">
            <span className="font-medium text-[16px] text-[#07000F]">
              Doc_#{invoice.invoiceNumber}
            </span>
            <span>...</span>
          </div>
          <div>
            <span className="font-normal text-xs text-[#5C696E]">
              {moment(invoice.createdAt).fromNow()}
            </span>
          </div>
          <div className="flex items-center justify-between">
            {invoice.status === 'PENDING' && (
              <span className="bg-[#FDEFCB] text-[#D97724] font-medium text-[10px] inline-block p-2 rounded">
                {invoice.status}
              </span>
            )}
            {invoice.status === 'APPROVED' && (
              <span className="bg-[#BBE5B3] text-[#414F3E] font-medium text-[10px] inline-block p-2 rounded">
                {invoice.status}
              </span>
            )}
            {invoice.status === 'DECLINED' && (
              <span className="bg-[#CFE0F5] text-[#087FCA] font-medium text-[10px] inline-block p-2 rounded">
                {invoice.status}
              </span>
            )}
            <ChatIcon />
          </div>
        </div>
        <div
          className="flex justify-center rounded-lg h-24 items-center bg-[#EDF8F6] text-[#006355] font-medium text-xs cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <UploadSuccessIcon />
          <span>{truncateString(invoiceDoc, 13)}</span>
        </div>
      </div>
      <UploadedDocumentPreview
        invoice={invoice}
        isOpen={isOpen}
        handleSuccessCancel={handleSuccessCancel}
        purchaseOrderDoc={purchaseOrderDoc}
        invoiceDoc={invoiceDoc}
      />
    </>
  );
};

export default InvoiceItem;
