import classNames from 'classnames';
import Pagination from 'components/common/Pagination/Pagination';
import TransactionCard from 'components/common/TransactionCard';
import { useAuth } from 'contexts/AuthContext';
import { getTransactions, getVendorInvoices } from 'functions/vendor-functions';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FilterInterface,
  PaginationOnChange,
  ITransactions,
  VendorType,
} from 'utils';
import UploadDocumentIcon from 'components/icons/UploadDocumentIcon.';

export default function Transactions() {
  const [transactions, setTransactions] = useState<ITransactions[]>([]);
  const [invoices, setInvoices] = useState<VendorType[]>([]);
  const [refreshInvoices, setRefreshInvoices] = useState(false);

  const [filters, setFilters] = useState<FilterInterface>({
    page: 1,
    perPage: 20,
    limit: 20,
    pageCount: 1,
  });
  const [, setIsLoading] = useState(false);
  const { logout } = useAuth();

  const handlePaginationChange = (e: PaginationOnChange) => {
    setFilters({ ...filters, page: e.selected + 1 });
  };
  const handlePageDropdown = (e: string) => {
    setFilters({ ...filters, limit: Number(e), page: 1 });
  };

  const fetchTransactions = async () => {
    setIsLoading(true);
    const { data, responseError, refreshError } = await getTransactions();

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    if (data) {
      setTransactions(data?.transactions?.reverse());
      setIsLoading(false);
    }

    if (refreshError) {
      logout();
      return;
    }
    return data as ITransactions[];
  };

  const getInvoice = async () => {
    setIsLoading(true);
    const { data, responseError, refreshError } = await getVendorInvoices({
      isBill: true,
      filters,
    });
    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    setInvoices(data?.vendorInvoices);
    setIsLoading(false);
    setRefreshInvoices(false);

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }
    return data as VendorType[];
  };

  useEffect(() => {
    fetchTransactions();
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, refreshInvoices]);

  const totalAmount = transactions.reduce((acc, obj) => {
    return acc + obj.amount;
  }, 0);
  const pendingAmount = transactions
    .filter((txn) => txn.status === 'pending')
    .reduce((acc, obj) => {
      return acc + obj.amount;
    }, 0);

  return (
    <>
      {Array.isArray(transactions) && transactions.length !== 0 ? (
        <>
          <div className="grid gap-5 overflow-auto md:grid-cols-3">
            <TransactionCard>
              <p className="text-[#5C696E] py-1">TOTAL AMOUNT RECEIVED</p>
              <p className="py-2 font-semibold">
                ₦
                {totalAmount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                }) || '0.00'}
              </p>
              <p className="text-[#5C696E] text-xs">Last 30 days</p>
            </TransactionCard>
            <TransactionCard>
              <p className="text-[#5C696E] py-1">PENDING PAYMENT</p>
              <p className="py-2 font-semibold">
                {' '}
                ₦
                {pendingAmount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                }) || '0.00'}
              </p>
              <p className="text-[#5C696E] text-xs">
                {' '}
                From {invoices.length} invoices
              </p>
            </TransactionCard>
            <TransactionCard>
              <p className="text-[#5C696E] py-1">
                TOTAL NUMBER OF TRANSACTIONS
              </p>
              <p className="py-2 font-semibold">{transactions.length}</p>
              <p className="text-[#5C696E] text-xs">Last 30 days</p>
            </TransactionCard>
          </div>
          <section className="mt-20">
            <div>
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr className="bg-[#F8F9F9] text-xs font-semibold text-[#07000F]">
                    <th className="px-2 py-4">DATE</th>
                    <th>NAME</th>
                    <th>AMOUNT</th>
                    <th>DESCRIPTION</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((txns, index) => (
                    <tr
                      key={txns?.transactionRef + index}
                      className="border-b-2 text-sm font-normal text-[#5C696E] cursor-pointer"
                    >
                      <td className="px-2 py-4">
                        {moment(txns?.date).format('DD/MM/yyyy')}
                      </td>
                      <td>{txns?.receiverDetails?.accountName || '-'}</td>
                      <td>
                        ₦
                        {txns?.amount?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        }) || '0.00'}
                      </td>
                      <td>{txns?.description || '-'}</td>
                      <td>
                        <div
                          className={classNames(
                            'font-medium text-[10px] inline-block px-2 rounded',
                            {
                              'bg-[#FDEFCB] text-[#D97724]':
                                txns?.status?.toLowerCase() === 'pending',
                              'bg-[#BBE5B3] text-[#414F3E] ':
                                txns?.status?.toLowerCase() === 'successful',
                              'bg-[#fbe5e5] text-[#ed5b5c]':
                                txns?.status?.toLowerCase() === 'failed',
                            }
                          )}
                        >
                          {txns?.status}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              selectedOption={String(filters.page)}
              currentPage={filters.page}
              handlePaginationChange={handlePaginationChange}
              pageCount={filters.pageCount}
              setSelectedOption={handlePageDropdown}
              list={transactions.length}
            />
          </section>
        </>
      ) : (
        <div className="flex items-center justify-center h-[400px]">
          <div className="flex flex-col items-center justify-center space-y-4">
            <UploadDocumentIcon />
            <h1 className="text-[#07000F] font-semibold text-[20px]">
              No transaction have occured yet
            </h1>
            <span className="font-normal text-sm text-[#484847]">
              When you make a transaction, it shows up here
            </span>
          </div>
        </div>
      )}
    </>
  );
}
