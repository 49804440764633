import React, { useEffect } from 'react';
import BackButtonIcon from '../icons/BackButtonIcon';
import {
  BillTransactions,
  FilterInterface,
  PaginationOnChange,
  VendorType,
} from 'utils';
import { useVendor } from 'contexts/VendorContext';
import moment from 'moment';
import classNames from 'classnames';
import { getBillsTransactions } from 'functions/requests';
import EmptyInvoice from 'assets/png/empty-invoice.png';
import toast from 'react-hot-toast';
import Pagination from 'components/common/Pagination/Pagination';
import { InvoicePreview } from './InvoicePreview';

interface Props {
  isVisible: boolean;
  handleCancel: () => void;
  invoice: VendorType | undefined;
}
const InvoiceBreakdown: React.FC<Props> = ({
  isVisible = false,
  handleCancel,
  invoice,
}) => {
  const { vendor } = useVendor();
  const [isInvoiceTable, setInvoiceTable] = React.useState(false);

  const [filters, setFilters] = React.useState<FilterInterface>({
    page: 1,
    perPage: 20,
    limit: 20,
    pageCount: 1,
  });

  const handlePaginationChange = (e: PaginationOnChange) => {
    setFilters({ ...filters, page: e.selected + 1 });
  };
  const handlePageDropdown = (e: string) => {
    setFilters({ ...filters, limit: Number(e), page: 1 });
  };

  const [bills, setBills] = React.useState<BillTransactions[]>();

  const getBills = async () => {
    const { data, error } = await getBillsTransactions({
      business_id: invoice?.businessId as string,
      billNumber: invoice?.billNumber as string,
      filters: filters,
    });

    if (error) {
      toast.error('Unable to fetch Bill Transactions');
    }
    setBills(data?.transactions?.reverse());
  };

  useEffect(() => {
    if (invoice?.businessId) {
      getBills();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.totalAmount]);

  const setInvoicePreview = () => {
    setInvoiceTable(!isInvoiceTable);
  };

  return (
    <div
      className={`${
        isVisible ? 'block' : 'hidden'
      } fixed top-0 left-0 right-0 bottom-0 bg-[#F8F9F9] min-h-screen overflow-y-auto`}
    >
      {/* Top bar */}
      <div className="flex items-center justify-between p-4 bg-white border-b">
        <div
          className="cursor-pointer"
          onClick={isInvoiceTable ? () => setInvoiceTable(false) : handleCancel}
        >
          <BackButtonIcon />
        </div>
        <div className="font-semibold text-lg text-[#07000F]">
          Invoice#{invoice?.invoiceNumber}{' '}
          <span>{isInvoiceTable ? 'payment(s)' : ''}</span>
        </div>
        <div></div>
      </div>
      {/* body */}
      {isInvoiceTable ? (
        <>
          {bills?.length !== 0 && Array.isArray(bills) ? (
            <section>
              {bills?.map((bill, index) => (
                <div
                  key={bill?.business_id + index}
                  className="p-2 border border-[#ECEBF5] rounded-lg bg-white w-[90%] mx-auto mt-10 md:hidden"
                >
                  <div className="flex items-center justify-between p-2 border-b-2">
                    <div>
                      <p className="text-[#5C696E]">Amount</p>
                      <p className="text-[#07000F] font-semibold">
                        ₦
                        {Number(bill?.amount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        }) || '0.00'}
                      </p>
                    </div>
                    <div
                      className={classNames(
                        'font-medium text-[10px] inline-block px-2 rounded uppercase',
                        {
                          'bg-[#FDEFCB] text-[#D97724]':
                            bill?.status.toLowerCase() === 'pending',
                          'bg-[#BBE5B3] text-[#414F3E] ':
                            bill?.status.toLowerCase() === 'successful',
                          'bg-[#CFE0F5] text-[#087FCA] ':
                            bill?.status.toLowerCase() === 'partiallypaid',
                        }
                      )}
                    >
                      {bill.status}
                    </div>
                  </div>
                  <div className="flex items-center justify-between p-2 border-b-2">
                    <div>
                      <p className="text-[#5C696E]">Name</p>
                      <p className="text-[#07000F] font-semibold">
                        {vendor?.vendorName}
                      </p>
                    </div>
                    <div></div>
                  </div>
                  <div className="flex items-center justify-between p-2">
                    <div>
                      <p className="text-[#5C696E]">Date created</p>
                      <p className="text-[#07000F] font-semibold">
                        {moment(bill.created_at).format('DD/MM/yyyy, h:mm A')}
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <div className="flex items-center justify-center mt-56 md:hidden">
              <div className="flex flex-col items-center justify-center">
                <img src={EmptyInvoice} alt="Empty Invoice" width={30} />
                <h1 className="text-[#07000F] font-semibold text-[20px]">
                  No transactions yet
                </h1>
                <span className="px-20 font-normal text-sm text-[#484847]">
                  Once the bill is paid, you will see the payment here
                </span>
              </div>
            </div>
          )}

          <div className="mx-auto w-[80%]  bg-white rouunded-lg mt-10  px-10 py-5 hidden md:block">
            <div>
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr className="bg-[#F8F9F9] text-xs font-semibold text-[#07000F]">
                    <th className="py-4 pl-3">DATE CREATED</th>
                    <th>NAME</th>
                    <th>AMOUNT</th>
                    <th>DESCRIPTION</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {bills?.length !== 0 &&
                    bills?.map((bill, index) => {
                      return (
                        <tr
                          key={bill.business_id + index}
                          className="border-b-2 text-sm font-normal text-[#5C696E] cursor-pointer"
                        >
                          <td className="py-4 pl-3">
                            {moment(bill.created_at).format(
                              'DD/MM/yyyy, h:mm A'
                            )}
                          </td>
                          <td>{vendor?.vendorName}</td>
                          <td>
                            ₦
                            {Number(bill.amount).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            }) || '0.00'}
                          </td>
                          <td>{bill.description}</td>
                          <td>
                            <div
                              className={classNames(
                                'font-medium text-[10px] inline-block px-2 rounded uppercase',
                                {
                                  'bg-[#FDEFCB] text-[#D97724] ':
                                    bill.status.toLowerCase() === 'pending',
                                  'bg-[#BBE5B3] text-[#414F3E] ':
                                    bill.status.toLowerCase() === 'successful',
                                  'bg-[#CFE0F5] text-[#087FCA] ':
                                    bill?.status.toLowerCase() ===
                                    'partiallypaid',
                                }
                              )}
                            >
                              {bill.status}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {(bills?.length === 0 || bills === undefined) && (
                <div className="flex items-center justify-center mt-32">
                  <div className="flex flex-col items-center justify-center">
                    <img src={EmptyInvoice} alt="Empty Invoice" width={30} />
                    <h1 className="text-[#07000F] font-semibold text-[20px]">
                      No transactions yet
                    </h1>
                    <span className="px-20 font-normal text-sm text-[#484847]">
                      Once the bill is paid, you will see the payment here
                    </span>
                  </div>
                </div>
              )}
            </div>
            <Pagination
              selectedOption={String(filters.page)}
              currentPage={filters.page}
              handlePaginationChange={handlePaginationChange}
              pageCount={filters.pageCount}
              setSelectedOption={handlePageDropdown}
              list={bills?.length}
            />
          </div>
        </>
      ) : (
        <InvoicePreview invoice={invoice} isVisible setInvoiceTable={setInvoicePreview} />
      )}
    </div>
  );
};

export default InvoiceBreakdown;
