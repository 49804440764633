import React from 'react';
import { Drawer } from 'antd';
import { removeCommas } from 'utils/format';
import moment from 'moment';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { IVendorCompliance, Vendor, VendorType } from 'utils';

export interface InvoiceDrawerProps {
  invoice: VendorType | undefined;
  compStatus: IVendorCompliance | undefined;
  vendor: Vendor | null;
  isOpenDrawer: boolean;
  setIsOpenDrawer: () => void;
  setIsRejectOpen: () => void;
  setIsApprovalOpen: () => void;
}

function InvoiceDrwaer({
  invoice,
  compStatus,
  vendor,
  isOpenDrawer,
  setIsOpenDrawer,
  setIsApprovalOpen,
  setIsRejectOpen,
}: InvoiceDrawerProps) {
  const isAcceptedOrRejected =
    invoice?.invoiceFinancingStatus === 'declined' ||
    invoice?.invoiceFinancingStatus === 'accepted';

  return (
    <section>
      <Drawer
        placement="right"
        closable={false}
        onClose={setIsOpenDrawer}
        open={isOpenDrawer}
        key="right"
        width={440}
        className="px-2 py-1 overflow-auto "
      >
        <div className="flex items-center justify-start gap-2">
          <CloseIcon onClick={setIsOpenDrawer} className="cursor-pointer" />
          <div>
            <p>Close loan terms</p>
          </div>
        </div>
        <div className="text-right text-[#5C696E] font-normal text-xs p-1">
          Created:{' '}
          {moment(invoice?.loanNote?.createdAt).format('DD-MMM-yyyy, h:mm A')}
        </div>
        <h1 className="py-2 uppercase text-[#162930] font-bold">Loan Terms</h1>
        <div>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">
              INVOICE AMOUNT
            </dt>
            <dl className="text-[#1D242E] font-semibold">
              ₦{' '}
              {Number(
                removeCommas(invoice?.totalAmount as string)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">
              Invoice Number
            </dt>
            <dl className="text-[#1D242E] font-semibold">
              {invoice?.invoiceNumber ?? '-'}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">VENDOR</dt>
            <dl className="text-[#1D242E] font-semibold">
              {vendor?.vendorName ?? '-'}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">
              VENDOR’s Phone Number
            </dt>
            <dl className="text-[#1D242E] font-semibold">
              {vendor?.phoneNumber ?? '-'}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">
              VENDOR’s Office Address
            </dt>
            <dl className="text-[#1D242E] font-semibold">
              {vendor?.shippingAddress?.address ||
                vendor?.vendorMerchantDetails?.shippingAddress?.address ||
                '-'}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">
              Serviced Percent
            </dt>
            <dl className="text-[#1D242E] font-semibold">
              {((invoice?.loanNote?.servicePercent as number) * 100)?.toFixed(
                2
              ) ?? 0.0}
              %
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">LOAN AMOUNT</dt>
            <dl className="text-[#1D242E] font-semibold">
              ₦
              {invoice?.loanNote?.loanAmount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) ?? 0.0}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">INTEREST</dt>
            <dl className="text-[#1D242E] font-semibold">
              {((invoice?.loanNote?.interest as number) * 100)?.toFixed(2) ??
                0.0}
              %
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">
              Daily Interest
            </dt>
            <dl className="text-[#1D242E] font-semibold">
              {(invoice?.loanNote?.dailyInterest as number)?.toFixed(5) ?? 0.0}%
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">Tenure</dt>
            <dl className="text-[#1D242E] font-semibold">
              {invoice?.loanNote?.tenor ?? 0.0} days
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">Due Date</dt>
            <dl className="text-[#1D242E] font-semibold">
              {moment(invoice?.loanNote?.createdAt)
                .add(invoice?.loanNote?.tenor, 'day')
                .startOf('day')
                .format('D, MMM YYYY')}{' '}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px]">
            <dt className="text-[#484847CC] text-xs uppercase">
              Guarantor’s Name{' '}
            </dt>
            <dl className="text-[#1D242E] font-semibold">
              {compStatus?.vendorDocument?.guarantorDetails?.fullName ?? '-'}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-t-[1px] items-center">
            <dt className="text-[#484847CC] text-xs uppercase">
              Guarantor’s Phone Number
            </dt>
            <dl className="text-[#1D242E] font-semibold">
              {compStatus?.vendorDocument?.guarantorDetails?.phoneNumber ?? '-'}
            </dl>
          </dl>
          <dl className="flex justify-between py-1 border-y-[1px] items-center">
            <dt className="text-[#484847CC] text-xs uppercase">
              Guarantor’s Address
            </dt>
            <dl className="text-[#1D242E] font-medium text-sm">
              {compStatus?.vendorDocument?.guarantorDetails?.address ?? '-'}
            </dl>
          </dl>
        </div>

        <div className="mt-12 border-b-[1px] pb-4">
          <h1 className="text-[#8B8B8B] border-b-[1px] py-2">
            VENDOR’S BANK DETAILS
          </h1>
          <div className="mt-3">
            <dl className="flex items-center justify-between py-1">
              <dt className="text-[#484847CC] text-xs uppercase">Bank Name</dt>
              <dl className="text-[#1D242E] font-medium text-sm">
                {vendor?.vendorMerchantDetails?.bankAccountDetails?.bankName}
              </dl>
            </dl>
            <dl className="flex items-center justify-between py-1">
              <dt className="text-[#484847CC] text-xs uppercase">
                Account Number
              </dt>
              <dl className="text-[#1D242E] font-medium text-sm">
                {
                  vendor?.vendorMerchantDetails?.bankAccountDetails
                    ?.accountNumber
                }
              </dl>
            </dl>
            <dl className="flex items-center justify-between py-1">
              <dt className="text-[#484847CC] text-xs uppercase">
                Account Name{' '}
              </dt>
              <dl className="text-[#1D242E] font-medium text-sm">
                {vendor?.vendorMerchantDetails?.bankAccountDetails?.accountName}
              </dl>
            </dl>
          </div>
        </div>

        {!isAcceptedOrRejected && (
          <div className="fixed bottom-0 right-0 flex gap-5 mb-5 mr-5">
            <button
              className="border border-[#DADAE7] font-medium rounded-md  text-center py-2 px-4 text-[#F84253]"
              onClick={setIsRejectOpen}
            >
              Reject loan terms
            </button>
            <button
              className="bg-[#006355] font-medium py-2 px-4 rounded-md hover:bg-emerald-700 disabled:opacity-[0.4] text-white"
              onClick={setIsApprovalOpen}
            >
              Accept loan terms{' '}
            </button>
          </div>
        )}
      </Drawer>
    </section>
  );
}

export default InvoiceDrwaer;
