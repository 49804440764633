/* eslint-disable @typescript-eslint/no-empty-function */
import PhoneField from 'components/common/PhoneField';
import { useVendor } from 'contexts/VendorContext';
import { Formik, FormikProps } from 'formik';
import { businessSchema, shippingSchema } from 'utils/validationSchema';
import {
  BillingInterface,
  BusinessInterface,
  ICountries,
  ShippingInterface,
  countries,
} from 'utils';
import InputComponent from 'components/common/InputComponent';
import { ReactComponent as BankIcon } from 'assets/svg/bank-icon.svg';
import { useEffect, useState } from 'react';
import DropdownComponent from 'components/common/DropdownComponent';
import { Tabs, type TabsProps } from 'antd';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import {
  updateVendorBillingDetails,
  updateVendorInformation,
  updateVendorShippingDetails,
} from 'functions/vendor-functions';
import toast from 'react-hot-toast';
import { useAuth } from 'contexts/AuthContext';
import { getChangedValues, getInitials } from 'utils/format';
import Loader from 'components/common/Loader';

const Business = () => {
  const { vendor } = useVendor();
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isShippingLoading, setIsShippingLoading] = useState(false);
  const [isBillingLoading, setIsBillingLoading] = useState(false);

  const [isInputsDisabled, setIsInputDisabled] = useState<boolean>(true);
  const [isInputsBillingDisabled, setIsInputBillingDisabled] =
    useState<boolean>(true);
  const [isInputsShippingDisabled, setIsInputShippingDisabled] =
    useState<boolean>(true);

  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
    vendor?.vendorMerchantDetails?.shippingAddress?.country ||
      vendor?.shippingAddress?.country ||
      ''
  );
  const [selectedState, setSelectedState] = useState<string | undefined>(
    vendor?.vendorMerchantDetails?.shippingAddress?.state ||
      vendor?.shippingAddress?.state ||
      ''
  );
  const [selectedBillingState, setSelectedBillingState] = useState<
    string | undefined
  >(
    vendor?.vendorMerchantDetails?.billingAddress?.state ||
      vendor?.billingAddress?.state ||
      ''
  );

  const [selectedBillingCountry, setSelectedBillingCountry] = useState<
    string | undefined
  >(
    vendor?.vendorMerchantDetails?.billingAddress?.country ||
      vendor?.billingAddress?.country ||
      ''
  );

  const [localGovernments, setLocalGovernments] = useState<string[]>([]);
  const [localGovernmentBillings, setLocalGovernmentsBilling] = useState<
    string[]
  >([]);

  const businessInitialValues = {
    vendorName: vendor?.vendorName || '',
    phoneNumber: vendor?.phoneNumber || '',
    email: vendor?.email || '',
    taxVerificationNumber: vendor?.taxVerificationNumber || '',
    rcNumber: vendor?.rcNumber || '',
  };

  const shippingInitialValues = {
    country:
      vendor?.vendorMerchantDetails?.shippingAddress?.country ||
      vendor?.shippingAddress?.country ||
      '',
    state:
      vendor?.vendorMerchantDetails?.shippingAddress?.state ||
      vendor?.shippingAddress?.state ||
      '',
    city:
      vendor?.vendorMerchantDetails?.shippingAddress?.city ||
      vendor?.shippingAddress?.city ||
      '',
    address:
      vendor?.vendorMerchantDetails?.shippingAddress?.address ||
      vendor?.shippingAddress?.address ||
      '',
  };

  const billingInitialValues = {
    country:
      vendor?.vendorMerchantDetails?.billingAddress?.country ||
      vendor?.billingAddress?.country ||
      '',
    state:
      vendor?.vendorMerchantDetails?.billingAddress?.state ||
      vendor?.billingAddress?.state ||
      '',
    city:
      vendor?.vendorMerchantDetails?.billingAddress?.city ||
      vendor?.billingAddress?.city ||
      '',
    address:
      vendor?.vendorMerchantDetails?.billingAddress?.address ||
      vendor?.billingAddress?.address ||
      '',
  };

  const handleNationalityFormatting = () => {
    const array: ICountries[] = countries;
    const filteredData: { label: string; value: string }[] = [];
    array.forEach(({ country }) =>
      filteredData.push({
        label: country,
        value: country,
      })
    );
    return filteredData;
  };

  const handleStateFormatting = (type?: string) => {
    const filteredData: { label: string; value: string }[] = [];
    const data =
      type === 'billing' ? localGovernmentBillings : localGovernments;
    data.forEach((localGovernment) =>
      filteredData.push({
        label: localGovernment,
        value: localGovernment,
      })
    );
    return filteredData;
  };

  const handleCountryChange = (e: string, type?: string) => {
    type === 'billing' ? setSelectedBillingCountry(e) : setSelectedCountry(e);
    const array: ICountries[] = countries;
    array.filter(
      ({ country, states }) =>
        country === e &&
        (type !== 'billing'
          ? setLocalGovernments(states)
          : setLocalGovernmentsBilling(states))
    );
  };

  const handleFormSubmit = async (values: BusinessInterface) => {
    const editPayload = getChangedValues(
      businessInitialValues || ({} as BusinessInterface),
      {
        ...values,
      }
    );

    setIsLoading(true);

    if (Object.keys(editPayload).length !== 0) {
      const { data, responseError, refreshError } =
        await updateVendorInformation(editPayload);

      if (responseError) {
        toast.error(responseError.message);
        setIsLoading(false);
      }

      // logout if refreshToken failed to refresh
      if (refreshError) {
        logout();
      }

      if (data) {
        setIsLoading(false);
        toast.success('Details updated successfully');
        setIsInputDisabled(true);
      }
    }
  };
  const handleFormShippingSubmit = async (values: ShippingInterface) => {
    const editPayload = getChangedValues(
      shippingInitialValues || ({} as ShippingInterface),
      {
        ...values,
        country: selectedCountry,
        state: selectedState,
      }
    );

    setIsShippingLoading(true);

    if (Object.keys(editPayload).length !== 0) {
      const { data, responseError, refreshError } =
        await updateVendorShippingDetails(editPayload);

      if (responseError) {
        toast.error(responseError.message);
        setIsShippingLoading(false);
      }

      // logout if refreshToken failed to refresh
      if (refreshError) {
        logout();
      }

      if (data) {
        setIsShippingLoading(false);
        toast.success('Details updated successfully');
        setIsInputShippingDisabled(true);
      }
    }
  };

  const closeDropdown = () => {
    setOpen(false);
  };
  const handleFormBillingSubmit = async (values: ShippingInterface) => {
    const editPayload = getChangedValues(
      billingInitialValues || ({} as ShippingInterface),
      {
        ...values,
        country: selectedBillingCountry,
        state: selectedBillingState,
      }
    );

    setIsBillingLoading(true);

    if (Object.keys(editPayload).length !== 0) {
      const { data, responseError, refreshError } =
        await updateVendorBillingDetails(editPayload);

      if (responseError) {
        toast.error(responseError.message);
        setIsBillingLoading(false);
      }

      // logout if refreshToken failed to refresh
      if (refreshError) {
        logout();
      }

      if (data) {
        setIsBillingLoading(false);
        toast.success('Details updated successfully');
        setIsInputBillingDisabled(true);
      }
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Upload Image',
      children: (
        <>
          <ImageUpload closeDropdown={closeDropdown} />
        </>
      ),
    },
  ];

  useEffect(() => {
    const shipping_country =
      vendor?.shippingAddress?.country ??
      vendor?.vendorMerchantDetails?.shippingAddress?.country;
    const billing_country =
      vendor?.billingAddress?.country ??
      vendor?.vendorMerchantDetails?.billingAddress?.country;
    if (shipping_country) handleCountryChange(shipping_country);
    if (billing_country) handleCountryChange(billing_country, 'billing');
  }, [
    vendor?.vendorMerchantDetails?.shippingAddress?.country,
    vendor?.vendorMerchantDetails?.billingAddress?.country,
    vendor?.shippingAddress?.country,
    vendor?.billingAddress?.country,
  ]);

  return (
    <>
      {!vendor && selectedCountry?.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <div className="p-4 space-y-8 w-[85%]">
          <div className="pb-8 space-y-6 border-b">
            <h3 className="item-header-large">Business logo</h3>
            <div className="flex items-center justify-between mt-5 max-w-[400px]">
              <div className="relative flex items-center">
                <div className="flex items-center justify-center mr-4">
                  {!vendor?.logoUrl ? (
                    <div className="w-9 h-9 text-black bg-[#2DB49E] rounded-full flex justify-center items-center uppercase text-lg font-bold">
                      {getInitials(vendor?.vendorName as string)}
                    </div>
                  ) : (
                    <img
                      src={vendor?.logoUrl}
                      alt="Business Logo"
                      className="block bg-blue-500 rounded-full w-14 h-14 overflow-clip"
                    />
                  )}
                </div>
                <div>
                  <button
                    className="px-4 py-3 text-sm font-medium text-gray-600 border rounded-lg"
                    onClick={() => setOpen(!open)}
                  >
                    Edit photo
                  </button>

                  {open && (
                    <div
                      className="px-4 py-3 min-w-[330px] bg-white rounded-lg cursor-pointer text-center absolute block left-20 top-12"
                      style={{
                        boxShadow: '0px 6px 28px 0px #5E5E7314',
                        zIndex: 2,
                      }}
                    >
                      <Tabs
                        defaultActiveKey="1"
                        items={items}
                        tabBarStyle={{
                          alignItems: 'center',
                          color: 'green',
                          fontWeight: 'bold',
                          width: '100%',
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Business information */}
          <div className="space-y-8">
            <div className="space-y-3">
              <h3 className="item-header-large">Business information</h3>
              <span className="text-sm font-normal text-gray-500">
                Set up your name and contact details in simple steps.
              </span>
            </div>
            <div className="space-y-8">
              <Formik
                initialValues={businessInitialValues}
                validationSchema={businessSchema}
                onSubmit={(values: BusinessInterface) => {
                  handleFormSubmit(values);
                }}
                enableReinitialize
              >
                {({
                  handleSubmit,
                  isValid,
                }: FormikProps<BusinessInterface>) => (
                  <form onSubmit={handleSubmit}>
                    <div className="grid items-center gap-6 py-4 md:grid-cols-2">
                      <InputComponent
                        label="Business Name"
                        name="vendorName"
                        type="text"
                        disabled={isInputsDisabled}
                      />
                      <InputComponent
                        label="Email Address"
                        name="email"
                        type="text"
                        disabled={true}
                      />
                      <PhoneField
                        name="phoneNumber"
                        label="Phone Number"
                        type="tel"
                        disabled={isInputsDisabled}
                      />
                      <InputComponent
                        name="taxVerificationNumber"
                        label="Tax Verification Number (Optional)"
                        type="text"
                        disabled={isInputsDisabled}
                      />
                      <InputComponent
                        name="rcNumber"
                        label="RC Number (Optional)"
                        type="text"
                        disabled={isInputsDisabled}
                      />
                    </div>

                    <div className="flex items-center space-x-4 md:w-[40%]">
                      <button
                        className="w-full p-3 text-sm font-semibold text-gray-600 border rounded-lg"
                        onClick={() => {
                          setIsInputDisabled(!isInputsDisabled);
                        }}
                        type="button"
                      >
                        {isInputsDisabled ? 'Edit' : 'Cancel'}
                      </button>
                      <button
                        className="text-white font-semibold text-sm p-3 w-full rounded-lg bg-[#006355]  disabled:opacity-60"
                        disabled={isInputsDisabled || !isValid}
                        type="submit"
                      >
                        {isLoading ? <Loader size="sm" /> : 'Save Changes'}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            {/* Bank Details */}
            <section>
              <h2 className="item-header-large">Bank Details</h2>
              <p className="py-2 text-[#484847CC] font-normal text-sm">
                Payment made for your invoice will be sent to this account
              </p>
              <div className="bg-[#F6F6F8] rounded-lg py-3 flex items-center justify-start px-4 gap-5 mt-4">
                <div>
                  <BankIcon />
                </div>
                {vendor?.bankAccountDetails ||
                vendor?.vendorMerchantDetails?.bankAccountDetails ? (
                  <div className="capitalize text-[#162930]">
                    {(
                      vendor?.bankAccountDetails?.accountName ??
                      vendor?.vendorMerchantDetails?.bankAccountDetails
                        ?.accountName
                    )?.toLowerCase()}{' '}
                    |{' '}
                    {(
                      vendor?.bankAccountDetails?.bankName ??
                      vendor?.vendorMerchantDetails?.bankAccountDetails
                        ?.bankName
                    )?.toLowerCase()}{' '}
                    |{' '}
                    {(
                      vendor?.bankAccountDetails?.accountNumber ??
                      vendor?.vendorMerchantDetails?.bankAccountDetails
                        ?.accountNumber
                    )?.toLowerCase()}
                  </div>
                ) : (
                  <div>No Bank Details Found</div>
                )}
              </div>
            </section>
            {/* Shipping Address */}
            <section>
              <h2 className="item-header-large">Shipping Address</h2>
              <Formik
                initialValues={shippingInitialValues}
                validationSchema={shippingSchema}
                onSubmit={(values: ShippingInterface) => {
                  handleFormShippingSubmit(values);
                }}
                enableReinitialize
              >
                {({
                  handleSubmit,
                  isValid,
                }: FormikProps<ShippingInterface>) => (
                  <form onSubmit={handleSubmit}>
                    <div className="grid items-center gap-6 py-4 md:grid-cols-2">
                      <DropdownComponent
                        label="Country"
                        dropdownOption={handleNationalityFormatting()}
                        onChange={(e: string) => {
                          handleCountryChange(e);
                        }}
                        disabled={isInputsShippingDisabled}
                        selectedOption={
                          selectedCountry ||
                          vendor?.vendorMerchantDetails?.shippingAddress
                            ?.country ||
                          vendor?.shippingAddress?.country
                        }
                      />
                      <InputComponent
                        label="City"
                        name="city"
                        type="text"
                        disabled={isInputsShippingDisabled}
                      />
                      <DropdownComponent
                        label="State"
                        dropdownOption={handleStateFormatting()}
                        onChange={(e: string) => {
                          setSelectedState(e);
                        }}
                        disabled={isInputsShippingDisabled}
                        selectedOption={
                          selectedState ||
                          vendor?.vendorMerchantDetails?.shippingAddress
                            ?.state ||
                          vendor?.shippingAddress?.country
                        }
                      />
                      <InputComponent
                        name="address"
                        label="Address"
                        type="text"
                        disabled={isInputsShippingDisabled}
                      />
                    </div>
                    <div className="flex items-center space-x-4 md:w-[40%]">
                      <button
                        className="w-full p-3 text-sm font-semibold text-gray-600 border rounded-lg"
                        onClick={() => {
                          setIsInputShippingDisabled(!isInputsShippingDisabled);
                        }}
                        type="button"
                      >
                        {isInputsShippingDisabled ? 'Edit' : 'Cancel'}
                      </button>
                      <button
                        className="text-white font-semibold text-sm p-3 w-full rounded-lg bg-[#006355]  disabled:opacity-60"
                        disabled={isInputsShippingDisabled || !isValid}
                        type="submit"
                      >
                        {isShippingLoading ? (
                          <Loader size="sm" />
                        ) : (
                          'Save Changes'
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </section>
            {/* Billing Address */}
            <section>
              <h2 className="item-header-large">Billing Address</h2>
              <Formik
                initialValues={billingInitialValues}
                validationSchema={shippingSchema}
                onSubmit={(values: ShippingInterface) => {
                  handleFormBillingSubmit(values);
                }}
                enableReinitialize
              >
                {({ handleSubmit, isValid }: FormikProps<BillingInterface>) => (
                  <form onSubmit={handleSubmit}>
                    <div className="grid items-center gap-6 py-4 md:grid-cols-2">
                      <DropdownComponent
                        label="Country"
                        dropdownOption={handleNationalityFormatting()}
                        onChange={(e: string) => {
                          handleCountryChange(e, 'billing');
                        }}
                        disabled={isInputsBillingDisabled}
                        selectedOption={
                          selectedBillingCountry ||
                          vendor?.vendorMerchantDetails?.billingAddress
                            ?.country ||
                          vendor?.billingAddress?.country
                        }
                      />
                      <InputComponent
                        label="City"
                        name="city"
                        type="text"
                        disabled={isInputsBillingDisabled}
                      />
                      <DropdownComponent
                        label="State"
                        dropdownOption={handleStateFormatting('billing')}
                        onChange={(e: string) => {
                          setSelectedBillingState(e);
                        }}
                        disabled={isInputsBillingDisabled}
                        selectedOption={
                          selectedBillingState ||
                          vendor?.vendorMerchantDetails?.billingAddress
                            ?.state ||
                          vendor?.billingAddress?.state
                        }
                      />
                      <InputComponent
                        name="address"
                        label="Address"
                        type="text"
                        disabled={isInputsBillingDisabled}
                      />
                    </div>
                    <div>
                      <div className="flex items-center space-x-4 md:w-[40%]">
                        <button
                          className="w-full p-3 text-sm font-semibold text-gray-600 border rounded-lg"
                          onClick={() => {
                            setIsInputBillingDisabled(!isInputsBillingDisabled);
                          }}
                          type="button"
                        >
                          {isInputsBillingDisabled ? 'Edit' : 'Cancel'}
                        </button>
                        <button
                          className="text-white font-semibold text-sm p-3 w-full rounded-lg bg-[#006355]  disabled:opacity-60"
                          disabled={isInputsBillingDisabled || !isValid}
                          type="submit"
                        >
                          {isBillingLoading ? (
                            <Loader size="sm" />
                          ) : (
                            'Save Changes'
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default Business;
