import React from 'react';

export default function UploadDocumentSuccessIcon() {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="80" height="80" rx="40" fill="#CAFFED" />
            <path
                opacity="0.4"
                d="M34.4071 41.6691L26.7465 33.9505C26.5399 33.7397 26.4252 33.4558 26.4273 33.1607C26.4294 32.8656 26.5482 32.5833 26.7578 32.3755L29.389 29.7649C29.5998 29.5583 29.8837 29.4435 30.1788 29.4456C30.4739 29.4478 30.7562 29.5666 30.964 29.7762L35.2283 34.0742L45.0507 24.3193C45.2616 24.1127 45.5456 23.9979 45.8408 24C46.136 24.0021 46.4184 24.121 46.6263 24.3306L49.2463 26.9631C49.4529 27.1739 49.5677 27.458 49.5656 27.7532C49.5635 28.0484 49.4447 28.3308 49.235 28.5387L35.9827 41.6804C35.7718 41.887 35.4878 42.0018 35.1926 41.9997C34.8973 41.9976 34.615 41.8788 34.4071 41.6691Z"
                fill="#0DA06D"
            />
            <path
                d="M55.531 34.7498C55.8312 35.0525 55.9997 35.4616 55.9997 35.8879C55.9997 36.3143 55.8312 36.7233 55.531 37.0261L37.1312 55.5283C36.9832 55.6778 36.807 55.7964 36.6128 55.8773C36.4186 55.9583 36.2104 56 36 56C35.7896 56 35.5813 55.9583 35.3872 55.8773C35.193 55.7964 35.0168 55.6778 34.8687 55.5283L24.4689 45.0685C24.1685 44.7659 24 44.3569 24 43.9307C24 43.5044 24.1685 43.0954 24.4689 42.7929L26.7313 40.5173C26.8794 40.3678 27.0556 40.2492 27.2498 40.1683C27.4439 40.0873 27.6522 40.0456 27.8626 40.0456C28.0729 40.0456 28.2812 40.0873 28.4754 40.1683C28.6696 40.2492 28.8458 40.3678 28.9938 40.5173L36 47.5622L51.0061 32.4711C51.1542 32.3218 51.3304 32.2033 51.5246 32.1225C51.7187 32.0416 51.927 32 52.1373 32C52.3476 32 52.5559 32.0416 52.75 32.1225C52.9442 32.2033 53.1204 32.3218 53.2685 32.4711L55.531 34.7498Z"
                fill="#0DA06D"
            />
        </svg>
    );
}
