import { Home, Swap, Ticket, Setting } from 'react-iconly';
import { ACCOUNT_SETTINGS_PATHS, ROUTE_PATHS } from './constants';

export interface SidenavLink {
  icon?: any;
  text: string;
  path: string;
}

export const NavLinks: SidenavLink[] = [
  {
    icon: Home,
    text: 'Home',
    path: ROUTE_PATHS.ACCOUNT + ROUTE_PATHS.DASHBOARD,
  },
  {
    icon: Ticket,
    text: 'Invoice Management',
    path: ROUTE_PATHS.ACCOUNT + ROUTE_PATHS.INVOICE_MANAGEMENT,
  },
  {
    icon: Swap,
    text: 'Transactions',
    path: ROUTE_PATHS.ACCOUNT + ROUTE_PATHS.TRANSACTIONS,
  },

  // {
  //   icon: Activity,
  //   text: 'Reports',
  //   path: ROUTE_PATHS.ACCOUNT + ROUTE_PATHS.REPORTS,
  // },
];

export const NavLinkExtras: SidenavLink[] = [
  {
    icon: Setting,
    text: 'Settings',
    path: ROUTE_PATHS.ACCOUNT + ROUTE_PATHS.SETTINGS,
  },
];

export const SettingsSideNavLinks: SidenavLink[] = [
  {
    text: 'Business',
    path:
      ROUTE_PATHS.ACCOUNT +
      ROUTE_PATHS.SETTINGS +
      ACCOUNT_SETTINGS_PATHS.BUSINESS,
  },
  {
    text: 'Security',
    path:
      ROUTE_PATHS.ACCOUNT +
      ROUTE_PATHS.SETTINGS +
      ACCOUNT_SETTINGS_PATHS.SECURITY,
  },
  {
    text: 'Compliance',
    path:
      ROUTE_PATHS.ACCOUNT +
      ROUTE_PATHS.SETTINGS +
      ACCOUNT_SETTINGS_PATHS.COMPLIANCE,
  },
  // {
  //   text: 'Preferences',
  //   path:
  //     ROUTE_PATHS.ACCOUNT +
  //     ROUTE_PATHS.SETTINGS +
  //     ACCOUNT_SETTINGS_PATHS.PREFERENCES,
  // },
];
