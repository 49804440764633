import React, { useState } from 'react';
import { ChevronRight } from 'react-iconly';
import { Progress, Modal } from 'antd';
import AccountCompletionItem from './AccountCompletionItem';
import AddressModal from './AddressModal';
import BankAccountModal from './BankAccountModal';

interface Props {
  hasBilingAddress: boolean | undefined;
  hasShippingAddress: boolean | undefined;
  hasBankAccount: boolean | undefined;
}

const AccountCompletion: React.FC<Props> = ({
  hasBilingAddress,
  hasShippingAddress,
  hasBankAccount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isBankAccountModalOpen, setIsBankAccountModalOpen] = useState(false);
  const [addressType, setAddressType] = useState('');
  let percentage = 0;

  if (hasBilingAddress) percentage++;
  if (hasShippingAddress) percentage++;
  if (hasBankAccount) percentage++;

  const percentageCompletion = (percentage / 3) * 100;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showAddressModal = (type: string) => {
    setIsAddressModalOpen(true);
    setAddressType(type);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleAddressCancel = () => {
    setIsAddressModalOpen(false);
    setAddressType('');
  };

  const showBankAccountModal = () => {
    setIsBankAccountModalOpen(true);
    setIsModalOpen(false);
  };

  const handleBankAccountCancel = () => {
    setIsBankAccountModalOpen(false);
  };

  return (
    <>
      <div className="flex items-start">
        <div className="hidden md:inline-block md:w-[36%] space-y-2">
          <h3 className="font-semibold text-xl text-[#07000F]">
            Continue setup
          </h3>
          <span className="font-normal text-xs text-gray-600 md:text-sm md:block md:w-[90%]">
            Complete your account setup to enjoy the full benefit of Duplo’s
            Vendor portal
          </span>
        </div>
        <div className="md:w-[64%] border rounded-lg p-6 space-y-4 md:space-y-12">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="font-semibold text-[15px] text-[#07000F] md:text-[16px]">
                Setup {Math.floor(percentageCompletion)}% complete
              </h3>
              <span className="text-xs font-normal text-gray-600 md:text-sm">
                Complete your account setup to have a full portal experience
              </span>
            </div>
            <i className="hidden md:inline-block">
              <ChevronRight />
            </i>
          </div>
          <div className="md:flex md:items-start md:justify-between">
            <div className="md:w-[50%]">
              <Progress
                success={{ percent: percentageCompletion }}
                showInfo={false}
              />
            </div>
            <button
              className="border rounded-lg px-6 py-2 font-medium text-[15px] border-black text-black"
              onClick={showModal}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      {/* Account completion modals */}
      <Modal
        open={isModalOpen}
        width={800}
        // bodyStyle={{ height: 520 }}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="text-center">
          <h3 className="md:font-semibold text-lg text-[#07000F]">
            Getting you started
          </h3>
        </div>
        <div className="mt-8 text-center">
          <h3 className="font-medium md:text-[16px]">
            You're off to a great start! 🎉
          </h3>
          <span>{percentage} out of 3 completed.</span>
        </div>
        <div className="md:w-[90%] mx-auto space-y-4 mt-8 pb-10">
          {/* Add billiing address */}
          <AccountCompletionItem
            isCompleted={hasBilingAddress}
            text="Add billing address"
            subText=""
            buttonText="Add address"
            action={() => showAddressModal('billing')}
          />
          {/* Add shipping address */}
          <AccountCompletionItem
            isCompleted={hasShippingAddress}
            text="Add shipping address"
            subText=""
            buttonText="Add address"
            action={() => showAddressModal('shipping')}
          />
          {/* Add bank details */}
          <AccountCompletionItem
            isCompleted={hasBankAccount}
            text="Add bank details"
            subText=""
            buttonText="Add account"
            action={showBankAccountModal}
          />
        </div>
      </Modal>
      <AddressModal
        isModalOpen={isAddressModalOpen}
        type={addressType}
        handleCancel={handleAddressCancel}
      />
      <BankAccountModal
        isModalOpen={isBankAccountModalOpen}
        handleCancel={handleBankAccountCancel}
      />
    </>
  );
};

export default AccountCompletion;
