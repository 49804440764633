import { Modal } from 'antd';
import React, { useState } from 'react';
import UploadIcon from 'components/icons/UploadIcon';
import TextFieldNumber from 'components/common/TextFieldNumber';
import LightBulbIcon from 'components/icons/LightBulbIcon';
import { useAuth } from 'contexts/AuthContext';
import { uploadFileRequest } from 'functions/vendor-functions';
import UploadSuccessIcon from 'components/icons/UploadSuccessIcon';
import SuccessChecbox from 'components/icons/SuccessChecbox';
import toast from 'react-hot-toast';
import { Formik, FormikProps } from 'formik';
import { uploadDocumentsSchema } from 'utils/validationSchema';
import { uploadDocsInterface } from 'utils';
import { Tooltip } from 'react-tooltip';
import InvoiceSuccessModal from './InvoiceSuccessModal';
import { rudderAnalytics } from 'utils/rudderEventGenerator';
import config from 'config';
import { useVendor } from 'contexts/VendorContext';
import { removeCommas, truncateString } from 'utils/format';

interface Props {
  isModalOpen: boolean;
  handleCancel: () => void;
  setRefreshInvoices?: any;
}

const allowedExtension = new Set(['pdf']);

const UploadDocumentModal: React.FC<Props> = ({
  isModalOpen = false,
  handleCancel,
  setRefreshInvoices,
}) => {
  const { vendor } = useVendor();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [purchaseOrder, setPurchaseOrder] = useState<File | null>(null);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
  const { logout } = useAuth();
  const [error, setError] = useState<string>('');

  const initialValues = {
    invoice_number: '',
    purchase_order_number: '',
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    is_invoice: boolean
  ) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile) {
      // Check file extension

      const fileExtension = selectedFile.name
        .split('.')
        .pop()
        ?.toLowerCase()
        .trim() as string;

      if (!allowedExtension.has(fileExtension)) {
        setError("Invalid file type. Only PDF's files are allowed.");
        setFile(null);
        return;
      }

      // Check file size
      if (selectedFile.size > 1024 * 1024 * 5) {
        toast.error('File size exceeds 5 MB limit');
        is_invoice ? setFile(null) : setPurchaseOrder(null);
        return;
      }
    }
    is_invoice ? setFile(selectedFile) : setPurchaseOrder(selectedFile);
    setError('');
  };

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    is_invoice: boolean
  ) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    is_invoice ? setFile(selectedFile) : setPurchaseOrder(selectedFile);
    setError('');
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const formatFileSize = (size: number) => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
  };

  async function handleUpload() {
    if (file || purchaseOrder) {
      if (purchaseOrderNumber.length === 0 && purchaseOrder) {
        toast.error('Purchase order number is required');
      } else {
        const formData = new FormData();
        formData.append('invoice', file || '');
        formData.append('totalAmount', removeCommas(totalAmount));
        formData.append('invoiceNumber', invoiceNumber);
        formData.append('purchaseOrder', purchaseOrder || '');
        formData.append('purchaseOrderNumber', purchaseOrderNumber);

        !purchaseOrder && formData.delete('purchaseOrder');
        !purchaseOrderNumber && formData.delete('purchaseOrderNumber');
        setIsLoading(true);
        const { data, responseError, refreshError } =
          await uploadFileRequest(formData);
        if (data) {
          toast.success('Invoice uploaded successfully');
          if (config.PROD) {
            rudderAnalytics.track(
              'form_submit:invoice_uploaded',
              {
                label: 'invoice_uploaded',
                invoice_number: invoiceNumber,
                purchase_order_number: purchaseOrderNumber,
                page: document.title,
                page_url: window.location.href,
                page_search: window.location.search,
                vendor_name: vendor?.vendorName,
                vendor_email: vendor?.email,
                vendor_id: vendor?.id,
              },
              {
                integrations: {
                  All:
                    config.API_ENDPOINT?.search(/(localhost|staging)\./) === -1,
                },
              }
            );
          }
        }

        if (responseError) {
          const errObj = responseError?.response?.data;
          const errorMessage = (errObj as any)?.error_message
            ? (errObj as any)?.error_message
            : 'Something went wrong';
          toast.error(errorMessage);
          setIsLoading(false);
          return;
        }

        // logout if refreshToken failed to refresh
        if (refreshError) {
          logout();
        }

        setIsLoading(false);
        handleCancelInternally();
        setIsSuccessModalOpen(true);
        setRefreshInvoices(true);
      }
    }
  }

  function handleSuccessCancel() {
    setIsSuccessModalOpen(false);
  }

  function handleCancelInternally() {
    handleCancel();
    setError('');
    setFile(null);
    setPurchaseOrder(null);
    setInvoiceNumber('');
    setPurchaseOrderNumber('');
    setTotalAmount('');
  }

  return (
    <>
      <div className="w-[600px]">
        <Modal
          open={isModalOpen}
          width={700}
          okText={`${isLoading ? 'Processing...' : 'Send Document'}`}
          okButtonProps={{
            className: 'bg-[#006355]  mt-12 h-[50px] font-semibold',
            disabled: isLoading || !file || !invoiceNumber || !totalAmount,
          }}
          cancelButtonProps={{
            className: 'h-[50px] font-semibold !text-[#004b40]',
          }}
          onCancel={handleCancelInternally}
          onOk={handleUpload}
        >
          <div className="text-center">
            <h3 className="md:font-semibold text-lg text-[#07000F]">
              Upload Invoice
            </h3>
          </div>
          <div className="mt-4 mb-20 space-y-6">
            <div className="space-y-2">
              {!file ? (
                <section>
                  <h3>Invoice</h3>
                  <div className="flex flex-col items-center space-y-4 justify-center border rounded-lg min-h-[120px] bg-[#F8F9F9]">
                    <UploadIcon />
                    <div
                      onDrop={(e) => handleDrop(e, true)}
                      onDragOver={handleDragOver}
                    >
                      <label className="text-sm text-[#006355] font-medium cursor-pointer">
                        <span>Click to upload</span>
                        <input
                          type="file"
                          className="hidden"
                          onChange={(e) => {
                            handleFileChange(e, true);
                          }}
                          accept=".pdf"
                        />
                      </label>{' '}
                      <span className="text-sm text-[#4A5361] font-medium">
                        or drag and drop
                      </span>{' '}
                      <div className="font-normal text-xs text-[#4A5361] text-center">
                        PDF (max. 5MB)
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <div className="space-y-4 justify-center border border-[#006355] rounded-lg min-h-[120px] p-4">
                  <div className="flex items-center justify-between bg-[#F3FBFF] p-2 rounded-lg">
                    <div className="flex items-start space-x-4">
                      <UploadSuccessIcon />
                      <div>
                        <h6 className="font-medium text-sm text-[#4A5361]">
                          {truncateString(file.name, 30)}
                        </h6>
                        <h6 className="font-light text-xs text-[#4A5361]">
                          {formatFileSize(file.size)}
                        </h6>
                      </div>
                    </div>
                    <SuccessChecbox />
                  </div>
                  <div className="text-[#4A5361] font-normal text-sm">
                    <label className="text-sm text-[#006355] font-medium cursor-pointer">
                      <span>Replace</span>
                      <input
                        type="file"
                        className="hidden"
                        onChange={(e) => {
                          handleFileChange(e, true);
                        }}
                        accept=".pdf"
                      />
                    </label>{' '}
                    document
                  </div>
                </div>
              )}
              {!purchaseOrder ? (
                <section className="pt-4">
                  <h3>Purchasing order (optional)</h3>
                  <div className="flex flex-col items-center space-y-4 justify-center border rounded-lg min-h-[120px] bg-[#F8F9F9]">
                    <UploadIcon />
                    <div
                      onDrop={(e) => handleDrop(e, false)}
                      onDragOver={handleDragOver}
                    >
                      <label className="text-sm text-[#006355] font-medium cursor-pointer">
                        <span>Click to upload</span>
                        <input
                          type="file"
                          className="hidden"
                          onChange={(e) => {
                            handleFileChange(e, false);
                          }}
                          accept=".pdf"
                        />
                      </label>{' '}
                      <span className="text-sm text-[#4A5361] font-medium">
                        or drag and drop
                      </span>{' '}
                      <div className="font-normal text-xs text-[#4A5361] text-center">
                        PDF (max. 5MB)
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <section>
                  <div className="space-y-4 justify-center border border-[#006355] rounded-lg min-h-[120px] p-4">
                    <div className="flex items-center justify-between bg-[#F3FBFF] p-2 rounded-lg">
                      <div className="flex items-start space-x-4">
                        <UploadSuccessIcon />
                        <div>
                          <h6 className="font-medium text-sm text-[#4A5361]">
                            {truncateString(purchaseOrder.name, 30)}
                          </h6>
                          <h6 className="font-light text-xs text-[#4A5361]">
                            {formatFileSize(purchaseOrder?.size)}
                          </h6>
                        </div>
                      </div>
                      <SuccessChecbox />
                    </div>
                    <div className="text-[#4A5361] font-normal text-sm">
                      <label className="text-sm text-[#006355] font-medium cursor-pointer">
                        <span>Replace</span>
                        <input
                          type="file"
                          className="hidden"
                          onChange={(e) => {
                            handleFileChange(e, false);
                          }}
                          accept=".pdf"
                        />
                      </label>{' '}
                      document
                    </div>
                  </div>
                  <div
                    className="pt-2 text-red-600 cursor-pointer"
                    onClick={() => setPurchaseOrder(null)}
                  >
                    X Remove
                  </div>
                </section>
              )}
              {error && (
                <div className="mt-4 text-sm text-red-600">{error}</div>
              )}
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={uploadDocumentsSchema}
              onSubmit={(values, helpers) => helpers.resetForm()}
            >
              {({ setFieldValue }: FormikProps<uploadDocsInterface>) => (
                <form>
                  <div>
                    <TextFieldNumber
                      label="Total amount"
                      value={totalAmount}
                      setValue={setTotalAmount}
                      formatToAmount={true}
                      delimeter
                      name="total_amount"
                    />
                  </div>
                  <div className="pt-3">
                    <TextFieldNumber
                      label="Invoice number"
                      name="invoice_number"
                      value={invoiceNumber}
                      setValue={setInvoiceNumber}
                      onChange={() => {
                        setFieldValue('invoice_number', invoiceNumber);
                      }}
                    />
                  </div>
                  {purchaseOrder && (
                    <div className="pt-3">
                      <TextFieldNumber
                        label="Purchasing order number"
                        value={purchaseOrderNumber}
                        toolTipId="po-info"
                        showInfoIcon
                        setValue={setPurchaseOrderNumber}
                        disabled={!purchaseOrder}
                        name="purchase_order_number"
                        onChange={() => {
                          setFieldValue('invoice_number', invoiceNumber);
                        }}
                      />

                      <Tooltip
                        id="po-info"
                        place="bottom-start"
                        style={{
                          backgroundColor: '#fff',
                          color: '#162930',
                          border: '0.5px solid #EEEEEE',
                          borderRadius: '5px',
                          boxShadow: '0px 12px 26px -7px #5C696E1A',
                        }}
                      >
                        <span>
                          if you decide to add your purchase order you will be
                          required to add your purchase order number
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </form>
              )}
            </Formik>

            <div className="flex items-center justify-center space-x-4 p-4 bg-[#caffed3c] h-[80px] border-2 border-[#00635649] border-dashed rounded-lg text-[#484847] text-sm font-normal">
              <LightBulbIcon />
              <span>
                Your uploaded document will be sent to your merchant; an invoice
                will be created for this payment once it is approved
              </span>
            </div>
          </div>
        </Modal>
      </div>
      <InvoiceSuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        handleSuccessCancel={handleSuccessCancel}
        invoiceNumber={invoiceNumber}
        headerText="Document sent successfully!"
        subText="Your document has been successfully uploaded and sent 
        successfully to your merchant for approval"
      />
    </>
  );
};

export default UploadDocumentModal;
