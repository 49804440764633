import { AccountRoutes, AuthRoutes, ROUTE_PATHS } from './utils';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AuthLayout from './views/layouts/AuthLayout';
import Notfound from './views/Notfound';
import PrivateRoute from './views/layouts/PrivateRoute';
import Verification from './views/Verification';
import { useCallback, useEffect } from 'react';
import config from 'config';
import { initializeHotjar } from 'utils/hotjar';
import { rudderAnalytics } from 'utils/rudderEventGenerator';

const App = () => {
  const hotjarId = Number(config.HOTJAR_ID);
  const hotjarSnipVersion = Number(config.HOTJAR_SNIPPET_VERSION);

  if (config.PROD) {
    if (typeof rudderAnalytics.getAnonymousId() === 'undefined') {
      rudderAnalytics.ready(() => undefined);
    }
  }

  const bootHotJar = useCallback(
    (hotjarId: number, hotjarSnipVersion: number) => {
      initializeHotjar(hotjarId, hotjarSnipVersion);
    },
    []
  );

  useEffect(() => {
    const url = window.location.href;
    const prod = url?.search(/(localhost|staging)\./) === -1;
    if (prod) {
      bootHotJar(hotjarId, hotjarSnipVersion);
    }
  }, [bootHotJar, hotjarId, hotjarSnipVersion]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${ROUTE_PATHS.AUTH}/*`}
          element={
            <AuthLayout>
              <Routes>
                {AuthRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.element />}
                  />
                ))}
              </Routes>
            </AuthLayout>
          }
        />
        <Route
          path={`${ROUTE_PATHS.VERIFICATION}`}
          element={<Verification />}
        />
        <Route
          path={`${ROUTE_PATHS.ACCOUNT}/*`}
          element={
            <PrivateRoute>
              <Routes>
                {AccountRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={`${route.path}/*`}
                    element={<route.element />}
                  />
                ))}

                <Route path="*" element={<Notfound />} />
              </Routes>
            </PrivateRoute>
          }
        />
        <Route
          index
          element={
            <Navigate to={`${ROUTE_PATHS.ACCOUNT}${ROUTE_PATHS.DASHBOARD}`} />
          }
        />

        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
