import React from 'react';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Formik, FormikProps } from 'formik';

import Button from 'components/common/Button';
import UploadSuccessIcon from 'components/icons/UploadSuccessIcon';
import BackButtonIcon from 'components/icons/BackButtonIcon';
import SuccessModal from 'components/modals/SuccessModal';
import InputComponent from 'components/common/InputComponent';

import { ReactComponent as TipIcon } from 'assets/svg/tip-ideas.svg';
import { ReactComponent as UploadIcon } from 'assets/svg/upload.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg';
import { ReactComponent as SuccessIcon } from 'assets/svg/success.svg';

import { PHONE_CODES, ROUTE_PATHS, countries, kybDocsInterface } from 'utils';
import { kybUploadDocumentsSchema } from 'utils/validationSchema';
import { getSignedUrl, uploadKYBFiles } from 'functions/vendor-functions';
import { useAuth } from 'contexts/AuthContext';
import PhoneField from 'components/common/PhoneField';
import CustomSelectComponent from 'components/common/CustomSelect';
import FileUploader from 'components/common/fileUploader';
import axios from 'axios';

interface ICountry {
  country: string;
  alpha2Code: string;
  alpha3Code: string;
  numberCode: string;
  states: string[];
}

const Compliance: React.FC = () => {
  interface UploadedFileTypes {
    file: File;
    id: string;
  }

  const storageKey = '___vendor_compliance_doc__';

  const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFileTypes[]>(
    []
  );

  const storageValue = localStorage.getItem(storageKey);
  const savedSignedURL = storageValue ? JSON.parse(storageValue) : [];

  const [signedURLResponse, setSignedURLResponse] = React.useState<
    {
      fileName: string;
      fieldName: string;
      url: string;
    }[]
  >(savedSignedURL || []);

  const handleFileUpload = (file: File, id: string) => {
    setUploadedFiles((prevFiles) => {
      const existingFileIndex = prevFiles.findIndex((f) => f.id === id);
      if (existingFileIndex !== -1) {
        // Replace the existing file
        const newFiles = [...prevFiles];
        newFiles[existingFileIndex] = { file, id };
        return newFiles;
      } else {
        // Add new file
        return [...prevFiles, { file, id }];
      }
    });
  };

  const formatFileName = (fileName: string) => {
    switch (fileName) {
      case 'loan_agreement':
        return 'Loan agreement document';
      case 'cac_certificate':
        return 'CAC certificate';
      case 'cac_status_report':
        return 'CAC Status Report';
      case 'guarantee_document':
        return 'Deeds of personal guarantee document';
      default:
        break;
    }

    return fileName;
  };

  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const savedGuarantorDetails: kybDocsInterface = JSON.parse(
    sessionStorage.getItem('financial_guarantor_details') || '{}'
  );
  const initialValues: kybDocsInterface = {
    name: savedGuarantorDetails?.name || '',
    phone: savedGuarantorDetails?.phone || '',
    email: savedGuarantorDetails?.email || '',
    address: savedGuarantorDetails?.address || '',
    country: savedGuarantorDetails?.country || '',
    city: savedGuarantorDetails?.city || '',
    state: savedGuarantorDetails?.state || '',
    dial_code: savedGuarantorDetails?.dial_code || '',
  };

  const [isSavingFiles, setIsSavingFiles] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState<string>(
    savedGuarantorDetails?.country ||
      countries.find(({ alpha2Code }) => alpha2Code === 'NG')?.country ||
      ''
  );
  const [selectedState, setSelectedState] = React.useState(
    savedGuarantorDetails?.state || ''
  );
  const [selectedDialCode, setSelectedDialCode] = React.useState(
    savedGuarantorDetails?.dial_code ||
      PHONE_CODES?.find(({ dial_code }) => dial_code === '+234')?.dial_code ||
      ''
  );

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleDocsSubmit = async () => {
    if (uploadedFiles.length !== 4) {
      return toast.error('Ensure all documents are uploaded');
    }
    {
      const body = Array.from(uploadedFiles).map((file) => {
        return {
          fileName: file.file.name,
          fieldName: file.id,
          contentType: file.file.type,
        };
      });
      setIsSavingFiles(true);
      try {
        const { data, responseError, refreshError } = await getSignedUrl(body);
        if (responseError) {
          const errObj = responseError?.response?.data;
          const errorMessage = (errObj as any)?.error_message
            ? (errObj as any)?.error_message
            : 'Something went wrong';
          toast.error(errorMessage);
          return;
        }

        if (refreshError) {
          logout();
          return;
        }

        if (data && data.data && data.data.length > 0) {
          try {
            localStorage.setItem(storageKey, JSON.stringify(body));
            setSignedURLResponse(data.data);
            await Promise.all(
              data.data.map(
                async ({
                  fieldName,
                  url,
                }: {
                  fileName: string;
                  fieldName: string;
                  url: string;
                }) => {
                  const filteredFile = uploadedFiles.find(
                    (el) => el.id === fieldName
                  );

                  await axios.put(url, filteredFile?.file, {
                    headers: {
                      'Content-Type': filteredFile?.file.type,
                    },
                  });
                }
              )
            );
            toast.success('Upload successful');
            setCurrentStep(2);
          } catch (error) {
            toast.error('Error uploading files');
          } finally {
            setIsSavingFiles(false);
          }

          return;
        }
      } catch (error) {
        toast.error('Error uploading files');
      } finally {
        setIsSavingFiles(false);
      }
    }
  };
  const handleGuarantorSubmit = (values: kybDocsInterface) => {
    values['country'] = selectedCountry;
    values['state'] = selectedState;
    values['dial_code'] = selectedDialCode;
    sessionStorage.setItem(
      'financial_guarantor_details',
      JSON.stringify(values)
    );

    const emptyInputs = Array.from(document.querySelectorAll('section')).filter(
      (_) => _?.querySelector('input')
    );

    if (emptyInputs.length !== 0) {
      toast.error('Ensure all documents are uploaded');
      Array.from(emptyInputs).forEach((el) => {
        const childNode = el.querySelector('.flex-col');
        childNode?.classList.add('border-red-500');
      });
    } else {
      setCurrentStep(3);
    }
  };

  const handleCancel = () => {
    navigate(ROUTE_PATHS.ACCOUNT + ROUTE_PATHS.DASHBOARD, { replace: true });
    sessionStorage.clear();
  };

  const formatCountries = countries.map(({ country }) => ({
    option: country,
    value: country,
  }));
  const formatStates = (selectedCountry: string) => {
    const setCountry: ICountry | undefined = countries?.find(
      ({ country }) => country === selectedCountry
    );
    return setCountry
      ? setCountry?.states?.map((state) => ({ option: state, value: state }))
      : [];
  };
  const uploadComplianceDocuments = async () => {
    const guarantorPhone =
      savedGuarantorDetails?.phone?.replace(/-/g, '') || '';

    const payload = {
      files: signedURLResponse,
      guarantorDetails: {
        country: savedGuarantorDetails?.country || '',
        state: savedGuarantorDetails?.state || '',
        city: savedGuarantorDetails?.city || '',
        address: savedGuarantorDetails?.address || '',
        email: savedGuarantorDetails?.email || '',
        phoneNumber: guarantorPhone,
        dialCode: '+234',
        fullName: savedGuarantorDetails?.name || '',
      },
    };

    try {
      setIsSavingFiles(true);
      const { data, responseError, refreshError } =
        await uploadKYBFiles(payload);
      if (data) {
        setIsModalOpen(true);

        for (let i = 0; i < sessionStorage.length; i++) {
          const savedKey = sessionStorage.key(i);
          if (savedKey?.includes(storageKey)) {
            sessionStorage.clear();
            setIsSavingFiles(false);
          }
        }
      }

      if (responseError) {
        const errObj = responseError?.response?.data;
        const errorMessage = (errObj as any)?.error_message
          ? (errObj as any)?.error_message
          : 'Something went wrong';
        toast.error(errorMessage);
        setIsSavingFiles(false);
        return;
      }

      // logout if refreshToken failed to refresh
      if (refreshError) {
        logout();
      }
    } catch (err: any) {
      if (err?.length > 0) {
        err?.forEach((err: string) => toast.error(err));
        return;
      }
      setIsSavingFiles(false);
      setIsModalOpen(false);
      toast.error(`${err.message}`);
    }
  };

  const handleRemoveFile = (fileId: string) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileId)
    );
  };

  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 bg-[#F8F9F9] min-h-screen overflow-y-auto`}
    >
      <div className="flex items-center justify-between p-4 bg-white border-b sticky top-0  z-20">
        <div
          className="cursor-pointer"
          onClick={
            currentStep === 1
              ? () => handleCancel()
              : currentStep === 2
                ? () => setCurrentStep(1)
                : () => setCurrentStep(2)
          }
        >
          <BackButtonIcon />
        </div>
        <div className="font-semibold text-lg text-[#07000F]">
          Vendor Compliance
        </div>
        <Button
          className="!w-fit py-2 px-6 text-xs"
          loading={isSavingFiles}
          disabled={isSavingFiles}
          {...(currentStep === 1
            ? {
                form: 'compliance-docs-form',
                type: 'submit',
                text: 'Preview',
              }
            : currentStep === 2
              ? {
                  form: 'compliance-guarantor-form',
                  type: 'submit',
                  text: 'Guarantor details',
                }
              : {
                  handleClick: () => uploadComplianceDocuments(),
                  text: 'Submit',
                  type: 'button',
                })}
        />
      </div>
      <div
        className={`md:w-[95%] w-[90%] mt-10 mx-auto bg-white rounded-lg  md:grid-cols-6 md:grid md:px-10 px-5 py-5 md:space-x-8`}
      >
        <div
          className={`col-span-2 border-t-4 border-0 ${
            currentStep === 1 ? 'border-t-[#3882E0]' : 'border-t-[#25BB87]'
          } h-fit sticky z-10 top-0  p-3 pl-0 `}
        >
          <p
            className={`ml-0 mb-1 mt-3 text-xs ${
              currentStep === 1 ? ' text-[#3882E0]' : 'text-[#25BB87]'
            }`}
          >
            Step 1
          </p>
          <p className="ml-0 font-bold text-xs">
            Invoice Financing Requirement
          </p>
        </div>
        <div
          className={`col-span-2 border-t-4 border-0 ${
            currentStep === 1
              ? 'border-t-[#C8D0D3]'
              : currentStep === 2
                ? 'border-t-[#3882E0]'
                : 'border-t-[#25BB87]'
          } h-fit sticky z-10 top-0  p-3 pl-0 `}
        >
          <p
            className={`ml-0 mb-1 mt-3 text-xs ${
              currentStep === 2
                ? ' text-[#3882E0]'
                : currentStep === 3
                  ? 'text-[#25BB87]'
                  : 'text-[#787B7E]'
            }`}
          >
            Step 2
          </p>
          <p className="ml-0 font-bold text-xs">Guarantor details</p>
        </div>
        <div
          className={`col-span-2 border-t-4 border-0 ${
            currentStep === 1 || currentStep === 2
              ? 'border-t-[#C8D0D3]'
              : 'border-t-[#3882E0]'
          } h-fit sticky z-10 top-0  p-3 pl-0 `}
        >
          <p
            className={`ml-0 mb-1 mt-3 text-xs ${
              currentStep === 1 || currentStep === 2
                ? ' text-[#787B7E]'
                : 'text-[#3882E0]'
            }`}
          >
            Step 3
          </p>
          <p className="ml-0 font-bold text-xs">Preview</p>
        </div>
        <div
          className={`overflow-auto col-span-3 ${
            currentStep === 1 ? 'h-[80vh]' : 'h-full'
          }`}
        >
          <div
            className={`mt-6 md:border md:rounded-lg md:border-[#F8F9F9] md:p-8  pb-2 ${
              currentStep === 1 ? 'block' : 'hidden'
            }`}
          >
            <h1 className="font-semibold text-[#162930] text-xl">
              Financing Requirement
            </h1>
            <p className="pt-2 font-light text-sm mb-10">
              Provide all the required documents
            </p>
            <Formik
              initialValues={{}}
              onSubmit={handleDocsSubmit}
              enableReinitialize
            >
              {({ handleSubmit }: FormikProps<{}>) => (
                <form
                  className="space-y-8 "
                  onSubmit={handleSubmit}
                  id="compliance-docs-form"
                >
                  <FileUploader
                    label="Loan agreement document"
                    onFileUpload={handleFileUpload}
                    allowedTypes={['pdf']}
                    maxSizeMB={5}
                    isRequired
                    id="loan_agreement"
                    toolTip={
                      <span>
                        Download the loan agreement document provided and fill
                        all information for us to review <br />
                        so you can have access to invoice financing
                      </span>
                    }
                    handleRemoveFile={(e) => handleRemoveFile(e)}
                  />

                  <FileUploader
                    label="Deeds of personal guarantee document"
                    onFileUpload={handleFileUpload}
                    allowedTypes={['pdf']}
                    maxSizeMB={5}
                    isRequired
                    id="guarantee_document"
                    toolTip={
                      <span>
                        Download the Deeds of personal guarantee document
                        provided and fill all <br />
                        information for us to review so you can have access to
                        invoice financing
                      </span>
                    }
                    handleRemoveFile={(e) => handleRemoveFile(e)}
                  />

                  <FileUploader
                    label="CAC certificate"
                    onFileUpload={handleFileUpload}
                    allowedTypes={['pdf']}
                    maxSizeMB={5}
                    isRequired
                    id="cac_certificate"
                    handleRemoveFile={(e) => handleRemoveFile(e)}
                  />

                  <FileUploader
                    label="CAC Status Report"
                    onFileUpload={handleFileUpload}
                    allowedTypes={['pdf']}
                    maxSizeMB={5}
                    isRequired
                    id="cac_status_report"
                    handleRemoveFile={(e) => handleRemoveFile(e)}
                  />
                </form>
              )}
            </Formik>

            <div className="bg-[#caffed38] p-3 md:py-4 md:px-5 border-dashed border border-[#00635580] rounded-lg flex items-center gap-2 md:gap-4 mt-10">
              <TipIcon className="shrink-0" />
              <p className=" text-xs md:text-sm font-light">
                If you don’t have CAC Status Report you can either provide CAC
                1.1 as a Sole Proprietorship or CAC 2 & 7 as a limited liability
                company.
              </p>
            </div>
          </div>
        </div>

        <div className="col-span-3">
          <div
            className={`mt-8 md:rounded-lg bg-[#caffed38] md:p-8  pb-2 ${
              currentStep === 1 ? 'block' : 'hidden'
            }`}
          >
            <div className="md:rounded-lg bg-white p-5 md:p-8 shadow-md">
              <h1 className="font-semibold text-[#162930] text-lg">
                Upload requirements
              </h1>
              <ul className="mt-6 list-disc ml-3.5 mb-9">
                <li className="text-sm cursor-pointer mb-4">
                  <a
                    className="text-[#006355] font-light underline"
                    href={'/documents/Loan Agreement-Invoice Financing.docx'}
                    download
                  >
                    Download Loan agreement document here{' '}
                    <span className="shrink-0 inline-block">
                      <UploadIcon className="ml-1 inline" />
                    </span>
                  </a>
                </li>
                <li className="text-sm cursor-pointer mb-4">
                  <a
                    className="text-[#006355] font-light underline"
                    href="/documents/Deed of Personal Guarantee - Invoice Financing.pdf"
                    download
                  >
                    Download Deed of Personal Guarantee document here{' '}
                    <span className="shrink-0 inline-block">
                      <UploadIcon className="ml-1 inline" />
                    </span>
                  </a>
                </li>
                <li className="text-sm font-light mb-3.5">
                  Ensure that all required field on both documents are filled
                  and signed
                </li>
                <li className="text-sm font-light mb-3.5">
                  Upload all four required documents for verification
                </li>
              </ul>
              <div className="bg-[#caffed38] p-3 md:py-4 md:px-5 border-dashed border border-[#00635580] rounded-lg flex items-center gap-2 md:gap-4">
                <TipIcon className="shrink-0" />
                <p className=" text-xs md:text-sm font-light">
                  We will get back to you with an update within 48 business
                  hours.
                </p>
              </div>
            </div>
          </div>

          {/*Guarantor's Details */}
        </div>
        {currentStep === 2 && (
          <div className=" mt-8  p-4 md:p-8 !mx-auto pb-2 col-span-6  border md:rounded-lg">
            <h1 className="font-semibold text-[#162930] text-xl">
              Guarantor's Details
            </h1>
            <p className="pt-2 font-light text-sm mb-4">
              Fill in your guarantor's details (The person who signed off your
              deeds of personal guarantee document)
            </p>

            <Formik
              initialValues={initialValues}
              onSubmit={handleGuarantorSubmit}
              validationSchema={kybUploadDocumentsSchema}
              enableReinitialize
            >
              {({ handleSubmit }: FormikProps<kybDocsInterface>) => (
                <form
                  className="space-y-8 mt-12 "
                  onSubmit={handleSubmit}
                  id="compliance-guarantor-form"
                >
                  <div className="grid md:grid-cols-2 gap-x-4 gap-y-4">
                    <div className="">
                      <InputComponent
                        label="Full name"
                        name="name"
                        labelStyles="!font-light md:mb-2 mb-0"
                        className="!border"
                      />
                    </div>
                    <div>
                      <PhoneField
                        name="phone"
                        label="Phone Number"
                        type="tel"
                        labelStyles="!font-light mb-2"
                        className="!border font-light"
                        valueSelect={selectedDialCode}
                        isSearchable
                        setValueSelect={setSelectedDialCode}
                      />
                    </div>
                  </div>

                  <div>
                    <InputComponent
                      label="Guarantor's email address"
                      name="email"
                      labelStyles="!font-light mb-2"
                      className="!border"
                    />
                  </div>
                  <div className="grid md:grid-cols-2 gap-x-4 gap-y-4">
                    <div>
                      <CustomSelectComponent
                        value={selectedCountry}
                        setValue={(e) => {
                          setSelectedCountry(e);
                          setSelectedState('');
                        }}
                        list={formatCountries}
                        label="Country"
                        labelStyles="mb-2"
                        name="country"
                        dropDownStyles="!relative !max-h-60"
                      />
                    </div>
                    <div>
                      <CustomSelectComponent
                        value={selectedState}
                        setValue={setSelectedState}
                        list={formatStates(selectedCountry)}
                        label="State"
                        labelStyles="mb-2"
                        name="state"
                        dropDownStyles="!relative !max-h-60"
                      />
                    </div>
                    <div>
                      <InputComponent
                        label="Address"
                        name="address"
                        labelStyles="!font-light mb-2"
                        className="!border"
                      />
                    </div>

                    <div>
                      <InputComponent
                        label="City"
                        name="city"
                        labelStyles="!font-light mb-2"
                        className="!border"
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
        {currentStep === 3 && (
          <div className=" mt-8  md:p-8  pb-2 col-span-4 col-start-2">
            <h1 className="font-semibold text-[#162930] text-xl">Preview</h1>
            <p className="pt-2 font-light text-sm mb-4">
              Preview all documents before submitting
            </p>

            <div className="md:rounded-lg bg-white p-5 md:p-8 shadow-[0_12px_26px_-7px_rgba(92,105,110,0.10)]">
              <div className="flex items-center border-b pb-7">
                <h1 className="font-semibold text-[#162930] text-lg flex-1">
                  Guarantor’s Details
                </h1>
                <button
                  className="bg-[#caffed38] py-1 px-3 text-xs text-[#006355] rounded flex items-center gap-1"
                  onClick={() => setCurrentStep(2)}
                >
                  <EditIcon /> Edit
                </button>
              </div>
              <ul className="md:mt-6 ml-3.5 grid md:grid-cols-6 gap-x-4 border-b pb-5 md:pb-8 mb-9">
                <div className="md:col-span-2">
                  <li className="text-sm mb-4 mt-5">
                    <h6 className="text-sm font-medium mb-2.5 text-[#8B8B8B]">
                      Guarantor’s Name
                    </h6>
                    <div className="font-light">
                      {savedGuarantorDetails?.name || '-'}
                    </div>
                  </li>
                  <li className="text-sm mb-4">
                    <h6 className="text-sm font-medium mb-2.5 text-[#8B8B8B]">
                      Guarantor’s Email Address
                    </h6>
                    <div className="font-light">
                      {savedGuarantorDetails?.email || '-'}
                    </div>
                  </li>
                  <li className="text-sm mb-4">
                    <h6 className="text-sm font-medium mb-2.5 text-[#8B8B8B]">
                      Guarantor’s Country
                    </h6>
                    <div className="font-light">
                      {savedGuarantorDetails?.country || '-'}
                    </div>
                  </li>
                </div>
                <div className="md:col-span-2">
                  <li className="text-sm mb-4 mt-5">
                    <h6 className="text-sm font-medium mb-2.5 text-[#8B8B8B]">
                      Guarantor’s Address
                    </h6>
                    <div className="font-light">
                      {savedGuarantorDetails?.address || '-'}
                    </div>
                  </li>
                  <li className="text-sm mb-4">
                    <h6 className="text-sm font-medium mb-2.5 text-[#8B8B8B]">
                      Guarantor’s City
                    </h6>
                    <div className="font-light">
                      {savedGuarantorDetails?.city || '-'}
                    </div>
                  </li>
                  <li className="text-sm mb-4">
                    <h6 className="text-sm font-medium mb-2.5 text-[#8B8B8B]">
                      Guarantor’s State
                    </h6>
                    <div className="font-light">
                      {savedGuarantorDetails?.state || '-'}
                    </div>
                  </li>
                </div>
                <div className="md:col-span-2">
                  <li className="text-sm mb-4 md:mt-5">
                    <h6 className="text-sm font-medium mb-2.5 text-[#8B8B8B]">
                      Guarantor’s Phone Number
                    </h6>
                    <div className="font-light">
                      {savedGuarantorDetails?.phone || '-'}
                    </div>
                  </li>
                  <li className="text-sm mb-4">
                    <h6 className="text-sm font-medium mb-2.5 text-[#8B8B8B]">
                      Guarantor’s Dial Code
                    </h6>
                    <div className="font-light">
                      {savedGuarantorDetails?.dial_code || '-'}
                    </div>
                  </li>
                </div>
              </ul>
              <div className="flex items-center pb-7">
                <h1 className="font-semibold text-[#162930] text-lg flex-1">
                  Business Documents
                </h1>
                <button
                  className="bg-[#caffed38] py-1 px-3 text-xs text-[#006355] rounded flex items-center gap-1"
                  onClick={() => setCurrentStep(1)}
                >
                  <EditIcon /> Edit
                </button>
              </div>
              <ul className="md:mt-6 ml-3.5 grid md:grid-cols-2 gap-x-4 ">
                {signedURLResponse.map(({ fieldName, fileName }, index) => (
                  <li
                    className="text-sm cursor-pointer mb-4"
                    key={fieldName + index}
                  >
                    <h6 className="text-sm font-light mb-2.5">
                      {formatFileName(fieldName)}
                      <span className="text-rose-500">*</span>
                    </h6>
                    <div className="justify-center border border-[#006355]/50  rounded-lg p-4 mb-8">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <UploadSuccessIcon />
                          <div>
                            <h6 className="font-semibold text-sm text-[#4A5361]">
                              {fileName}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <SuccessModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title="Compliance"
      >
        <div className="flex flex-col items-center justify-center px-5">
          <SuccessIcon className="my-5" />
          <h1 className="text-lg mb-3 font-semibold">
            Account verification request sent!
          </h1>
          <p className="text-center text-sm">
            We are currently reviewing your documents. We will get back to you
            with an update within 48 business hours.
          </p>
          <Button
            className="!w-fit py-2.5 px-6 text-sm mt-10 mb-5"
            type="button"
            text="Go home"
            handleClick={() => {
              handleCancel();
              setIsModalOpen(false);
            }}
          />
        </div>
      </SuccessModal>
    </div>
  );
};

export default Compliance;
