import React from 'react';
import Loader from './Loader';

interface IProps {
  text: string;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  handleClick?: () => void;
  className?: string;
  form?: string;
  loading?: boolean;
}
const Button: React.FC<IProps> = ({
  text,
  handleClick,
  disabled = false,
  type,
  className,
  loading,
  ...attributes
}) => {
  return (
    <button
      className={`${
        disabled ? 'bg-[#006356b4]' : 'bg-[#006355]'
      } text-white font-normal text-md p-2 w-full rounded-lg ${className}`}
      onClick={handleClick}
      disabled={disabled}
      type={type}
      {...attributes}
    >
      {loading ? <Loader size="sm" /> : text}
    </button>
  );
};

export default Button;
