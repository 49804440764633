import moment from 'moment';
import { ReactComponent as SuccessIcon } from 'assets/svg/success.svg';
import { InvoiceAuditActions } from 'utils';
import { capitaliseFirstLetterOfAllWordsInSentence } from 'utils/format';
import Avatar from 'components/common/Avatar';

export default function AuditTrail({ info }: any) {
  const allEvents: any[] = info?.data;

  const getAuditEvents = (singleEvent: any) => {
    const initiatorName = singleEvent.initiator_name;
    const id = singleEvent._id;
    const date = singleEvent.created_at;
    const capitalisedInitiatorName =
      capitaliseFirstLetterOfAllWordsInSentence(initiatorName);

    switch (singleEvent.action) {
      case InvoiceAuditActions.CREATED:
        return (
          <li className="flex flex-col " key={id}>
            <div className="flex items-center">
              <Avatar name={initiatorName} />
              <small className="py-2 mx-5 text-sm">
                <span className="font-bold">{capitalisedInitiatorName}</span>
                {` sent a document • ${moment(date).fromNow()}`}
              </small>
            </div>
          </li>
        );
      case InvoiceAuditActions.APPROVED:
        return (
          <li className="flex flex-col " key={id}>
            <div className="w-px h-2 ml-4 border border-gray-300 border-solid "></div>
            <div className="flex items-center">
              <SuccessIcon className="w-8 h-8" />
              <small className="py-2 mx-5 text-sm">
                <span className="font-bold">{`Document approved by ${capitalisedInitiatorName}`}</span>
                {` • ${moment(date).fromNow()}`}
              </small>
            </div>
          </li>
        );
      case InvoiceAuditActions.CONVERTED_TO_BILL:
        return (
          <li className="flex flex-col " key={id}>
            <div className="w-px h-2 ml-4 border border-gray-300 border-solid"></div>
            <div className="flex items-center">
              <Avatar name={initiatorName} />
              <small className="py-2 mx-5 text-sm">
                <span className="font-bold">{capitalisedInitiatorName}</span>
                {` created this invoice • ${moment(date).fromNow()}`}
              </small>
            </div>
          </li>
        );
      case InvoiceAuditActions.INVOICE_FINANCING_REQUEST:
        return (
          <li className="flex flex-col " key={id}>
            <div className="w-px h-2 my-1 ml-4 border border-gray-300 border-solid "></div>
            <div className="flex items-center">
              <Avatar name={initiatorName} />
              <small className="py-2 mx-5 text-sm">
                <span className="font-bold">{capitalisedInitiatorName}</span>
                {` requested for invoice financing • ${moment(date).fromNow()}`}
              </small>
            </div>
          </li>
        );
      case InvoiceAuditActions.INVOICE_FINANCING_APPROVED: {
        const comment = singleEvent?.data?.reason;
        return (
          <li key={id}>
            {comment && (
              <div className="flex flex-col ">
                <div className="w-px h-2 my-1 ml-4 border border-gray-300 border-solid "></div>
                <div className="flex">
                  <Avatar name={initiatorName} />
                  <div className="flex flex-col py-3 pr-10 ml-6 border border-gray-300 border-solid rounded-xl">
                    <small className="py-2 ml-2 text-sm">
                      <span className="font-bold">
                        {capitalisedInitiatorName}
                      </span>
                      {` commented • ${moment(date).fromNow()}`}
                    </small>
                    <span className="ml-4">{comment}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col ">
              <div className="w-px h-2 my-1 ml-4 border border-gray-300 border-solid "></div>
              <div className="flex items-center">
                <SuccessIcon className="w-8 h-8" />
                <small className="py-2 mx-5 text-sm">
                  <span className="font-bold">{`Financing request approved by ${capitalisedInitiatorName}`}</span>
                  {` • ${moment(date).fromNow()}`}
                </small>
              </div>
            </div>
          </li>
        );
      }
      case InvoiceAuditActions.INVOICE_FINANCING_REJECTED: {
        const comment = singleEvent?.data?.reason;
        return (
          <li key={id}>
            {comment && (
              <div className="flex flex-col ">
                <div className="w-px h-2 my-1 ml-4 border border-gray-300 border-solid "></div>
                <div className="flex">
                  <Avatar name={initiatorName} />
                  <div className="flex flex-col py-3 pr-10 ml-6 border border-gray-300 border-solid rounded-xl">
                    <small className="py-2 ml-2 text-sm">
                      <span className="font-bold">
                        {capitalisedInitiatorName}
                      </span>
                      {` commented • ${moment(date).fromNow()}`}
                    </small>
                    <span className="ml-4">{comment}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col ">
              <div className="w-px h-2 my-1 ml-4 border border-gray-300 border-solid "></div>
              <div className="flex items-center">
                <Avatar name={initiatorName} />
                <small className="py-2 mx-5 text-sm">
                  <span className="font-bold">{`Financing request rejected by ${capitalisedInitiatorName}`}</span>
                  {` • ${moment(date).fromNow()}`}
                </small>
              </div>
            </div>
          </li>
        );
      }
      default:
        return (
          <>
            <li className="flex flex-col " key={id}>
              <div className="w-px h-2 my-1 ml-4 border border-gray-300 border-solid "></div>
              <div>
                <Avatar name={initiatorName} />
                <small className="py-2 mx-5 text-sm font-bold">
                  {`${initiatorName} ${singleEvent.description.replace(
                    initiatorName,
                    ''
                  )} • ${moment(date).fromNow()}`}
                </small>
              </div>
            </li>
          </>
        );
    }
  };

  return (
    <>
      <div className="uppercase bg-[#f8f9f9] my-5 text-[14px] font-bold p-4 text-[#484847CC] rounded-t-md">
        Audit Trail
      </div>
      <ul className="px-5">
        {allEvents && allEvents?.map((singleEvent) => getAuditEvents(singleEvent))}
      </ul>
    </>
  );
}
