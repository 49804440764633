import React from 'react';
import SettingsLayout from '../../components/settings/SettingsLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AccountSettingsRoutes } from '../../utils/route';
import Notfound from '../Notfound';
import { ACCOUNT_SETTINGS_PATHS, ROUTE_PATHS } from '../../utils';

export default function Settings() {
  return (
    <div className="md:p-10">
      <h1 className="mb-3 item-header-large">Settings</h1>
      {/* Mobile Nav */}
      <SettingsLayout>
        <Routes>
          {AccountSettingsRoutes.map((route, index) => (
            <Route
              key={index}
              path={`${route.path}/*`}
              element={<route.element />}
            />
          ))}
          <Route
            index
            element={
              <Navigate
                to={`${ROUTE_PATHS.ACCOUNT}${ROUTE_PATHS.SETTINGS}${ACCOUNT_SETTINGS_PATHS.BUSINESS}`}
              />
            }
          />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </SettingsLayout>
    </div>
  );
}
