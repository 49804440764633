import { Modal } from 'antd';

interface IDocModal {
  open: boolean;
  closeModal: () => void;
  url: string | undefined;
}

const DocumentPreviewModal = ({ open, closeModal, url }: IDocModal) => {
  return (
    <>
      <div>
        <Modal footer={null} onCancel={closeModal} open={open}>
          <div className="mt-7 h-[500px]">
            <iframe
              src={`https://docs.google.com/viewerng/viewer?url=${url}&embedded=true`}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DocumentPreviewModal;
