import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  data: {
    topText: string;
    amount: string;
    subText?: string;
    linkText: string;
    href: string;
    bgColor: string;
    linkColor: string;
    linkTextColor: string;
  };
}

const DashboardCard: React.FC<IProps> = ({ data }) => {
  return (
    <div
      className="flex flex-col px-6 py-10 space-y-3 rounded-xl"
      style={{ backgroundColor: `${data.bgColor}` }}
    >
      <span className="font-medium text-sm text-[#484847]">{data.topText}</span>
      <div className="flex flex-col space-y-1">
        <span className="font-semibold text-[22px] text-black">
          {data.amount}
        </span>
        {data.subText && (
          <span className="text-xs font-normal text-gray-600">
            {data.subText}
          </span>
        )}
      </div>
      <div
        className="w-[50%] p-3 rounded-md text-center font-semibold text-sm"
        style={{
          backgroundColor: `${data.linkColor}`,
          color: `${data.linkTextColor}`,
        }}
      >
        <Link to={data.href}>{data.linkText}</Link>
      </div>
    </div>
  );
};

export default DashboardCard;
