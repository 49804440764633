import { ReactComponent as Icon } from 'assets/svg/exclamation-mark 1.svg';
import { FaArrowRight } from 'react-icons/fa';

interface BannerProps {
  clickToOpen: () => void;
}

function InvoiceNotificationBanner({ clickToOpen }: BannerProps) {
  return (
    <section className="flex justify-center py-3">
      <div className="flex justify-between items-center w-7/12 py-3 px-4 bg-[#FFF6C8] rounded-lg">
        <div className="flex items-center gap-3">
          <Icon />
          <p className="text-[#3E3B07]">
            You’ve got an update to your financing request
          </p>
        </div>
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={clickToOpen}
        >
          <div className="border-b-2 border-[#3E3B07]">View details</div>
          <div>
            <span>
              <FaArrowRight />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InvoiceNotificationBanner;
