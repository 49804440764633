import React from 'react';

interface IProps {
  label?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  setValue: (val: string) => void;
}

const TextField: React.FC<IProps> = ({
  label,
  value,
  setValue,
  disabled = false,
  placeholder,
}) => {
  return (
    <div className="flex flex-col space-y-2">
      <span className="text-sm font-light text-[#192850]">{label}</span>
      <input
        className="border-2 rounded-md h-12 outline-[#006355] p-2"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextField;
