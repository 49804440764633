import React, { useRef, useState } from 'react';
import AuthContainer from '../../components/auth/AuthContainer';
import PasswordField from '../../components/common/PasswordField';
import { Link, Navigate } from 'react-router-dom';
import Button from '../../components/common/Button';
import FormFooter from '../../components/common/FormFooter';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { LoginInterface, ROUTE_PATHS } from '../../utils';
import InputComponent from '../../components/common/InputComponent';
import { Formik, FormikProps } from 'formik';
import { loginSchema } from '../../utils/validationSchema';
import toast from 'react-hot-toast';
import URL_CONFIG from 'config';

const initialValues: LoginInterface = {
  email: '',
  password: '',
};

export default function Login() {
  const [isSigninComplete, setIsSigninComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const redirectUrl = useRef(ROUTE_PATHS.ACCOUNT + ROUTE_PATHS.DASHBOARD);
  const { login } = useAuth();

  async function handleSignin(values: LoginInterface) {
    setIsProcessing(true);
    try {
      const payload: LoginInterface = {
        email: values.email.toLowerCase(),
        password: values.password,
      };
      const response = await axios.post(
        `${URL_CONFIG.API_ENDPOINT}/auth/login`,
        payload
      );

      const { status, access_token, refresh_token } = response.data;
      if (status === 'SUCCESS') {
        toast.success('login successful');
        login(access_token, refresh_token);
        setTimeout(() => {
          setIsSigninComplete(true);
        }, 2000);
      }
    } catch (error: any) {
      // Handle error
      const errObj = error?.response?.data;
      const errorMessage = errObj?.error_message
        ? errObj?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
    }
    setIsProcessing(false);
  }

  if (isSigninComplete) {
    return <Navigate to={redirectUrl.current} replace />;
  }

  return (
    <AuthContainer headerText="Hey, welcome back 👋">
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={handleSignin}
      >
        {({ handleSubmit }: FormikProps<LoginInterface>) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <InputComponent label="Email address" name="email" />
            <PasswordField name="password" label="Password" />
            <div className="flex justify-end text-[#006355] font-normal text-sm md:text-base">
              <Link to={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.FORGOT_PASSWORD}`}>
                Forgot your pasword?
              </Link>
            </div>
            <Button text="Sign in" disabled={isProcessing} loading={isProcessing} />
          </form>
        )}
      </Formik>
      <FormFooter
        text="New to Duplo for vendors?"
        link={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.SIGNUP}`}
        linkText="Get Started"
      />
    </AuthContainer>
  );
}
