import React, { useEffect, useState } from 'react';
import Tab from '../common/Tab';
import { FilterInterface, ITab, VendorInvoice } from '../../utils';
import InvoicesList from './InvoicesList';
import { getVendorInvoices } from '../../functions/vendor-functions';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';

export default function UploadedDocuments() {
  const [isLoading, setIsLoading] = useState(false);
  const [refreshInvoices, setRefreshInvoices] = useState(false);
  const [pendingInvoices, setPendingInvoices] = useState<VendorInvoice[]>([]);
  const [approvedInvoices, setApprovedInvoices] = useState<VendorInvoice[]>([]);
  const [rejectedInvoices, setRejectedInvoices] = useState<VendorInvoice[]>([]);

  const [filters] = useState<FilterInterface>({
    page: 1,
    perPage: 20,
    limit: 20,
    pageCount: 1,
  });
  const { logout } = useAuth();

  async function fetchVendorInvoices() {
    setIsLoading(true);
    const { data, responseError, refreshError } = await getVendorInvoices({
      filters,
    });

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }

    const invoiceData: VendorInvoice[] = data?.vendorInvoices;

    const inverseDocs = invoiceData?.sort(
      (fir, sec) => Date.parse(sec.createdAt) - Date.parse(fir.createdAt)
    );

    const pending = inverseDocs?.filter(
      (invoice: VendorInvoice) => invoice.status === 'PENDING'
    );

    const approved = inverseDocs?.filter(
      (invoice: VendorInvoice) => invoice.status === 'APPROVED'
    );

    const rejected = inverseDocs?.filter(
      (invoice: VendorInvoice) => invoice.status === 'DECLINED'
    );

    setIsLoading(false);
    setPendingInvoices(pending);
    setApprovedInvoices(approved);
    setRejectedInvoices(rejected);
    setRefreshInvoices(false);
  }

  useEffect(() => {
    fetchVendorInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInvoices]);

  const tabContent: ITab[] = [
    {
      key: 'pending',
      label: `Pending (${pendingInvoices.length})`,
      content: (
        <InvoicesList invoices={pendingInvoices} isLoading={isLoading} />
      ),
    },
    {
      key: 'approved',
      label: `Approved (${approvedInvoices.length})`,
      content: (
        <InvoicesList invoices={approvedInvoices} isLoading={isLoading} />
      ),
    },
    {
      key: 'rejected',
      label: `Rejected (${rejectedInvoices.length})`,
      content: (
        <InvoicesList invoices={rejectedInvoices} isLoading={isLoading} />
      ),
    },
  ];

  return (
    <>
      <div className="mt-4">
        <Tab
          tabContent={tabContent}
          active={tabContent[0].content}
          activeKey={tabContent[0].key}
          setRefreshInvoices={setRefreshInvoices}
        />
      </div>
    </>
  );
}
