export function getIcon(name: string) {
  switch (name.toLowerCase()) {
    case 'logo':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="66"
          height="22"
          viewBox="0 0 66 22"
          fill="none"
        >
          <path
            d="M46.2801 4.96844C43.3304 4.96844 40.9414 7.64326 40.9414 10.9458C40.9414 14.2484 43.3304 16.9232 46.2801 16.9232C49.2297 16.9232 51.6187 14.2484 51.6187 10.9458C51.6187 7.64326 49.2175 4.96844 46.2801 4.96844ZM46.2801 14.535C44.5127 14.535 43.0744 12.9247 43.0744 10.9458C43.0744 8.96702 44.5127 7.35667 46.2801 7.35667C48.0474 7.35667 49.4857 8.96702 49.4857 10.9458C49.4857 12.9247 48.0474 14.535 46.2801 14.535Z"
            fill="#103129"
          />
          <path
            d="M31.3733 4.96844C30.1667 4.96844 29.0575 5.4188 28.1677 6.18303V5.26868H26.0469V21.9999H28.1677V15.7086C29.0575 16.4592 30.1667 16.9232 31.3733 16.9232C34.323 16.9232 36.712 14.2484 36.712 10.9458C36.712 7.64326 34.323 4.96844 31.3733 4.96844ZM31.3733 14.535C29.606 14.535 28.1677 12.9247 28.1677 10.9458C28.1677 8.96702 29.606 7.35667 31.3733 7.35667C33.1407 7.35667 34.579 8.96702 34.579 10.9458C34.579 12.9247 33.1407 14.535 31.3733 14.535Z"
            fill="#103129"
          />
          <path
            d="M5.22895 0H0V16.7312H5.22895C7.30103 16.7312 9.09277 15.9124 10.5798 14.2611C12.0668 12.6098 12.8103 10.6447 12.8103 8.36562C12.8103 6.08657 12.0668 4.1214 10.5798 2.47011C9.09277 0.818822 7.30103 0 5.22895 0ZM8.93432 12.6098C7.92265 13.7698 6.69159 14.343 5.22895 14.343H2.34023V2.38823H5.22895C6.67941 2.38823 7.91047 2.9614 8.93432 4.1214C9.94598 5.2814 10.4579 6.68704 10.4579 8.36562C10.4457 10.0442 9.94598 11.4498 8.93432 12.6098Z"
            fill="#103129"
          />
          <path d="M39.8806 0H37.7598V16.7312H39.8806V0Z" fill="#103129" />
          <path
            d="M22.658 10.9463C22.658 12.9251 21.2198 14.5355 19.4524 14.5355C17.685 14.5355 16.2468 12.9251 16.2468 10.9463V5.26917H14.1016V10.9463C14.1016 14.2489 16.4905 16.9237 19.4402 16.9237C20.6469 16.9237 21.7561 16.4734 22.6458 15.7091V16.7327H24.7667V5.26917H22.6458V10.9463H22.658Z"
            fill="#103129"
          />
          <path
            d="M57.3209 16.6942C55.9314 16.6942 54.7979 15.425 54.7979 13.8693V7.82365C54.7979 6.26789 55.9314 4.99872 57.3209 4.99872C60.1974 4.99872 62.5499 7.61894 62.5499 10.8533C62.5499 14.0603 60.1974 16.6942 57.3209 16.6942ZM57.3209 5.95401C56.4068 5.95401 55.6511 6.78648 55.6511 7.82365V13.8693C55.6511 14.8928 56.3946 15.7389 57.3209 15.7389C59.7343 15.7389 61.6845 13.5417 61.6845 10.8533C61.6845 8.13753 59.7343 5.95401 57.3209 5.95401Z"
            fill="#2DB49E"
          />
          <path
            d="M60.7701 13.6938C59.3806 13.6938 58.2471 12.4247 58.2471 10.8689V4.82328C58.2471 3.26752 59.3806 1.99835 60.7701 1.99835C63.6467 1.99835 65.9991 4.61858 65.9991 7.85292C65.9869 11.06 63.6467 13.6938 60.7701 13.6938ZM60.7701 2.95364C59.856 2.95364 59.1003 3.78611 59.1003 4.82328V10.8689C59.1003 11.8924 59.8438 12.7386 60.7701 12.7386C63.1835 12.7386 65.1337 10.5414 65.1337 7.85292C65.1337 5.16446 63.1713 2.95364 60.7701 2.95364Z"
            fill="#162930"
          />
        </svg>
      );
    case 'logo_big':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94"
          height="30"
          viewBox="0 0 94 30"
          fill="none"
        >
          <path
            d="M65.9145 6.77563C61.7135 6.77563 58.311 10.4231 58.311 14.9265C58.311 19.4299 61.7135 23.0774 65.9145 23.0774C70.1155 23.0774 73.5179 19.4299 73.5179 14.9265C73.5179 10.4231 70.0981 6.77563 65.9145 6.77563ZM65.9145 19.8207C63.3973 19.8207 61.3489 17.6248 61.3489 14.9265C61.3489 12.2282 63.3973 10.0323 65.9145 10.0323C68.4316 10.0323 70.48 12.2282 70.48 14.9265C70.48 17.6248 68.4316 19.8207 65.9145 19.8207Z"
            fill="#103129"
          />
          <path
            d="M44.6842 6.77563C42.9656 6.77563 41.3859 7.38974 40.1187 8.43186V7.18504H37.0981V30H40.1187V21.4211C41.3859 22.4446 42.9656 23.0774 44.6842 23.0774C48.8852 23.0774 52.2877 19.4299 52.2877 14.9265C52.2877 10.4231 48.8852 6.77563 44.6842 6.77563ZM44.6842 19.8207C42.1671 19.8207 40.1187 17.6248 40.1187 14.9265C40.1187 12.2282 42.1671 10.0323 44.6842 10.0323C47.2013 10.0323 49.2498 12.2282 49.2498 14.9265C49.2498 17.6248 47.2013 19.8207 44.6842 19.8207Z"
            fill="#103129"
          />
          <path
            d="M7.4472 0H0V22.815H7.4472C10.3983 22.815 12.9501 21.6984 15.068 19.4467C17.1858 17.195 18.2448 14.5152 18.2448 11.4075C18.2448 8.29974 17.1858 5.62 15.068 3.36828C12.9501 1.11656 10.3983 0 7.4472 0ZM12.7245 17.195C11.2836 18.7768 9.53033 19.5584 7.4472 19.5584H3.33301V3.25662H7.4472C9.51297 3.25662 11.2663 4.03822 12.7245 5.62001C14.1653 7.20179 14.8944 9.11855 14.8944 11.4075C14.877 13.6964 14.1653 15.6132 12.7245 17.195Z"
            fill="#103129"
          />
          <path d="M56.7998 0H53.7793V22.815H56.7998V0Z" fill="#103129" />
          <path
            d="M32.2703 14.9264C32.2703 17.6247 30.2219 19.8206 27.7048 19.8206C25.1877 19.8206 23.1392 17.6247 23.1392 14.9264V7.18494H20.084V14.9264C20.084 19.4298 23.4864 23.0773 27.6874 23.0773C29.406 23.0773 30.9857 22.4632 32.253 21.421V22.8167H35.2735V7.18494H32.253V14.9264H32.2703Z"
            fill="#103129"
          />
          <path
            d="M81.6398 22.7644C79.6608 22.7644 78.0464 21.0338 78.0464 18.9123V10.6684C78.0464 8.54695 79.6608 6.81628 81.6398 6.81628C85.7366 6.81628 89.087 10.3893 89.087 14.7997C89.087 19.1728 85.7366 22.7644 81.6398 22.7644ZM81.6398 8.11893C80.3378 8.11893 79.2615 9.2541 79.2615 10.6684V18.9123C79.2615 20.308 80.3205 21.4618 81.6398 21.4618C85.077 21.4618 87.8545 18.4657 87.8545 14.7997C87.8545 11.0964 85.077 8.11893 81.6398 8.11893Z"
            fill="#2DB49E"
          />
          <path
            d="M86.5531 18.6728C84.5741 18.6728 82.9597 16.9421 82.9597 14.8206V6.57673C82.9597 4.45527 84.5741 2.72461 86.5531 2.72461C90.6499 2.72461 94.0003 6.29759 94.0003 10.708C93.983 15.0812 90.6499 18.6728 86.5531 18.6728ZM86.5531 4.02726C85.2512 4.02726 84.1749 5.16242 84.1749 6.57673V14.8206C84.1749 16.2163 85.2338 17.3701 86.5531 17.3701C89.9903 17.3701 92.7678 14.374 92.7678 10.708C92.7678 7.04196 89.9729 4.02726 86.5531 4.02726Z"
            fill="#162930"
          />
        </svg>
      );
    case 'reveal':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.9922 3.23492H10.0003C11.1629 3.23492 12.293 3.51387 13.3337 4.03812L10.6182 6.77916C10.4231 6.74634 10.2117 6.72173 10.0003 6.72173C8.20358 6.72173 6.74829 8.19028 6.74829 10.0034C6.74829 10.2167 6.77268 10.43 6.8052 10.6269L3.79707 13.6625C2.98407 12.7108 2.27675 11.5614 1.71577 10.2413C1.65073 10.0937 1.65073 9.91317 1.71577 9.75729C3.45561 5.67158 6.55317 3.23492 9.9922 3.23492ZM15.358 5.45827C16.5369 6.54123 17.5369 8.00159 18.2849 9.75729C18.3499 9.91317 18.3499 10.0937 18.2849 10.2413C16.545 14.3271 13.4475 16.7719 10.0003 16.7719H9.9922C8.42309 16.7719 6.91902 16.255 5.59382 15.3116L8.17106 12.7108C8.69138 13.0628 9.32553 13.2769 10.0003 13.2769C11.7889 13.2769 13.2442 11.8084 13.2442 10.0034C13.2442 9.32247 13.0328 8.68254 12.6833 8.15747L15.358 5.45827Z"
            fill="#DCDDE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3584 5.45795L16.8381 3.96478C17.082 3.72686 17.082 3.33305 16.8381 3.09513C16.6023 2.85721 16.2202 2.85721 15.9763 3.09513L15.2121 3.86633C15.2039 3.88274 15.1877 3.89914 15.1714 3.91555C15.1633 3.93196 15.147 3.94837 15.1308 3.96478L14.4072 4.69578L11.8299 7.29652L3.10637 16.0988L3.08198 16.1317C2.91938 16.3696 2.9519 16.6986 3.15515 16.9037C3.26897 17.0259 3.43157 17.0834 3.58604 17.0834C3.74052 17.0834 3.89499 17.0259 4.01694 16.9037L12.6836 8.15714L15.3584 5.45795ZM10.0008 12.0463C11.1146 12.0463 12.0251 11.1274 12.0251 10.0034C12.0251 9.65885 11.9438 9.33068 11.7975 9.05173L9.05767 11.8166C9.33409 11.9642 9.6593 12.0463 10.0008 12.0463Z"
            fill="#DCDDE5"
          />
        </svg>
      );
    case 'hamburger':
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1_3640_3804" fill="white">
            <path d="M22.944 0H9.072C3.648 0 0 3.808 0 9.472V22.544C0 28.192 3.648 32 9.072 32H22.944C28.368 32 32 28.192 32 22.544V9.472C32 3.808 28.368 0 22.944 0Z" />
          </mask>
          <path
            d="M22.944 0H9.072C3.648 0 0 3.808 0 9.472V22.544C0 28.192 3.648 32 9.072 32H22.944C28.368 32 32 28.192 32 22.544V9.472C32 3.808 28.368 0 22.944 0Z"
            fill="white"
          />
          <path
            d="M22.944 -1H9.072V1H22.944V-1ZM9.072 -1C6.11722 -1 3.57819 0.0427564 1.77983 1.92028C-0.0149402 3.79407 -1 6.42323 -1 9.472H1C1 6.85677 1.83894 4.74993 3.22417 3.30372C4.60581 1.86124 6.60278 1 9.072 1V-1ZM-1 9.472V22.544H1V9.472H-1ZM-1 22.544C-1 25.5855 -0.0144903 28.2107 1.78025 30.0822C3.57848 31.9573 6.11726 33 9.072 33V31C6.60274 31 4.60552 30.1387 3.22375 28.6978C1.83849 27.2533 1 25.1505 1 22.544H-1ZM9.072 33H22.944V31H9.072V33ZM22.944 33C25.8989 33 28.4342 31.9571 30.2286 30.0812C32.0193 28.2093 33 25.5843 33 22.544H31C31 25.1517 30.1647 27.2547 28.7834 28.6988C27.4058 30.1389 25.4131 31 22.944 31V33ZM33 22.544V9.472H31V22.544H33ZM33 9.472C33 6.42445 32.0197 3.79541 30.229 1.92119C28.4345 0.0429239 25.899 -1 22.944 -1V1C25.413 1 27.4055 1.86108 28.783 3.30281C30.1643 4.74859 31 6.85555 31 9.472H33Z"
            fill="#DADAE7"
            mask="url(#path-1-inside-1_3640_3804)"
          />
          <rect x="10" y="10" width="12" height="1" rx="0.5" fill="#4A5361" />
          <rect x="10" y="15" width="12" height="1" rx="0.5" fill="#4A5361" />
          <rect x="10" y="20" width="12" height="1" rx="0.5" fill="#4A5361" />
        </svg>
      );
    case 'pencil':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.83958 2.40006L3.36624 8.1934C3.15958 8.4134 2.95958 8.84673 2.91958 9.14673L2.67291 11.3067C2.58624 12.0867 3.14624 12.6201 3.91958 12.4867L6.06624 12.1201C6.36624 12.0667 6.78624 11.8467 6.99291 11.6201L12.4662 5.82673C13.4129 4.82673 13.8396 3.68673 12.3662 2.2934C10.8996 0.913396 9.78624 1.40006 8.83958 2.40006Z"
            fill="white"
            stroke="#006355"
            strokeWidth="1.1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.92676 3.3667C8.21342 5.2067 9.70676 6.61337 11.5601 6.80003L7.92676 3.3667Z"
            fill="white"
          />
          <path
            d="M7.92676 3.3667C8.21342 5.2067 9.70676 6.61337 11.5601 6.80003"
            stroke="#006355"
            strokeWidth="1.1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 14.6667H14"
            stroke="#006355"
            strokeWidth="1.1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'home':
      return (
        <svg width="16" height="18">
          <path d="M5.6197 16.3184V13.7627C5.61968 13.115 6.14631 12.5889 6.79861 12.5848H9.19376C9.84898 12.5848 10.3801 13.1122 10.3801 13.7627V16.311C10.3801 16.8727 10.8366 17.3292 11.4024 17.3333H13.0364C13.7996 17.3352 14.5322 17.0356 15.0726 16.5005C15.6129 15.9655 15.9166 15.2389 15.9166 14.4812V7.2215C15.9166 6.60945 15.6433 6.02889 15.1704 5.63621L9.61907 1.22852C8.64868 0.457561 7.26273 0.482466 6.32108 1.28778L0.889095 5.63621C0.393869 6.01731 0.0978789 6.5996 0.083252 7.2215V14.4738C0.083252 16.0531 1.37274 17.3333 2.9634 17.3333H4.56016C4.83256 17.3352 5.09449 17.2292 5.28781 17.0386C5.48113 16.8481 5.58986 16.5888 5.58985 16.3184H5.6197Z" />
        </svg>
      );
    case 'transactions':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M5.58804 10.7421C5.15795 10.7421 4.80977 11.0954 4.80977 11.5319L4.59521 15.3475C4.59521 15.9038 5.03994 16.3541 5.58804 16.3541C6.13614 16.3541 6.57989 15.9038 6.57989 15.3475L6.36631 11.5319C6.36631 11.0954 6.01814 10.7421 5.58804 10.7421Z"
            fill="#5C696E"
          />
          <path
            d="M6.65039 3.06121C6.65039 3.06121 6.42705 2.83158 6.28856 2.73161C6.08766 2.5772 5.83994 2.5 5.59319 2.5C5.31622 2.5 5.05874 2.5871 4.84906 2.7514C4.81102 2.79001 4.64913 2.9355 4.51552 3.0711C3.67678 3.86392 2.30458 5.93355 1.88521 7.01736C1.81889 7.18167 1.67553 7.59737 1.66675 7.82007C1.66675 8.03189 1.71356 8.23578 1.80914 8.42879C1.94275 8.6703 2.15243 8.86429 2.40015 8.9702C2.5718 9.0385 3.08577 9.1444 3.09552 9.1444C3.65825 9.2513 4.57306 9.30871 5.58344 9.30871C6.54603 9.30871 7.4228 9.2513 7.99431 9.1642C8.00407 9.1543 8.64189 9.04839 8.86133 8.9316C9.26119 8.7188 9.50989 8.30309 9.50989 7.85868V7.82007C9.50013 7.53007 9.25144 6.92036 9.24266 6.92036C8.82329 5.89495 7.5174 3.87382 6.65039 3.06121Z"
            fill="#5C696E"
          />
          <path
            opacity="0.4"
            d="M14.4123 9.25805C14.8424 9.25805 15.1905 8.9047 15.1905 8.46819L15.4041 4.6525C15.4041 4.09623 14.9604 3.64587 14.4123 3.64587C13.8642 3.64587 13.4194 4.09623 13.4194 4.6525L13.634 8.46819C13.634 8.9047 13.9822 9.25805 14.4123 9.25805Z"
            fill="#5C696E"
          />
          <path
            d="M18.191 11.5711C18.0574 11.3296 17.8477 11.1366 17.6 11.0297C17.4283 10.9614 16.9134 10.8555 16.9046 10.8555C16.3419 10.7486 15.4271 10.6912 14.4167 10.6912C13.4541 10.6912 12.5773 10.7486 12.0058 10.8357C11.9961 10.8456 11.3582 10.9525 11.1388 11.0683C10.738 11.2811 10.4902 11.6968 10.4902 12.1422V12.1808C10.5 12.4708 10.7477 13.0796 10.7575 13.0796C11.1768 14.105 12.4817 16.1272 13.3497 16.9388C13.3497 16.9388 13.5731 17.1684 13.7116 17.2674C13.9115 17.4228 14.1592 17.5 14.4079 17.5C14.6839 17.5 14.9404 17.4129 15.1511 17.2486C15.1891 17.21 15.351 17.0645 15.4846 16.9299C16.3224 16.1361 17.6955 14.0664 18.1139 12.9835C18.1812 12.8192 18.3246 12.4025 18.3334 12.1808C18.3334 11.968 18.2866 11.7641 18.191 11.5711Z"
            fill="#5C696E"
          />
        </svg>
      );
    case 'incomplete':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM19.2802 9.53016L10.6552 18.1552C10.5145 18.2957 10.3238 18.3747 10.125 18.3747C9.92617 18.3747 9.73548 18.2957 9.59484 18.1552L4.71984 13.2802C4.5793 13.1395 4.50035 12.9488 4.50035 12.75C4.50035 12.5512 4.5793 12.3605 4.71984 12.2198L5.78016 11.1591C5.8498 11.0894 5.93249 11.0341 6.02349 10.9964C6.1145 10.9587 6.21204 10.9393 6.31055 10.9393C6.40905 10.9393 6.50659 10.9587 6.5976 10.9964C6.6886 11.0341 6.77129 11.0894 6.84094 11.1591L10.125 14.4431L17.1591 7.40906C17.2287 7.3394 17.3114 7.28414 17.4024 7.24644C17.4934 7.20874 17.5909 7.18933 17.6895 7.18933C17.788 7.18933 17.8855 7.20874 17.9765 7.24644C18.0675 7.28414 18.1502 7.3394 18.2198 7.40906L19.2802 8.46984C19.4207 8.61048 19.4997 8.80117 19.4997 9C19.4997 9.19883 19.4207 9.38952 19.2802 9.53016Z"
            fill="#EBEFF5"
          />
          <path
            d="M10.6553 18.1548C10.5147 18.2953 10.324 18.3743 10.1251 18.3743C9.92631 18.3743 9.73562 18.2953 9.59499 18.1548L4.71998 13.2798C4.57944 13.1391 4.50049 12.9485 4.50049 12.7496C4.50049 12.5508 4.57944 12.3601 4.71998 12.2195L5.7803 11.1587C5.84994 11.089 5.93263 11.0338 6.02364 10.9961C6.11464 10.9584 6.21218 10.939 6.31069 10.939C6.40919 10.939 6.50673 10.9584 6.59774 10.9961C6.68875 11.0338 6.77143 11.089 6.84108 11.1587L10.1251 14.4427L17.1592 7.40869C17.2289 7.33903 17.3115 7.28377 17.4025 7.24607C17.4935 7.20837 17.5911 7.18896 17.6896 7.18896C17.7881 7.18896 17.8856 7.20837 17.9766 7.24607C18.0677 7.28377 18.1503 7.33903 18.22 7.40869L19.2803 8.46947C19.4208 8.61011 19.4998 8.8008 19.4998 8.99963C19.4998 9.19846 19.4208 9.38915 19.2803 9.52979L10.6553 18.1548Z"
            fill="#FAFBFC"
          />
        </svg>
      );
    case 'complete':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM19.2802 9.53016L10.6552 18.1552C10.5145 18.2957 10.3238 18.3747 10.125 18.3747C9.92617 18.3747 9.73548 18.2957 9.59484 18.1552L4.71984 13.2802C4.5793 13.1395 4.50035 12.9488 4.50035 12.75C4.50035 12.5512 4.5793 12.3605 4.71984 12.2198L5.78016 11.1591C5.8498 11.0894 5.93249 11.0341 6.02349 10.9964C6.1145 10.9587 6.21204 10.9393 6.31055 10.9393C6.40905 10.9393 6.50659 10.9587 6.5976 10.9964C6.6886 11.0341 6.77129 11.0894 6.84094 11.1591L10.125 14.4431L17.1591 7.40906C17.2287 7.3394 17.3114 7.28414 17.4024 7.24644C17.4934 7.20874 17.5909 7.18933 17.6895 7.18933C17.788 7.18933 17.8855 7.20874 17.9765 7.24644C18.0675 7.28414 18.1502 7.3394 18.2198 7.40906L19.2802 8.46984C19.4207 8.61048 19.4997 8.80117 19.4997 9C19.4997 9.19883 19.4207 9.38952 19.2802 9.53016Z"
            fill="#25BB87"
          />
          <path
            d="M10.6553 18.1548C10.5147 18.2953 10.324 18.3743 10.1251 18.3743C9.92631 18.3743 9.73562 18.2953 9.59499 18.1548L4.71998 13.2798C4.57944 13.1391 4.50049 12.9485 4.50049 12.7496C4.50049 12.5508 4.57944 12.3601 4.71998 12.2195L5.7803 11.1587C5.84994 11.089 5.93263 11.0338 6.02364 10.9961C6.11464 10.9584 6.21218 10.939 6.31069 10.939C6.40919 10.939 6.50673 10.9584 6.59774 10.9961C6.68875 11.0338 6.77143 11.089 6.84108 11.1587L10.1251 14.4427L17.1592 7.40869C17.2289 7.33903 17.3115 7.28377 17.4025 7.24607C17.4935 7.20837 17.5911 7.18896 17.6896 7.18896C17.7881 7.18896 17.8856 7.20837 17.9766 7.24607C18.0677 7.28377 18.1503 7.33903 18.22 7.40869L19.2803 8.46947C19.4208 8.61011 19.4998 8.8008 19.4998 8.99963C19.4998 9.19846 19.4208 9.38915 19.2803 9.52979L10.6553 18.1548Z"
            fill="#FAFBFC"
          />
        </svg>
      );
    default:
      return null;
  }
}
