import React, { useState } from 'react';
import { ITab } from 'utils';
import UploadDocumentModal from '../modals/UploadDocumentModal';
import classNames from 'classnames';
import { useAuth } from 'contexts/AuthContext';

interface Props {
  tabContent: ITab[];
  active: any;
  activeKey: string;
  setRefreshInvoices?: any;
}
const Tab: React.FC<Props> = ({
  tabContent,
  active,
  activeKey,
  setRefreshInvoices,
}) => {
  const [activeTab, setActiveTab] = useState(active);
  const [activeTabKey, setActiveTabKey] = useState(activeKey);

  function changeTab(tab: any) {
    setActiveTab(tab.content);
    setActiveTabKey(tab.key);
  }
  const { isModalOpen, closeModal, openModal } = useAuth();
  return (
    <>
      <div className="">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between border rounded-lg md:w-[50%] lg:w-[35%] w-[90%]">
            {tabContent.map((tab) => (
              <span
                key={tab.key}
                className={classNames(
                  'py-2.5 hover:bg-[#CDE2EA] capitalize flex-1 cursor-pointer text-center',
                  {
                    'bg-[#CDE2EA]': activeTabKey === tab.key,
                  }
                )}
                onClick={() => changeTab(tab)}
              >
                {tab.label}
              </span>
            ))}
          </div>
          <button
            className="bg-[#006355] text-white font-medium text-sm py-2 px-6 rounded-md hidden md:inline-block"
            onClick={openModal}
          >
            Upload document
          </button>
        </div>
        <div className="py-6">{activeTab}</div>
      </div>
      <UploadDocumentModal
        isModalOpen={isModalOpen}
        handleCancel={closeModal}
        setRefreshInvoices={setRefreshInvoices}
      />
    </>
  );
};

export default Tab;
