import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import UploadedDocuments from 'components/invoice-management/UploadedDocuments';
import Invoices from 'components/invoice-management/Invoices';
import { useVendor } from 'contexts/VendorContext';
import ApprovedIcon from 'components/icons/ApprovedIcon';
import AddIcon from 'assets/png/add.png';
import { useAuth } from 'contexts/AuthContext';

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Uploaded Documents',
    children: <UploadedDocuments />,
  },
  {
    key: '2',
    label: 'Invoices',
    children: <Invoices />,
  },
];

export default function InvoiceManagement() {
  const { vendor } = useVendor();
  const { openModal } = useAuth();
  return (
    <div className="">
      {!vendor?.hasMerchantInvite ? (
        <div className="flex flex-col space-y-4 md:w-[500px] text-wrap">
          <ApprovedIcon />
          <span className="font-semibold text-lg text-[#07000F]">
            You don’t have any active buyer
          </span>
          <div className="font-normal text-sm text-[#484847] text-left">
            You need to have an active buyer to start uploading invoices. Please
            contact our team via&nbsp;
            <a
              className="text-[#006355] underline"
              href="mailto:support@tryduplo.com"
            >
              support@tryduplo.com{' '}
            </a>
            to get more information on how to invite a buyer to your portal.
          </div>
        </div>
      ) : (
        <>
          <Tabs
            defaultActiveKey="1"
            items={items}
            tabBarStyle={{ color: 'red' }}
          />
          <div
            className="absolute z-50 cursor-pointer right-5 md:hidden bottom-8"
            onClick={openModal}
          >
            <img src={AddIcon} alt="Add Icon" />
          </div>
        </>
      )}
    </div>
  );
}
