import config from 'config';
import { RudderAnalytics } from '@rudderstack/analytics-js';

const rudderAnalytics = new RudderAnalytics();

if (config.PROD) {
  rudderAnalytics.load(
    `${config.RUDDERSTACK_WRITE_KEY}`,
    `${config.RUDDERSTACK_DATA_PLAIN_URL}`,
    {
      logLevel: config.PROD ? 'INFO' : 'DEBUG',
      secureCookie: true,
      integrations: {
        All: config.API_ENDPOINT?.search(/(localhost|staging)\./) === -1,
        Hotjar: false,
      },
    }
  );
}
export { rudderAnalytics };
