import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please enter a valid email')
    .min(5, 'Must be longer than 5 characters')
    .max(255, 'Must be shorter than 255 characters')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const signupSchema = Yup.object().shape({
  business_name: Yup.string()
    .trim()
    .required('Business name is required')
    .min(3, 'Must be longer than 3 characters'),
  phone_number: Yup.string().required('Phone number is required'),
  email: Yup.string()
    .trim()
    .email('Please enter a valid email')
    .min(5, 'Must be longer than 5 characters')
    .max(255, 'Must be shorter than 255 characters')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password too short')
    .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number'),
  confirm_password: Yup.string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
});

export const resetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required('Password is required')
    .min(8, 'Password too short')
    .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number'),
  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('new_password')], 'Passwords must match'),
});

export const resetPasswordEmail = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please enter a valid email')
    .min(5, 'Must be longer than 5 characters')
    .max(255, 'Must be shorter than 255 characters')
    .required('Email is required'),
});

export const businessSchema = Yup.object().shape({
  vendorName: Yup.string()
    .trim()
    .required('Business name is required')
    .min(3, 'Must be longer than 3 characters')
    .max(50, 'Must not be longer than 50 characters'),
  phoneNumber: Yup.string().required('Phone number is required'),
  email: Yup.string()
    .trim()
    .email('Please enter a valid email')
    .min(5, 'Must be longer than 5 characters')
    .max(255, 'Must be shorter than 255 characters')
    .required('Email is required'),
  taxVerificationNumber: Yup.string()
    .trim()
    .min(3, 'Must be longer than 3 characters')
    .optional(),
  rcNumber: Yup.string()
    .trim()
    .min(3, 'Must be longer than 3 characters')
    .optional(),
});
export const shippingSchema = Yup.object().shape({
  city: Yup.string()
    .min(3, 'Must be longer than 3 characters')
    .max(50, 'Must not be longer than 50 characters')
    .required('City is required'),
  address: Yup.string()
    .trim()
    .min(3, 'Must be longer than 3 characters')
    .max(100, 'Must not be longer than 100 characters')
    .required('Address is required'),
});

export const resetPasswordSecuritySchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password too short')
    .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number'),
  new_password: Yup.string()
    .required('New Password is required')
    .min(8, 'New Password too short')
    .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number'),
  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('new_password')], 'Passwords must match'),
});

export const uploadDocumentsSchema = Yup.object().shape({
  invoice_number: Yup.string()
    .trim()
    .required('Invoice number is required')
    .min(3, 'Must be longer than 3 digits'),
  purchase_order_number: Yup.string()
    .trim()
    .optional()
    .min(3, 'Must be longer than 3 digits'),
});

export const kybUploadDocumentsSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please enter a valid email')
    .min(5, 'Must be longer than 5 characters')
    .max(255, 'Must be shorter than 255 characters'),
  name: Yup.string()
    .trim()
    .min(3, 'Must be longer than 3 characters')
    .max(50, 'Must be shorter than 50 characters')
    .matches(
      /^(?:([-A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s0-9]*)) (?:([-A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s0-9]*))$/g,
      'Enter a valid full name'
    ),

  address: Yup.string()
    .trim()
    .min(3, 'Must be longer than 3 characters')
    .max(50, 'Must be shorter than 50 characters'),
  city: Yup.string()
    .trim()
    .min(3, 'Must be longer than 3 characters')
    .max(50, 'Must be shorter than 50 characters'),
});
