import { useState } from 'react';
import { Modal } from 'antd';
import { updateLoanStatus } from 'functions/vendor-functions';
import toast from 'react-hot-toast';
import { useAuth } from 'contexts/AuthContext';
import { ReactComponent as RejectIcon } from 'assets/svg/confirm-reject.svg';
import InvoiceSuccessModal from './InvoiceSuccessModal';

export interface InvoiceFinancingModalProps {
  isOpen: boolean;
  handleSuccessCancel: () => void;
  billNumber?: string;
}

export const RejectLoansModal = ({
  handleSuccessCancel,
  isOpen,
  billNumber,
}: InvoiceFinancingModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { logout } = useAuth();
  const [rejectionNote, setRejectionNote] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const openModal = () => {
    handleSuccessCancel();
    setIsSuccessModalOpen((prev) => !prev);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen((prev) => !prev);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const handleApproveLoan = async () => {
    setIsLoading(true);
    const { data, responseError, refreshError } = await updateLoanStatus({
      billNumber: billNumber,
      status: 'declined',
      loanTermsRejectionReason: rejectionNote,
    });
    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }
    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }
    if (data !== undefined) {
      setIsLoading(false);
      openModal();
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={handleSuccessCancel}
        cancelButtonProps={{
          className: 'h-[40px] font-semibold !text-[#004b40]',
        }}
        okText={`${isLoading ? 'Loading...' : 'Yes, I reject loan terms'}`}
        okButtonProps={{
          className:
            'bg-[#F84253]  mt-12 h-[40px] font-semibold disabled:bg-[#F84253] disabled:opacity-50 disabled:text-white',
          disabled: isLoading || rejectionNote.length === 0,
        }}
        onOk={handleApproveLoan}
      >
        <div className="mt-10 space-y-3 text-[#727E8F] flex justify-start flex-col">
          <RejectIcon />
          <h1 className="text-[#07000F] font-semibold text-lg">
            Loan terms confirmation
          </h1>
          <p>
            Please confirm that you are not comfortable with this loan terms.
          </p>
          <div>
            <label>
              Add reason<span className="text-red-500">*</span>
            </label>
            <textarea
              rows={4}
              name="approve_note"
              onChange={(e) => setRejectionNote(e.target.value)}
              className="w-full font-semibold text-black border-2 border-solid rounded-md outline-none"
              value={rejectionNote}
            />
          </div>
        </div>
      </Modal>

      <InvoiceSuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        handleSuccessCancel={closeSuccessModal}
        invoiceNumber={billNumber}
        headerText="Loan term rejected successfully!"
        subText="Your request has been sent. You will here from our shortly."
        isInvoice
      />
    </>
  );
};
