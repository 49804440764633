import React, { useState } from 'react';
import { FormInputProps, PHONE_CODES } from '../../utils';
import { useField } from 'formik';
import { PatternFormat } from 'react-number-format';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import CustomSelectComponent from './CustomSelect';

export interface IProps
  extends Omit<
    FormInputProps,
    'title' | 'type' | 'ref' | 'value' | 'defaultValue'
  > {
  label: string;
  disabled?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setValue?: (val: string) => void;
  valueSelect?: string;
  setValueSelect?: React.Dispatch<React.SetStateAction<string>>;
  type?: 'text' | 'tel';
  placeholder?: string;
  className?: string;
  labelStyles?: string;
  isSearchable?: boolean;
}

const PhoneField = ({
  label,
  type,
  placeholder,
  disabled,
  className,
  labelStyles,
  valueSelect,
  setValueSelect,
  isSearchable,
  ...props
}: IProps) => {
  const [isActive, setIsActive] = useState(false);
  const [field, meta] = useField(props);

  const data = useLocation();
  const isSignupRoute = data.pathname.includes('signup');

  return (
    <>
      <div className="flex flex-col">
        <span
          className={classNames(
            'text-sm font-light text-[#192850]',
            labelStyles
          )}
        >
          {label}
        </span>
        <div
          className={classNames(
            `border-2 ${
              isActive ? 'border-[#006355]' : 'border-gray-200'
            } rounded-md h-12 p-2 flex justify-between items-center`,
            {
              'border-red-700': meta.touched && meta.error,
            },
            className
          )}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
        >
          {isSearchable ? (
            <CustomSelectComponent
              className="!border-0 !bg-transparent !focus:outline-none !shadow-none -ml-2.5 mr-1.5 !justify-center !pr-0"
              value={valueSelect}
              setValue={setValueSelect}
              list={PHONE_CODES.map(({ dial_code }) => ({
                option: dial_code,
                value: dial_code,
              }))}
              placeholder="_"
              name="dial_code"
            />
          ) : (
            <select className="outline-none text-sm w-[18%]">
              <input type="text" name="" id="" placeholder="search" />
              {PHONE_CODES.map(({ dial_code, code }) => (
                <option
                  key={code}
                  value={dial_code}
                  selected={dial_code === '+234'}
                  defaultValue={'+234'}
                >
                  {dial_code}
                </option>
              ))}
            </select>
          )}
          <PatternFormat
            className="border-l outline-none w-[80%] px-2"
            type={type}
            format="###-###-####"
            disabled={disabled}
            placeholder={placeholder}
            {...field}
            {...props}
          />
        </div>
      </div>
      {isSignupRoute && (
        <span className="text-[#b3b3b3] text-sm">
          Please enter NGN format. E.g 8122934834
        </span>
      )}
      {meta.touched && meta.error ? (
        <span className="text-sm text-red-700">{meta.error}</span>
      ) : null}{' '}
    </>
  );
};

export default PhoneField;
