import React, { useEffect, useState } from 'react';
import TextField from '../common/TextField';
import InvoiceBreakdown from './InvoiceBreakdown';
import { getVendorInvoices } from 'functions/vendor-functions';
import { FilterInterface, PaginationOnChange, VendorType } from 'utils';
import { useAuth } from 'contexts/AuthContext';
import toast from 'react-hot-toast';
import moment from 'moment';
import classNames from 'classnames';
import EmptyInvoice from 'assets/png/empty-invoice.png';
import Pagination from 'components/common/Pagination/Pagination';

export default function Invoices() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [refreshInvoices, setRefreshInvoices] = useState(false);
  const [singleInvoice, setSingleInvoice] = useState<VendorType>();

  const [filters, setFilters] = useState<FilterInterface>({
    page: 1,
    perPage: 20,
    limit: 20,
    pageCount: 1,
  });

  const handlePaginationChange = (e: PaginationOnChange) => {
    setFilters({ ...filters, page: e.selected + 1 });
  };
  const handlePageDropdown = (e: string) => {
    setFilters({ ...filters, limit: Number(e), page: 1 });
  };

  const [showInvoiceBreakdown, setShowInvoiceBreakdown] = useState(false);
  const [invoices, setInvoices] = useState<VendorType[]>([]);
  const { logout } = useAuth();

  const getInvoice = async () => {
    setIsLoading(true);
    const { data, responseError, refreshError } = await getVendorInvoices({
      isBill: true,
      filters,
    });
    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    setInvoices(data?.vendorInvoices);
    setIsLoading(false);
    setRefreshInvoices(false);

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }
    return data as VendorType[];
  };

  React.useEffect(() => {
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  const displayInvoiceBreakdown = (invoice: VendorType) => {
    setShowInvoiceBreakdown(true);
    setSingleInvoice(invoice);
  };

  function hideInvoiceBreakdown() {
    setShowInvoiceBreakdown(false);
  }

  useEffect(() => {
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInvoices]);

  return (
    <>
      {invoices.map((dts) => (
        <div
          className="border  border-[#EDEDF2] py-3 px-4 rounded-lg md:hidden"
          key={dts._id}
          onClick={() => displayInvoiceBreakdown(dts)}
        >
          <div className="flex items-center justify-between py-2">
            <div className="text-[#07000F] font-semibold">
              Invoice#{dts.invoiceNumber}
            </div>
            <div className="text-right text-[#07000F] font-semibold">
              {Number(dts?.balance || '0').toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) || '0.00'}
            </div>
          </div>
          <div className="flex items-center justify-between py-2">
            <div className="text-[#5C696E]">
              {moment(dts.createdAt).fromNow()}
            </div>
            <div
              className={classNames(
                'font-medium text-[10px] inline-block px-2 rounded uppercase',
                {
                  'bg-[#FDEFCB] text-[#D97724]':
                    dts.paymentStatus.toLowerCase() === 'open',
                  'bg-[#BBE5B3] text-[#414F3E] ':
                    dts.paymentStatus.toLowerCase() === 'paid',
                  'bg-[#CFE0F5] text-[#087FCA] ':
                    dts.paymentStatus.toLowerCase() === 'partiallypaid',
                }
              )}
            >
              {dts.paymentStatus === 'Open' ? 'Unpaid' : dts.paymentStatus}
            </div>
          </div>
        </div>
      ))}

      {!isLoading && invoices?.length !== 0 ? (
        <>
          <div className="hidden space-y-6 md:block">
            {/* Search and filter */}
            <div className="flex items-center justify-between mt-4">
              <div className="w-[20%]">
                <TextField
                  value={searchValue}
                  setValue={setSearchValue}
                  placeholder="Search Invoices"
                />
              </div>
            </div>
            {/* Table */}
            <div>
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr className="bg-[#F8F9F9] text-xs font-semibold text-[#07000F]">
                    <th className="py-4">INVOICE NUMBER</th>
                    <th>DATE CREATED</th>
                    <th>BALANCE</th>
                    <th>DUE</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((dts) => (
                    <tr
                      key={dts._id}
                      className="border-b-2 text-sm font-normal text-[#5C696E] cursor-pointer"
                      onClick={() => displayInvoiceBreakdown(dts)}
                    >
                      <td className="py-4">Invoice#{dts.invoiceNumber}</td>
                      <td>{moment(dts.createdAt).format('DD/MM/yyyy')}</td>
                      <td>
                        ₦
                        {Number(dts?.balance || '0').toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        }) || '0.00'}
                      </td>
                      <td>{moment(dts.createdAt).fromNow()}</td>
                      <td>
                        <div
                          className={classNames(
                            'font-medium text-[10px] inline-block px-2 rounded uppercase',
                            {
                              'bg-[#FDEFCB] text-[#D97724]':
                                dts.paymentStatus.toLowerCase() === 'open',
                              'bg-[#BBE5B3] text-[#414F3E] ':
                                dts.paymentStatus.toLowerCase() === 'paid',
                              'bg-[#CFE0F5] text-[#087FCA] ':
                                dts.paymentStatus.toLowerCase() ===
                                'partiallypaid',
                            }
                          )}
                        >
                          {dts.paymentStatus === 'Open'
                            ? 'Unpaid'
                            : dts.paymentStatus}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              selectedOption={String(filters.page)}
              currentPage={filters.page}
              handlePaginationChange={handlePaginationChange}
              pageCount={filters.pageCount}
              setSelectedOption={handlePageDropdown}
              list={invoices.length}
            />
          </div>
          {/* Pagination */}
          <InvoiceBreakdown
            isVisible={showInvoiceBreakdown}
            handleCancel={hideInvoiceBreakdown}
            invoice={singleInvoice}
          />
        </>
      ) : (
        <div className="flex items-center justify-center h-[400px] mt-10">
          <div className="flex flex-col items-center justify-center space-y-4">
            <img src={EmptyInvoice} alt="Empty Invoice" width={60} />
            <h1 className="text-[#07000F] font-semibold text-[20px]">
              No invoices created yet
            </h1>
            <span className="font-normal text-sm text-[#484847] text-center md:w-[80%]">
              When your uploaded documents have been accepted the resulting
              invoice will be seen here
            </span>
          </div>
        </div>
      )}
    </>
  );
}
