import React from 'react';

export default function SuccessChecbox() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#006355" />
      <path
        d="M11.3337 5.5L6.75033 10.0833L4.66699 8"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#006355" />
    </svg>
  );
}
