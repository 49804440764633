import moment from 'moment';

export const base64StringToBlob = (
  base64Data: string,
  contentType?: string | null,
  sliceSize = 512
) => {
  contentType = contentType || '';

  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const { floor, random } = Math;

function randomCharacterFromArray(list: string[] | string) {
  return list[floor(random() * list.length)];
}

const generateUpperCaseLetter = () => {
  return randomCharacterFromArray('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
};

const identifiers: string[] = [];

export const generateIdentifier = (): string => {
  const identifier = [
    ...moment().utcOffset('+01:00').format('YYYYMMDDHHmmss'),
    ...Array.from({ length: 8 }, generateUpperCaseLetter),
  ].join('');

  return (
    identifiers.includes(identifier)
      ? generateIdentifier()
      : identifiers.push(identifier),
    identifier
  );
};
