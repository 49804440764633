/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, createContext, useContext, useState } from 'react';

import axios from 'axios';
import config from 'config';

import { jwtDecode } from 'jwt-decode';

export type UserType = { id: string; email: string } | null;
interface AuthContextType {
  accessToken: string | null;
  refreshToken: string | null;
  user: UserType;
  login: (access: string, refresh: string) => void;
  logout: () => void;
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

interface IProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType>({
  accessToken: null,
  refreshToken: null,
  user: null,
  login: () => {},
  logout: () => {},
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem('accessToken')
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem('refreshToken')
  );
  const [user, setUser] = useState<{ id: string; email: string } | null>(
    JSON.parse(localStorage.getItem('user') || 'null')
  );
  const login = (access: string, refresh: string) => {
    setAccessToken(access);
    setRefreshToken(refresh);
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
    const decodedToken = jwtDecode<any>(access);
    setUser({ id: decodedToken.id, email: decodedToken.email });
    localStorage.setItem(
      'user',
      JSON.stringify({ id: decodedToken.id, email: decodedToken.email })
    );
  };

  const logout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const user = JSON.parse(localStorage.getItem('user') || '');
    await axios.delete(`${config.API_ENDPOINT}/auth/logout`, {
      data: {
        refresh_token: refreshToken,
        _id: user?.id,
      },
    });
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    sessionStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        refreshToken,
        user,
        login,
        logout,
        isModalOpen: isModalOpen,
        openModal,
        closeModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
