import React, { ReactNode } from 'react';
import { getIcon } from '../../utils';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Logo from 'assets/png/white-logo.png';

type AuthContainerProps = {
  headerText?: string;
  showLogo?: boolean;
  children: ReactNode;
  headerPosition?: 'left' | 'center' | 'right';
  isLogo?: boolean;
};

const AuthContainer: React.FC<AuthContainerProps> = ({
  headerText,
  showLogo = true,
  children,
  headerPosition,
  isLogo,
}) => {
  const navigateToRelevantLink = (url: string) => {
    window.open(url, '_blank');
  };

  const data = useLocation();
  const isSignupRoute = data.pathname.includes('signup');

  return (
    <div
      className={classNames(
        'flex flex-col items-center w-full pt-32 overflow-y-auto h-screen bg-center bg-no-repeat bg-cover',
        {
          'h-full': isSignupRoute,
          'pt-20': isSignupRoute,
        }
      )}
      style={{
        backgroundImage: `url('/background.png')`,
      }}
    >
      {isLogo && (
        <div className="py-2">
          <img src={Logo} alt="White Logo" />
        </div>
      )}
      <div className="w-[90%] lg:w-[33%]">
        <div className="px-6 py-6 space-y-2 bg-white bg-red rounded-xl lg:px-10">
          {showLogo && headerPosition !== 'center' && (
            <h2>{getIcon('logo')}</h2>
          )}
          {headerText && (
            <h3
              className={classNames(
                'text-[17px] font-bold text-[#192850] py-3',
                {
                  'text-center': headerPosition === 'center',
                }
              )}
            >
              {headerText}
            </h3>
          )}
          {children}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            color: '#DAECFA',
            margin: isSignupRoute
              ? '10px 12px 20px 12px'
              : '10px 30px 20px 30px',
            cursor: 'pointer',
          }}
          className="text-xs md:text-base"
        >
          <div
            onClick={() =>
              navigateToRelevantLink('https://duplo-product-demo.webflow.io/')
            }
          >
            Need Help?
          </div>
          <div
            style={{
              float: 'left',
              height: '20px',
              width: '1.5px',
              backgroundColor: '#DAECFA33',
            }}
          ></div>
          <div
            onClick={() =>
              navigateToRelevantLink(
                'https://www.tryduplo.com/terms-and-conditions'
              )
            }
          >
            Terms & Conditions
          </div>
          <div
            style={{
              float: 'left',
              height: '20px',
              width: '1.5px',
              backgroundColor: '#DAECFA33',
            }}
          ></div>
          <div
            onClick={() =>
              navigateToRelevantLink(
                'https://www.tryduplo.com/terms-and-conditions#Privacy-Policy'
              )
            }
          >
            Privacy Policy
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
