import React, { useState, useEffect } from 'react';
import { ReactComponent as DocumentIcon } from 'assets/svg/approval-document.svg';
import Dropzone from 'react-dropzone';
import CropImageComponent from 'components/cropper/Cropper';
import { FileUploadInterface, fileUploadErrorType } from 'utils';
import toast from 'react-hot-toast';
import { base64StringToBlob, generateIdentifier } from 'utils/imageConverter';
import { uploadVendorLogoInformation } from 'functions/vendor-functions';
import { useAuth } from 'contexts/AuthContext';
import classNames from 'classnames';
import Loader from 'components/common/Loader';

export interface IProps {
  closeDropdown: () => void;
}

function ImageUpload({ closeDropdown }: IProps) {
  const [croppedPreviewImg, setCroppedPreviewImg] = useState<string>('');
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const { logout } = useAuth();

  const [imgSrc, setImgSrc] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<string>('');

  const [cropper, setCropper] = useState<{
    getCroppedCanvas: () => HTMLCanvasElement;
  }>();

  const [uploadFile, setUploadFile] = useState<FileUploadInterface>({
    name: '',
  } as FileUploadInterface);

  const onUploadCancel = () => {
    setSelectedFile('');
    setImgSrc('');
  };

  function handleUpload<T extends FileUploadInterface>(
    e: T[],
    fileRejections: fileUploadErrorType[]
  ) {
    if (fileRejections.length > 0) {
      fileRejections[0].errors.forEach((err) => {
        toast.error(err.message);
      });

      return;
    }
    const file = e[0] || null;

    if (file !== null) {
      setSelectedFile(file.path || '');
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }

  const triggerUploadLogo = async () => {
    const formData = new FormData();
    formData.append('business_image', uploadFile);
    setIsUploadLoading(true);

    const { data, responseError, refreshError } =
      await uploadVendorLogoInformation(formData);

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsUploadLoading(false);
      return;
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
    }

    if (data) {
      closeDropdown();
      toast.success('Image uploaded successfully');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  useEffect(() => {
    if (croppedPreviewImg) {
      const ImageURL = croppedPreviewImg;
      const block = ImageURL.split(';');
      const contentType = block[0].split(':')[1];
      const realData = block[1].split(',')[1];
      const blob = base64StringToBlob(realData, contentType);
      const file = new File([blob], `${generateIdentifier()}.png`, {
        type: 'image/png',
      });
      setUploadFile(file);
    }
  }, [croppedPreviewImg]);

  useEffect(() => {
    if (typeof cropper !== 'undefined') {
      setCroppedPreviewImg(cropper?.getCroppedCanvas()?.toDataURL());
    }
  }, [cropper]);
  return (
    <div className="flex flex-col items-center px-4 py-8 min-w-[120px]">
      {!imgSrc && (
        <div className="flex flex-col items-center justify-center py-3">
          <DocumentIcon />
          <small className="py-4">
            Recommended size is 176 x 176 pixels with <br /> maximum size of
            5MB, and either JPEG or PNG.
          </small>
        </div>
      )}
      <div className="drop">
        {!selectedFile && (
          <Dropzone
            accept={['.png', '.jpeg', '.jpg']}
            maxSize={500000}
            onDrop={(acceptedFiles, fileRejections) =>
              handleUpload<FileUploadInterface>(acceptedFiles, fileRejections)
            }
          >
            {({ getRootProps, getInputProps }) => (
              <div className="drop" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dotted">
                  <button className="bg-[#EEEEEE] h-10 w-32 rounded-md">
                    Upload Image
                  </button>
                </div>
              </div>
            )}
          </Dropzone>
        )}

        {Boolean(imgSrc) && (
          <>
            <CropImageComponent imgSrc={imgSrc} setCropper={setCropper} />
            <div className="flex items-center justify-between mt-5">
              <button
                className="w-20 h-10 rounded-md border border-solid border-[#c9c9c9] px-5"
                onClick={() => {
                  onUploadCancel();
                }}
              >
                Cancel
              </button>

              <button
                className={classNames(
                  'w-32 h-10 bg-[#2DB49E] px-5 text-white rounded-md cursor-pointer'
                )}
                onClick={() => triggerUploadLogo()}
                disabled={isUploadLoading || !selectedFile}
              >
                {isUploadLoading ? <Loader /> : 'Save'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ImageUpload;
