import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import SelectField from '../common/SelectField';
import { useAuth } from '../../contexts/AuthContext';
import { updateVendorDetails } from '../../functions/vendor-functions';
import { useVendor } from '../../contexts/VendorContext';
import { getListOfBanks, verifyAccountName } from '../../functions/requests';
import { NumberField } from '../common/TextFieldNumber';

interface Props {
  isModalOpen: boolean;
  handleCancel: () => void;
}

const BankAccountModal: React.FC<Props> = ({
  isModalOpen = false,
  handleCancel,
}) => {
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [bank, setBank] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fetchBanksError, setFetchBanksError] = useState<string | null>(null);
  const [verifyError, setVerifyError] = useState<string | null>(null);
  const { vendor, initVendor } = useVendor();
  const [banksList, setBanksList] = useState<any[]>([]);
  const { logout } = useAuth();

  async function handleSubmit() {
    setError('');
    setIsLoading(true);

    if (!accountNumber || !bank) {
      setError('All fields are required');
      setIsLoading(false);
      return;
    }

    const reqBody = {
      bankAccountDetails: {
        accountName,
        accountNumber,
        bankName: JSON.parse(bank).name,
      },
    };

    const { data, responseError, refreshError } =
      await updateVendorDetails(reqBody);

    if (responseError) {
      setError(responseError.message);
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
    }

    initVendor(data);

    setIsLoading(false);
    handleCancel();
  }

  async function fetchBanks() {
    const { data, error } = await getListOfBanks();

    if (error) {
      setFetchBanksError(error.message);
    }

    const banksList = data?.map((bank: any) => {
      return {
        option: bank.name,
        value: JSON.stringify({ name: bank.name, code: bank.code }),
      };
    });

    setBanksList(banksList);
  }

  async function verifyBankAccount() {
    setIsVerifying(true);
    setVerifyError('');
    setAccountName('');
    setError('');
    const bankCode = JSON.parse(bank).code;
    const reqBody = {
      account_number: accountNumber,
      bank_code: bankCode,
    };
    const { data, error } = await verifyAccountName(reqBody);

    if (error) {
      setVerifyError(error.message);
    }

    setAccountName(data?.account_name || '');
    setIsVerifying(false);
  }

  // populate fields with values
  useEffect(() => {
    setAccountNumber(vendor?.bankAccountDetails?.accountNumber || '');
    setBank(vendor?.bankAccountDetails?.bankName || '');
  }, [vendor]);

  // fetch list of banks
  useEffect(() => {
    fetchBanks();
  }, []);

  // verify account name
  useEffect(() => {
    if (bank && accountNumber?.length === 10 && !vendor?.hasBankAccount) {
      verifyBankAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank, accountNumber]);

  return (
    <Modal
      open={isModalOpen}
      okText={`Add Bank`}
      okButtonProps={{
        className: 'bg-[#006355] mt-12 h-[40px]',
        disabled: isLoading,
      }}
      cancelButtonProps={{ className: 'h-[40px]' }}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <div className="text-center">
        <h3 className="md:font-semibold text-lg text-[#07000F]">
          Add bank account
        </h3>
      </div>
      <div className="mt-8 space-y-6">
        <div>
          <SelectField
            label="Bank"
            value={bank}
            setValue={setBank}
            options={banksList}
          />
          {fetchBanksError && (
            <span className="inline-block mt-1 text-xs text-red-600">
              Error fetching list of banks
            </span>
          )}
        </div>
        <div>
          <NumberField
            label="Account Number"
            value={accountNumber}
            setValue={setAccountNumber}
            limit={10}
          />
          {verifyError && (
            <span className="inline-block mt-1 text-xs text-red-600">
              Verification failed
            </span>
          )}
          {accountName && (
            <span className="inline-block mt-3 text-sm text-gray-700 ">
              {accountName}
            </span>
          )}
          {isVerifying && (
            <span className="inline-block mt-1 text-xs text-gray-700 ">
              Verifying ...
            </span>
          )}
        </div>
      </div>
      {error && <div className="mt-4 text-sm text-red-600">{error}</div>}
    </Modal>
  );
};

export default BankAccountModal;
