/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import DashboardCard from 'components/dashboard/DashboardCard';
import { Link } from 'react-router-dom';
import {
  FilterInterface,
  ITransactions,
  IVendorCompliance,
  ROUTE_PATHS,
  VendorInvoice,
  VendorType,
  getIcon,
} from 'utils';
import AccountCompletion from 'components/dashboard/AccountCompletion';
import AddressModal from 'components/dashboard/AddressModal';
import { useVendor } from 'contexts/VendorContext';
import Loader from 'components/common/Loader';
import {
  getTransactions,
  getVendorComplianceStatus,
  getVendorInvoices,
} from 'functions/vendor-functions';
import toast from 'react-hot-toast';
import { useAuth } from 'contexts/AuthContext';
import moment from 'moment';
import { getInitials } from 'utils/format';
import KYBAlert from 'components/dashboard/KYBAlert';
import { ArrowRight } from 'react-iconly';

export default function Dashboard() {
  const { vendor } = useVendor();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [addressType, setAddressType] = useState('');
  const [transactions, setTransactions] = useState<ITransactions[]>([]);
  const [, setIsLoading] = useState(false);

  const [refreshInvoices, setRefreshInvoices] = useState(false);
  const [pendingInvoices, setPendingInvoices] = useState<VendorInvoice[]>([]);
  const [rejectedInvoices, setRejectedInvoices] = useState<VendorInvoice[]>([]);
  const [invoices, setInvoices] = useState<VendorType[]>([]);
  const [compStatus, setCompStatus] = useState<IVendorCompliance>();

  const [filters] = useState<FilterInterface>({
    page: 1,
    perPage: 20,
    limit: 20,
    pageCount: 1,
  });
  const { logout } = useAuth();

  const fetchTransactions = async () => {
    setIsLoading(true);
    const { data, responseError, refreshError } = await getTransactions();

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    if (data) {
      setTransactions(data?.transactions?.slice(-3));
      setIsLoading(false);
    }

    if (refreshError) {
      logout();
      return;
    }
    return data as ITransactions[];
  };

  const getVendorKybStatus = async () => {
    const { data, responseError, refreshError } =
      await getVendorComplianceStatus();
    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    if (data) {
      setCompStatus(data?.vendor as IVendorCompliance);
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }
    return data as IVendorCompliance;
  };

  async function fetchVendorInvoices() {
    setIsLoading(true);
    const { data, responseError, refreshError } = await getVendorInvoices({
      filters,
    });

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }
    setInvoices(data?.vendorInvoices);

    const pending = data?.vendorInvoices?.filter(
      (invoice: VendorInvoice) => invoice.status === 'PENDING'
    ) as VendorInvoice[];

    const rejected = data?.vendorInvoices?.filter(
      (invoice: VendorInvoice) => invoice.status === 'DECLINED'
    ) as VendorInvoice[];

    setIsLoading(false);
    setPendingInvoices(pending);
    setRejectedInvoices(rejected);
    setRefreshInvoices(false);
  }

  // show address modal
  const showAddressModal = (type: string) => {
    setIsAddressModalOpen(true);
    setAddressType(type);
  };

  // close address modal
  const handleAddressCancel = () => {
    setIsAddressModalOpen(false);
    setAddressType('');
  };

  const totalAmount = transactions.reduce((acc, obj) => {
    return acc + obj.amount;
  }, 0);

  useEffect(() => {
    fetchTransactions();
    fetchVendorInvoices();
  }, [filters, refreshInvoices]);

  useEffect(() => {
    getVendorKybStatus();
  }, []);

  const dataOne = {
    topText: 'Total Amount of Invoice paid',
    amount: `₦${
      totalAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      }) || '0.00'
    }`,
    subText: `From ${invoices.length} Documents`,
    linkText: 'View breakdown',
    href: '/account/invoice-management',
    bgColor: '#D9F4FF',
    linkColor: '#BCE7F9',
    linkTextColor: '#0E526D',
  };
  const dataTwo = {
    topText: 'Pending Documents',
    amount: `${pendingInvoices.length}`,
    linkText: 'View Documents',
    href: '/account/invoice-management',
    bgColor: '#FEEFDA',
    linkColor: '#F2DEC2',
    linkTextColor: '#955D10',
  };
  const dataThree = {
    topText: 'Rejected Documents',
    amount: `${rejectedInvoices.length}`,
    linkText: 'View Documents',
    href: '/account/invoice-management',
    bgColor: '#FFE7E7',
    linkColor: '#FFCFCF',
    linkTextColor: '#983F55',
  };

  return (
    <>
      {!vendor ? (
        <Loader size="lg" color="green" />
      ) : (
        <>
          <div className="space-y-8">
            <div className="space-y-8">
              {(!compStatus?.kybStatus ||
                compStatus?.kybStatus?.toLowerCase() === 'inactive') && (
                <KYBAlert
                  description={
                    <>
                      <p>
                        Complete your KYB to get access to our invoice financing
                        product
                      </p>
                      <Link //for sm devices display only
                        to={`${ROUTE_PATHS.ACCOUNT}${ROUTE_PATHS.COMPLIANCE}`}
                        className="text-[#003C88] hover:text-[#003C88] underline flex justify-end  items-center mt-2 -mr-5 md:hidden "
                      >
                        Get Started
                        <ArrowRight size="small" stroke="bold" />
                      </Link>
                    </>
                  }
                  action={
                    <Link //for md devices display only
                      to={`${ROUTE_PATHS.ACCOUNT}${ROUTE_PATHS.COMPLIANCE}`}
                      className="text-[#003C88] hover:text-[#003C88] underline hidden md:flex items-center gap-1 mr-3 "
                    >
                      Get Started
                      <ArrowRight size="small" stroke="bold" />
                    </Link>
                  }
                />
              )}
              <div className="space-y-3">
                <h3 className="item-header md:font-semibold md:text-xl md:text-[#07000F]">
                  Hi {vendor?.vendorName}, welcome back 👋🏾
                </h3>
                <h5 className="text-sm font-normal text-gray-600">
                  Here is what's happening with your business today{' '}
                </h5>
              </div>
              <div className="space-y-4 md:grid md:grid-cols-3 md:space-x-10 md:space-y-0">
                <DashboardCard data={dataOne} />
                <DashboardCard data={dataTwo} />
                <DashboardCard data={dataThree} />
              </div>
            </div>
            {/* Account completion progress */}
            {!vendor?.hasMerchantInvite &&
              (!vendor?.hasBillingAddress ||
                !vendor?.hasShippingAddress ||
                !vendor?.hasBankAccount) && (
                <AccountCompletion
                  hasBilingAddress={vendor?.hasBillingAddress}
                  hasShippingAddress={vendor?.hasShippingAddress}
                  hasBankAccount={vendor?.hasBankAccount}
                />
              )}
            <div className="space-y-8 md:flex md:items-start md:space-y-0 md:space-x-6">
              {/* Business details */}
              <div className="border rounded-lg md:w-[60%]">
                <div className="p-4 border-b md:p-6">
                  <h3 className="item-header md:item-header-large">
                    Business details
                  </h3>
                </div>
                <div className="p-4">
                  <div className="pb-6 space-y-4 md:flex md:items-start md:space-y-0 md:justify-between">
                    <div className="space-y-4">
                      <h6 className="text-xs font-normal text-gray-600">
                        VENDOR
                      </h6>
                      <div className="flex items-center space-x-4">
                        <div className="w-9 h-9 text-black bg-[#2DB49E] rounded-full flex justify-center items-center uppercase text-lg font-bold">
                          {getInitials(vendor?.vendorName as string)}
                        </div>
                        <div>
                          <h6 className="text-sm font-medium">
                            {vendor?.vendorName}
                          </h6>
                          <h6 className="text-xs font-normal text-gray-600">
                            {vendor?.email}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-4">
                      <h6 className="text-xs font-normal text-gray-600">
                        INVITING BUSINESS
                      </h6>
                      <span className="text-sm font-medium capitalize">
                        {vendor?.hasMerchantInvite
                          ? `${vendor?.vendorMerchantDetails.merchantName}`
                          : 'No business yet'}
                      </span>
                    </div>
                  </div>
                  {/* Contacts */}
                  {vendor?.hasMerchantInvite && (
                    <div className="py-4 pb-8 space-y-4 border-t">
                      <h6 className="text-xs font-normal text-gray-600">
                        CONTACT PERSON
                      </h6>
                      <div className="flex items-center space-x-4">
                        <div className="w-9 h-9 text-black bg-[#2DB49E] rounded-full flex justify-center items-center uppercase text-lg font-bold">
                          {getInitials(
                            vendor?.vendorMerchantDetails?.contactPerson
                              ?.name as string
                          )}
                        </div>
                        <div>
                          <h6 className="text-sm font-medium capitalize">
                            {vendor?.vendorMerchantDetails?.contactPerson?.name}
                          </h6>
                          <h6 className="text-xs font-normal text-gray-600">
                            {
                              vendor?.vendorMerchantDetails?.contactPerson
                                ?.contact
                            }
                          </h6>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Shipping / Billing addresses */}
                  {(vendor?.hasBillingAddress ||
                    vendor?.hasShippingAddress ||
                    vendor?.vendorMerchantDetails?.billingAddress?.address ||
                    vendor?.vendorMerchantDetails?.shippingAddress
                      ?.address) && (
                    <div className="py-4 pb-6 space-y-6 border-t md:py-6 md:flex md:items-start md:space-y-0">
                      {(vendor?.hasBillingAddress ||
                        vendor?.vendorMerchantDetails?.billingAddress
                          ?.address) && (
                        <div className="space-y-2 md:flex-1">
                          <div className="flex items-center space-x-2">
                            <h6 className="text-xs font-normal text-gray-600">
                              BILLING ADDRESS
                            </h6>
                            {!vendor?.hasMerchantInvite && (
                              <i
                                className="cursor-pointer"
                                onClick={() => showAddressModal('billing')}
                              >
                                {getIcon('pencil')}
                              </i>
                            )}
                          </div>
                          <div className="flex flex-col space-y-1 text-sm font-medium">
                            <span>
                              {vendor?.billingAddress?.address ||
                                vendor?.vendorMerchantDetails?.billingAddress
                                  ?.address}
                            </span>
                            <span>
                              {vendor?.billingAddress?.city ||
                                vendor?.vendorMerchantDetails?.billingAddress
                                  ?.city}
                              ,{' '}
                              {vendor?.billingAddress?.state ||
                                vendor?.vendorMerchantDetails?.billingAddress
                                  ?.state}
                              ,{' '}
                              {vendor?.billingAddress?.country ||
                                vendor?.vendorMerchantDetails?.billingAddress
                                  ?.country}
                            </span>
                          </div>
                        </div>
                      )}
                      {(vendor?.hasShippingAddress ||
                        vendor?.vendorMerchantDetails?.shippingAddress
                          ?.address) && (
                        <div className="space-y-2 md:flex-1">
                          <div className="flex items-center space-x-2">
                            <h6 className="text-xs font-normal text-gray-600">
                              SHIPPING ADDRESS
                            </h6>
                            {!vendor?.hasMerchantInvite && (
                              <i
                                className="cursor-pointer"
                                onClick={() => showAddressModal('shipping')}
                              >
                                {getIcon('pencil')}
                              </i>
                            )}
                          </div>
                          <div className="flex flex-col space-y-1 text-sm font-medium">
                            <span>
                              {vendor?.shippingAddress?.address ||
                                vendor?.vendorMerchantDetails?.shippingAddress
                                  ?.address}
                            </span>
                            <span>
                              {vendor?.shippingAddress?.city ||
                                vendor?.vendorMerchantDetails?.shippingAddress
                                  ?.city}
                              ,{' '}
                              {vendor?.shippingAddress?.state ||
                                vendor?.vendorMerchantDetails?.shippingAddress
                                  ?.state}
                              ,{' '}
                              {vendor?.shippingAddress?.country ||
                                vendor?.vendorMerchantDetails?.shippingAddress
                                  ?.country}
                            </span>
                          </div>
                        </div>
                      )}

                      <AddressModal
                        isModalOpen={isAddressModalOpen}
                        type={addressType}
                        handleCancel={handleAddressCancel}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* Recent payments */}
              <div className="border rounded-lg space-y-6 min-h-[350px] md:w-[40%] md:min-h-[380px]">
                <div className="p-4 border-b md:p-6">
                  <h3 className="item-header md:item-header-large">
                    Recent Payments Received
                  </h3>
                </div>
                <div className="px-4 space-y-4 md:px-6">
                  {transactions.map((item, index) => (
                    <div
                      className="flex items-center justify-between"
                      key={index}
                    >
                      <div>
                        <h5 className="font-medium text-[16px] md:text-lg">
                          N
                          {item.amount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          }) || '0.00'}
                        </h5>
                        <h6 className="font-medium text-[10px] md:text-xs text-gray-600">
                          Received from {item?.invoiceNumber || 'N/A'}
                        </h6>
                      </div>
                      <div>
                        <h5 className="text-xs font-normal text-gray-600 md:text-sm">
                          Received
                        </h5>
                        <h6 className="font-medium text-sm md:text-[16px]">
                          {moment(item.date).format('DD/MM/yyyy')}
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-center">
                  <Link
                    to="/account/transactions"
                    className="inline-block px-8 py-3 text-sm font-medium text-gray-600 border rounded-lg"
                  >
                    See all payments
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
