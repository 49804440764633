export default {
  API_ENDPOINT: process.env.REACT_APP_BASE_URL as string,
  BANK_URL: process.env.REACT_APP_FETCH_BANKS_URL as string,
  VERIFY_ENPOINT: process.env.REACT_APP_VERIFY_ACCOUNT_URL as string,
  BILLS_ENPOINT: process.env.REACT_APP_BILLS_URL as string,
  KEY: process.env.REACT_APP_KEY as string,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
  PROD: process.env.NODE_ENV === 'production',
  RUDDERSTACK_DATA_PLAIN_URL: process.env.REACT_APP_RUDDERSTACK_DATAPLANE_URL,
  RUDDERSTACK_WRITE_KEY: process.env.REACT_APP_RUDDERSTACK_APP_KEY,
};
