import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import URL_CONFIG from 'config';
import { FilterInterface } from 'utils';

interface RequestWithRetryResult {
  data: any;
  responseError: AxiosError | null;
  refreshError: AxiosError | null;
}

interface BillsRequest {
  billNumber: string;
  business_id: string;
  filters: FilterInterface;
}

export const requestWithRetry = async (
  config: AxiosRequestConfig,
  retriesLeft = 1
): Promise<RequestWithRetryResult> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    const response = await axios({
      ...config,
    });

    return { data: response.data, responseError: null, refreshError: null };
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;

      // make request to refresh tokens and retry request
      if (status === 401 && retriesLeft > 0) {
        const refresh_token = localStorage.getItem('refreshToken');
        const refreshConfig: AxiosRequestConfig = {
          method: 'post',
          url: `${config}/auth/refresh-token`,
          data: {
            refresh_token,
          },
        };

        try {
          const refreshResponse = await axios(refreshConfig);
          localStorage.setItem(
            'accessToken',
            refreshResponse?.data?.access_token
          );
          localStorage.setItem(
            'refreshToken',
            refreshResponse?.data?.refresh_token
          );

          // Retry the original request
          return requestWithRetry(config, retriesLeft - 1);
        } catch (refreshError: any) {
          return { data: null, responseError: null, refreshError };
        }
      }

      return { data: null, responseError: error, refreshError: null };
    }

    return { data: null, responseError: error, refreshError: null };
  }
};

export const getListOfBanks = async () => {
  try {
    const res = await axios.get(URL_CONFIG.BANK_URL, {
      headers: {
        dpmlekdno: URL_CONFIG.KEY,
      },
    });

    return { data: res.data.data, error: null };
  } catch (error: any) {
    return { data: null, error };
  }
};

export const getBillsTransactions = async ({
  business_id,
  billNumber,
  filters,
}: BillsRequest) => {
  try {
    const res = await axios.get(
      `${URL_CONFIG.BILLS_ENPOINT}/bill-transactions/${billNumber}?business_id=${business_id}&page=${filters.page}&limit=${filters.limit}&pageCount=${filters.pageCount}&perPage=${filters.perPage}`,
      {
        headers: {
          dpmlekdno: URL_CONFIG.KEY,
        },
      }
    );

    return { data: res.data.data, error: null };
  } catch (error: any) {
    return { data: null, error };
  }
};
export const verifyAccountName = async (reqBody: any) => {
  try {
    const config = {
      method: 'post',
      url: URL_CONFIG.VERIFY_ENPOINT,
      headers: {
        dpmlekdno: URL_CONFIG.KEY,
      },
      data: reqBody,
    };

    const res = await axios({
      ...config,
    });
    return { data: res.data.data, error: null };
  } catch (error: any) {
    return { data: null, error };
  }
};

export default requestWithRetry;
