import { useState } from 'react';
import { Modal } from 'antd';
import { updateLoanStatus } from 'functions/vendor-functions';
import toast from 'react-hot-toast';
import { useAuth } from 'contexts/AuthContext';
import { ReactComponent as ApproveIcon } from 'assets/svg/confirm-approve.svg';
import InvoiceSuccessModal from './InvoiceSuccessModal';

export interface InvoiceFinancingModalProps {
  isOpen: boolean;
  handleSuccessCancel: () => void;
  billNumber?: string;
}

export const ApprovaLoansModal = ({
  handleSuccessCancel,
  isOpen,
  billNumber,
}: InvoiceFinancingModalProps) => {
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const openModal = () => {
    handleSuccessCancel();
    setIsSuccessModalOpen((prev) => !prev);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen((prev) => !prev);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleApproveLoan = async () => {
    setIsLoading(true);
    const { data, responseError, refreshError } = await updateLoanStatus({
      billNumber: billNumber,
      status: 'accepted',
    });
    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }
    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }
    if (data !== undefined) {
      setIsLoading(false);
      openModal();
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={handleSuccessCancel}
        cancelButtonProps={{
          className: 'h-[40px] font-semibold !text-[#004b40]',
        }}
        okText={`${isLoading ? 'Loading...' : 'Yes, I accept loan terms'}`}
        okButtonProps={{
          className: 'bg-[#006355]  mt-12 h-[40px] font-semibold',
        }}
        onOk={handleApproveLoan}
      >
        <div className="mt-10 space-y-4 text-[#727E8F] flex justify-center items-center flex-col">
          <ApproveIcon />
          <h1 className="text-[#07000F] font-semibold text-lg">
            Loan terms confirmation
          </h1>
          <p>
            Please confirm that you are comfortable with this loan terms before
            accepting as this action can not be reversed.
          </p>
        </div>
      </Modal>
      <InvoiceSuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        handleSuccessCancel={closeSuccessModal}
        invoiceNumber={billNumber}
        headerText="Loan term accepted successfully!"
        subText="Your loan is now been processed. You will hear from us shortly."
        isInvoice
      />
    </>
  );
};
