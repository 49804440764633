import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import { ROUTE_PATHS } from 'utils';
import URL_CONFIG from 'config';
import axios from 'axios';
import ClockAndExclamationImage from 'assets/png/clock-and-exclamation-mark .png';
import Logo from 'assets/png/white-logo.png';

export default function Verification() {
  const redirectUrl = useRef(ROUTE_PATHS.AUTH + ROUTE_PATHS.SIGNUP);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const phone_number = searchParams.get('phone_number');
  const [isVerificationComplete, setIsVerificationComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const [verificationError, setVerificationError] = useState('');

  async function handleVerification() {
    try {
      const response = await axios.post(
        `${URL_CONFIG.API_ENDPOINT}/auth/verify-merchant-invite`,
        {
          token,
        }
      );

      if (response.data.status === 'SUCCESS') {
        redirectUrl.current += `?name=${response.data.vendor_name}&email=${response.data.email}&token=${token}&phone_number=${phone_number}`;
        setIsVerificationComplete(true);
      }
    } catch (error: any) {
      // Handle error
      const errObj = error.response.data;
      setVerificationError(
        `Error verifying merchant invite ${errObj.error_message}`
      );
    }
    setIsProcessing(false);
  }

  useEffect(() => {
    handleVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {isProcessing && <div>Processing, please wait...</div>}
      {isVerificationComplete && <Navigate to={redirectUrl.current} replace />}
      {verificationError && (
        <div
          className="flex flex-col items-center w-full h-screen pt-24 bg-center bg-repeat bg-cover"
          style={{ backgroundImage: `url('/background.png')` }}
        >
          <div className="py-2">
            <img src={Logo} alt="White Logo" />
          </div>
          <div className="w-[644px] px-20 py-3 bg-white rounded-md h-[300px]">
            <div className="flex justify-center mt-5">
              <img src={ClockAndExclamationImage} alt="Verify failure" />
            </div>
            <h1 className="py-4 text-lg font-semibold text-center">
              The verification link has expired.
            </h1>
            <p className="text-center text-[#484847B2] font-normal text-base">
              Please check your email for a new link to complete your account
              setup.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
