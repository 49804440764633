import React from 'react';
import { getIcon } from '../../utils';

interface Props {
    isCompleted: boolean | undefined;
    text: string;
    subText: string;
    buttonText: string;
    action: any;
}

const AccountCompletionItem: React.FC<Props> = ({
    isCompleted,
    text,
    subText,
    buttonText,
    action,
}) => {
    return (
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between border p-6 rounded-lg">
            <div className="flex items-center space-x-4">
                {isCompleted ? (
                    <i>{getIcon('complete')}</i>
                ) : (
                    <i>{getIcon('incomplete')}</i>
                )}
                <div>
                    <h6 className="font-semibold text-[13px] md:text-sm text-[#07000F]">
                        {text}
                    </h6>
                    <span className="font-normal text-[11px] md:text-xs text-gray-600">
                        {subText}
                    </span>
                </div>
            </div>
            {!isCompleted && (
                <button
                    className="font-medium text-sm text-[#5C696E] py-2 px-4 border rounded-lg block ml-auto"
                    onClick={action}
                >
                    {buttonText}
                </button>
            )}
        </div>
    );
};

export default AccountCompletionItem;
