import classNames from 'classnames';

export type IProps = {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
};

const Loader = ({ size, color }: IProps) => {
  return (
    <span
      className={classNames('loading loading-spinner', {
        'loading-md': size === 'md',
        'loading-sm': size === 'sm',
        'loading-lg': size === 'lg',
        'text-success': color === 'green',
      })}
    ></span>
  );
};

export default Loader;
