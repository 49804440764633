import InProgressIcon from 'assets/png/in-progress.png';
import CompletedIcon from 'assets/png/completed.png';
import NotStartedIcon from 'assets/png/not-started.png';
import { useEffect, useState } from 'react';
import { getVendorComplianceStatus } from 'functions/vendor-functions';
import toast from 'react-hot-toast';
import { useAuth } from 'contexts/AuthContext';
import { IVendorCompliance } from 'utils';
import { useNavigate } from 'react-router-dom';

const Compliance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [compStatus, setCompStatus] = useState<IVendorCompliance>();

  const fetchComlianceStatus = async () => {
    setIsLoading(true);

    const { data, responseError, refreshError } =
      await getVendorComplianceStatus();

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }

    setIsLoading(false);
    setCompStatus(data?.vendor as IVendorCompliance);
  };

  useEffect(() => {
    fetchComlianceStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComplianceInfo = () => {
    if (
      compStatus?.kybStatus &&
      compStatus?.kybStatus.toLowerCase() === 'pending'
    ) {
      return (
        <div className="w-[70%]">
          <img
            src={InProgressIcon}
            width={74}
            height={74}
            alt="In Progress KYC"
          />
          <p className="mt-5 text-md text-[#07000F] font-semibold">
            Account verification in progress
          </p>
          <p className="font-light text-sm text-[#484847CC]">
            We are currently reviewing your documents. We will get back to you
            with an update within 48 business hours. Please contact our team via{' '}
            <a
              className="text-[#006355] underline"
              href="mailto:support@tryduplo.com"
            >
              support@tryduplo.com{' '}
            </a>
            to get more information if this is taking more than expected.
          </p>

          <div className="mt-7">
            <a
              className="bg-[#006355] text-white font-medium rounded-md text-sm py-3 px-6 cursor-pointer"
              href="mailto:support@tryduplo.com"
            >
              Get in touch
            </a>
          </div>
        </div>
      );
    }
    if (
      compStatus?.kybStatus &&
      compStatus?.kybStatus?.toLowerCase() === 'activated'
    ) {
      return (
        <div className="w-3/5">
          <img src={CompletedIcon} width={74} height={74} alt="Completed KYC" />
          <p className="mt-5 text-md text-[#07000F] font-semibold">
            Account verification successful{' '}
          </p>
          <p className="font-light text-sm text-[#484847CC]">
            You can now enjoy the full benefit of your portal
          </p>
          <div className="mt-7">
            <button
              className="bg-[#006355] text-white font-medium rounded-md text-sm py-3 px-6 mt-4 cursor-pointer"
              onClick={() => navigate('/')}
            >
              Go home
            </button>
          </div>
        </div>
      );
    }
    if (!compStatus?.kybStatus || compStatus?.kybStatus === 'inactive') {
      return (
        <div className="w-3/5">
          <img src={NotStartedIcon} width={74} height={74} alt="Not Started" />
          <p className="mt-5 text-md text-[#07000F] font-semibold">
            KYB verification{' '}
          </p>
          <p className="font-light text-sm text-[#484847CC]">
            Complete your KYB to get access to our invoice financing product{' '}
          </p>

          <div className="mt-7">
            <a
              className="bg-[#006355] text-white font-medium rounded-md text-sm py-3 px-6 cursor-pointer"
              onClick={() => navigate('/account/compliance')}
            >
              Get Started
            </a>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="p-4 space-y-8">
          <h3 className="item-header-large">Compliance</h3>
          {getComplianceInfo()}
        </div>
      )}
    </>
  );
};

export default Compliance;
