import { Modal } from 'antd';
import UploadDocumentSuccessIcon from 'components/icons/UploadDocumentSuccessIcon';

interface SuccessModalType {
  isSuccessModalOpen: boolean;
  handleSuccessCancel: () => void;
  invoiceNumber: string | undefined;
  headerText: string;
  subText: string;
  isInvoice?: boolean;
}

const InvoiceSuccessModal = ({
  isSuccessModalOpen,
  handleSuccessCancel,
  invoiceNumber,
  headerText,
  subText,
  isInvoice,
}: SuccessModalType) => {
  return (
    <Modal
      open={isSuccessModalOpen}
      footer={null}
      onCancel={handleSuccessCancel}
    >
      <div className="text-center font-medium text-lg text-[#07000F]">
        {isInvoice ? 'Document' : 'Invoice'} #{invoiceNumber}
      </div>
      <div className="flex flex-col space-y-4 items-center justify-center h-[300px]">
        <UploadDocumentSuccessIcon />
        <h3 className="font-semibold text-lg text-[#07000F]">{headerText}</h3>
        <span className="px-5 font-normal text-sm text-[#484847] text-center">
          {subText}
        </span>
      </div>
    </Modal>
  );
};

export default InvoiceSuccessModal;
