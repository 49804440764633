import { FormInputProps } from '../../utils';
import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

interface CustomSelectComponentProps
  extends Omit<
    FormInputProps,
    'title' | 'type' | 'ref' | 'value' | 'defaultValue' | 'list'
  > {
  list: { option: string; value: string }[];
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  maxLength?: number;
  className?: string;
  label?: string;
  labelStyles?: string;
  dropDownStyles?: string;
  placeholder?: string;
}

const CustomSelectComponent: React.FC<CustomSelectComponentProps> = ({
  list,
  value,
  setValue,
  label,
  labelStyles,
  className,
  placeholder = 'Select Option',
  dropDownStyles,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef<HTMLInputElement>(null);
  const [field, meta] = useField(props);

  function useDebounce(value: string, delay: number) {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const [query, setQuery] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const debouncedQuery = useDebounce(query, 300);

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isOpen]);

  return (
    <>
      <div className="relative group">
        <div
          className={classNames(
            'text-sm font-light text-[#192850]',
            labelStyles
          )}
        >
          {label}
        </div>
        <button
          id="dropdown-button"
          className={classNames(
            'inline-flex h-12 items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border rounded-md shadow-sm focus:outline-none focus:border-[#006355] focus:border-2',
            className
          )}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          {...field}
        >
          <span className="mr-2">{!value ? placeholder : value}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 ml-2 -mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {isOpen && (
          <div
            id="dropdown-menu"
            className={classNames(
              'absolute z-10 right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 max-h-48 overflow-auto',
              dropDownStyles
            )}
       
          >
            {list.length > 5 && (
              <input
                id="search-input"
                className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                type="text"
                placeholder="Search here"
                onChange={(e) => setQuery(e.target.value)}
              />
            )}
            {query &&
              list
                .filter(
                  ({ option }) =>
                    option?.toLowerCase().includes(query?.toLowerCase())
                )
                .map(({ option, value }) => (
                  <a
                    key={value}
                    onClick={() => {
                      setValue && setValue(option);
                      setIsOpen(false);
                      setQuery('');
                    }}
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                  >
                    {option}
                  </a>
                ))}

            {!query &&
              list.map(({ option, value }) => (
                <a
                  key={value}
                  onClick={() => {
                    setValue && setValue(option);
                    setIsOpen(false);
                    setQuery('');
                  }}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                >
                  {option}
                </a>
              ))}
          </div>
        )}
        {meta.touched && meta.error ? (
          <span className="text-sm text-red-700">{meta.error}</span>
        ) : null}{' '}
      </div>
    </>
  );
};

export default CustomSelectComponent;
