import { getInitials } from 'utils/format';

export interface AvatarProps {
  name: string;
}

function Avatar({ name }: AvatarProps) {
  return (
    <div className="w-8 h-8 text-black bg-[#2DB49E] rounded-full flex justify-center items-center uppercase text-lg font-bold">
      {getInitials(name)}
    </div>
  );
}

export default Avatar;
