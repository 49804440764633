import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.css';

export interface PaginationOnChange {
  selected: number;
}

interface PaginationProps {
  selectedOption?: string;
  currentPage?: number;
  setSelectedOption: Function;
  pageCount: number;
  handlePaginationChange?: Function;
  gripped?: boolean;
  list?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  handlePaginationChange,
  currentPage = 1,
  list,
}) => {
  return (
    <>
      <div className="flex items-center justify-between px-1 py-3 page">
        <div className="text-[#5C696E] font-medium text-sm">
          1 - {list} of {list} record(s)
        </div>
        <div>
          <details className="dropdown">
            <summary className="px-3 m-1 btn text-[#5C696E] !h-9 !min-h-8">
              Page {currentPage}
            </summary>

            {/* <ul className="text-black bg-[#F8F9F9] shadow menu dropdown-content rounded-box w-36">
              {pageSize.map((page, index) => (
                <li key={index}>
                  <a onClick={() => setSelectedOption(page.value)}>
                    {page.name}
                  </a>
                </li>
              ))}
            </ul> */}
          </details>
        </div>
        <div className="pagination__div">
          <ReactPaginate
            breakLabel="..."
            nextLabel={'Next'}
            onPageChange={(e: PaginationOnChange) =>
              handlePaginationChange?.(e)
            }
            marginPagesDisplayed={2}
            forcePage={currentPage >= 1 ? currentPage - 1 : 0}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={'Prev'}
          />
        </div>
      </div>
    </>
  );
};

export default Pagination;
