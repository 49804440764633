import React, { ReactNode } from 'react';

interface IProps {
    children: ReactNode;
}

const AuthLayout: React.FC<IProps> = ({ children }) => {
    return <main>{children}</main>;
};

export default AuthLayout;
