import { FormInputProps } from '../../utils';
import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

interface TextInputProps extends FormInputProps {
  type?: string;
  value?: string;
  disabled?: boolean;
  autocomplete?: string;
  maxLength?: number;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  label: string;
  labelStyles?: string;
}

const InputComponent: React.FC<TextInputProps> = ({
  type = 'text',
  disabled,
  maxLength,
  onKeyDown,
  onInput,
  onChange,
  className,
  label,
  labelStyles,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div>
      <div className={classNames('flex flex-col')}>
        <span
          className={classNames(
            'text-sm font-semibold text-[#192850]',

            labelStyles
          )}
        >
          {label}
        </span>
        <input
          disabled={disabled}
          onKeyDown={onKeyDown}
          onInput={onInput}
          type={type}
          className={classNames(
            'border-2 rounded-md h-12 outline-[#006355] p-2 font-extralight text-[#162930]',
            {
              'border-red-700': meta.touched && meta.error,
            },
            className
          )}
          min={type === 'number' ? Number('0') : undefined}
          maxLength={maxLength}
          {...field}
          onChange={(e) => {
            if (typeof onChange === 'function') {
              onChange(e);
            }
            /* @HINT: `onChange` for <Formik /> from `useField()` */
            if (!e.defaultPrevented) {
              field.onChange(e);
            }
          }}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <span className="text-sm text-red-700">{meta.error}</span>
      ) : null}
    </div>
  );
};

export default InputComponent;
