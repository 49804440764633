import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  text: string;
  link?: string;
  linkText?: string;
}

const FormFooter: React.FC<IProps> = ({ text, link, linkText }) => {
  return (
    <div className="py-2 text-sm font-normal text-md md:text-base">
      <span className="text-[#192850]">{text}</span>{' '}
      {link && (
        <span className="text-[#006355] underline">
          <Link to={link}>{linkText}</Link>
        </span>
      )}
    </div>
  );
};

export default FormFooter;
