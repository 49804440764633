import { Progress } from 'antd';
import SuccessChecbox from 'components/icons/SuccessChecbox';
import UploadIcon from 'components/icons/UploadIcon';
import UploadSuccessIcon from 'components/icons/UploadSuccessIcon';
import React, { useState, useRef } from 'react';
import { Delete } from 'react-iconly';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Tooltip } from 'react-tooltip';

type FileType = 'image' | 'pdf' | 'doc';

interface FileUploadProps {
  label: string;
  onFileUpload: (file: File, id: string) => void;
  allowedTypes: FileType[];
  maxSizeMB: number;
  id: string;
  isRequired?: boolean;
  toolTip?: string | React.ReactElement;
  validationError?: boolean;
  handleRemoveFile?: (file_name: string) => void;
}

const FileUploader: React.FC<FileUploadProps> = ({
  label,
  onFileUpload,
  allowedTypes,
  maxSizeMB,
  id,
  isRequired,
  toolTip,
  validationError,
  handleRemoveFile,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const allowedExtensions = {
    image: ['.jpg', '.jpeg', '.png', '.gif'],
    pdf: ['.pdf'],
    doc: ['.doc', '.docx'],
  };

  const acceptedTypes = allowedTypes
    .flatMap((type) => allowedExtensions[type])
    .join(',');

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === 'dragenter' || e.type === 'dragover');
  };
  const [percent, setPercent] = React.useState<number>(0);

  const formatFileSize = (size: number) => {
    if (size < 1024) {
      return `${Math.round(size)} B`;
    } else if (size < 1024 * 1024) {
      return `${Math.round(size / 1024)} KB`;
    } else {
      return `${Math.round(size / (1024 * 1024))} MB`;
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0]);
    }
  };

  const handleFiles = (file: File) => {
    setPercent(0); // Reset percent to 0

    // Simulate file upload progress
    const simulateUpload = () => {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 10;
        setPercent(percent);
        if (percent >= 100) {
          clearInterval(interval);
        }
      }, 100);
    };

    // Start the simulation
    simulateUpload();

    // File validation
    const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase() || '';
    const isValidType = allowedTypes.some((type) =>
      allowedExtensions[type].includes(fileExtension)
    );

    if (isValidType && file.size <= maxSizeMB * 1024 * 1024) {
      onFileUpload(file, id);
      setUploadedFile(file);
    } else {
      setErrorMessage(
        `Please upload a valid file (${allowedTypes.join(', ')}) no larger than ${maxSizeMB}MB.`
      );
    }
  };

  const handleDelete = () => {
    if (handleRemoveFile) {
      handleRemoveFile(id);
    }
    setUploadedFile(undefined);
    setPercent(0);
  };

  return (
    <div className="mb-4">
      <div className="flex items-center">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {label}
          <span className="text-red-600">{isRequired && '*'}</span>
        </label>

        {toolTip && (
          <span className="mb-2.5 cursor-pointer ml-1">
            <IoInformationCircleOutline data-tooltip-id={label} size={17} />
          </span>
        )}

        <Tooltip
          border="0.5px solid #EEEEEE"
          id={label}
          place="bottom-start"
          style={{
            backgroundColor: '#fff',
            color: '#162930',
            borderRadius: '5px',
            boxShadow: '0px 12px 26px -7px #5C696E1A',
          }}
        >
          {toolTip}
        </Tooltip>
      </div>
      {uploadedFile?.name ? (
        <div className="justify-center border border-[#006355]/50  rounded-lg p-4 mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-start space-x-4">
              <UploadSuccessIcon />
              <div>
                <h6 className="font-semibold text-sm text-[#4A5361]">
                  {uploadedFile?.name}
                </h6>
                {uploadedFile?.size && (
                  <h6 className="font-light text-xs text-[#4A5361] uppercase">
                    {formatFileSize(uploadedFile.size)}
                  </h6>
                )}
              </div>
            </div>
            <SuccessChecbox />
          </div>
          <div className="flex items-center gap-2">
            <Progress
              percent={percent}
              strokeColor="#006355"
              success={{ percent, strokeColor: '#006355' }}
              style={{ marginBottom: '0' }}
              format={() => (
                <span className="text-[#4A5361]">
                  {percent <= 100 ? percent : '100'}%
                </span>
              )}
            />

            <span onClick={handleDelete} className="hover:text-red-600">
              <Delete
                size="medium"
                style={{ height: '18px', width: '18px', cursor: 'pointer' }}
              />
            </span>
          </div>
        </div>
      ) : (
        <div
          className={`border bg-[#F8F9F9] rounded-md p-4 text-center cursor-pointer
          ${dragActive ? '!border-[#006355] bg-blue-50' : 'border-gray-300'} 
          ${errorMessage || validationError ? 'border border-red-500' : 'border-gray-300'}
          `}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <div className="flex flex-col items-center">
            <UploadIcon />

            <div className="mt-3">
              <label className="text-sm text-[#006355] font-semibold cursor-pointer underline">
                <span>Click to upload</span>
                <input
                  ref={inputRef}
                  type="file"
                  className="hidden"
                  onChange={handleChange}
                  accept={acceptedTypes}
                />
              </label>

              <span className="text-sm text-[#4A5361] font-medium pl-1">
                or drag and drop
              </span>
              <p className="text-xs text-gray-500 mt-1">
                <span className="uppercase">{allowedTypes.join(', ')}</span>{' '}
                (max. {maxSizeMB}MB)
              </p>
            </div>
          </div>
        </div>
      )}

      {errorMessage && (
        <p className="text-xs text-red-600 pt-1">{errorMessage}</p>
      )}
    </div>
  );
};

export default FileUploader;
