import React, { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
}

const TransactionCard = ({ children }: IProps) => {
  return (
    <div className="border-2 border-[#EDEDF2] h-28 text-left py-2 px-3 shadow-[#ACACBE14] rounded-lg bg-white">
      {children}
    </div>
  );
};

export default TransactionCard;
