import React, { ReactNode } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { SettingsSideNavLinks } from '../../utils';

interface IProps {
  children: ReactNode;
}
const SettingsLayout: React.FC<IProps> = ({ children }) => {
  return (
    <>
      {/* Mobile View */}
      <div className="md:hidden">
        <div className="py-6">
          <ul className="flex justify-start items-center">
            {SettingsSideNavLinks.map((navlink, index) => (
              <div key={index}>
                <NavLink
                  className={`${({ isActive }: { isActive: boolean }) =>
                    isActive
                      ? 'active'
                      : 'inactive'} flex items-center space-x-4 font-normal text-sm text-[#4A5361] p-3 rounded`}
                  to={navlink.path}
                >
                  <span>{navlink.text}</span>
                </NavLink>
              </div>
            ))}
          </ul>
        </div>
        <div>{children ? <>{children}</> : <Outlet />}</div>
      </div>
      <div className="hidden md:flex border rounded-xl min-h-[600px]">
        <div className="border-r p-6 w-[18%]">
          <ul className="space-y-4">
            {SettingsSideNavLinks.map((navlink, index) => (
              <li key={index}>
                <NavLink
                  className={`${({ isActive }: { isActive: boolean }) =>
                    isActive
                      ? 'active'
                      : 'inactive'} flex items-center space-x-4 font-normal text-sm text-[#4A5361] p-3 rounded`}
                  to={navlink.path}
                >
                  <span>{navlink.text}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-[90%]">{children ? <>{children}</> : <Outlet />}</div>
      </div>
    </>
  );
};

export default SettingsLayout;
