import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import TextField from '../common/TextField';
import SelectField from '../common/SelectField';
import { updateVendorDetails } from '../../functions/vendor-functions';
import { useVendor } from '../../contexts/VendorContext';
import { useAuth } from '../../contexts/AuthContext';
import { NIGERIA_STATES } from '../../utils';

interface Props {
  isModalOpen: boolean;
  type: string;
  handleCancel: () => void;
}

const AddressModal: React.FC<Props> = ({
  isModalOpen = false,
  type,
  handleCancel,
}) => {
  const { vendor, initVendor } = useVendor();
  const { logout } = useAuth();
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  async function handleSubmit() {
    setError('');
    setIsLoading(true);

    if (!address || !country || !city || !state) {
      setError('All fields are required');
      setIsLoading(false);
      return;
    }

    const addressObj = {
      address,
      country,
      city,
      state,
    };

    const reqBody =
      type === 'billing'
        ? { billingAddress: addressObj }
        : { shippingAddress: addressObj };

    const { data, responseError, refreshError } =
      await updateVendorDetails(reqBody);

    if (responseError) {
      setError(responseError.message);
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
    }

    initVendor(data);
    setIsLoading(false);
    handleCancel();
  }

  // populate fields with values if exist
  useEffect(() => {
    setAddress(
      (type === 'billing'
        ? vendor?.billingAddress?.address
        : vendor?.shippingAddress?.address) || ''
    );

    setCountry(
      (type === 'billing'
        ? vendor?.billingAddress?.country
        : vendor?.shippingAddress?.country) || ''
    );

    setCity(
      (type === 'billing'
        ? vendor?.billingAddress?.city
        : vendor?.shippingAddress?.city) || ''
    );

    setState(
      (type === 'billing'
        ? vendor?.billingAddress?.state
        : vendor?.shippingAddress?.state) || ''
    );
  }, [type, vendor]);

  return (
    <Modal
      open={isModalOpen}
      okText={`Add address`}
      okButtonProps={{
        className: 'bg-[#006355] mt-12 h-[40px]',
        disabled: isLoading,
      }}
      cancelButtonProps={{ className: 'h-[40px]' }}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <div className="text-center">
        <h3 className="md:font-semibold text-lg text-[#07000F]">
          Add {type} address
        </h3>
      </div>
      <div className="mt-8 space-y-6">
        <TextField label="Address *" value={address} setValue={setAddress} />
        <SelectField
          label="Country *"
          value={country}
          setValue={setCountry}
          options={[{ option: 'Nigeria', value: 'Nigeria' }]}
        />
        <SelectField
          label="State *"
          value={state}
          setValue={setState}
          options={NIGERIA_STATES}
        />
        <TextField label="City *" value={city} setValue={setCity} />
      </div>
      {error && <div className="mt-4 text-sm text-red-600">{error}</div>}
    </Modal>
  );
};

export default AddressModal;
