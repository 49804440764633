import React, { useState } from 'react';
import AuthContainer from 'components/auth/AuthContainer';
import Button from 'components/common/Button';
import { Navigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ROUTE_PATHS } from 'utils';
import ConfirmationImage from 'assets/png/confirmation-image2.png';
import OtpInput from 'react-otp-input';
import toast from 'react-hot-toast';
import URL_CONFIG from 'config';

const EnterOtp = () => {
  const [otp, setOTP] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const flag = searchParams.get('flag');
  const email = searchParams.get('email')?.toLowerCase();

  if (!token && !flag) {
    return <Navigate to={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.SIGNUP}`} replace />;
  }

  if (flag === 'skip') {
    return (
      <Navigate
        to={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.SIGNUP_COMPLETE}`}
        replace
      />
    );
  }

  const handleVerify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      const response = await axios.post(
        `${URL_CONFIG.API_ENDPOINT}/auth/verify-otp`,
        {
          token,
          email,
          otp,
        }
      );

      if (response.data.status === 'SUCCESS') {
        toast.success('validation successful');
        setIsComplete(true);
      }
    } catch (error: any) {
      // Handle error
      const errObj = error.response.data;
      toast.error(`${errObj.error_message}`);
    }
    setIsProcessing(false);
  };

  const resendOtp = async () => {
    try {
      const response = await axios.get<{ email: string }>(
        `${URL_CONFIG.API_ENDPOINT}/auth/resend-email-otp?email=${email}`
      );
      if (response.data) {
        toast.success('Otp sent to your email, kindly check again.');
      }
    } catch (error: any) {
      const errObj = error.response.data;
      toast.error(`${errObj.error_message}`);
    }
  };

  if (isComplete)
    return (
      <Navigate
        to={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.SIGNUP_COMPLETE}`}
        replace
      />
    );

  return (
    <>
      <AuthContainer headerText="Enter OTP" headerPosition="center" isLogo>
        <div className="flex justify-center">
          <img src={ConfirmationImage} alt="Confirmation" />
        </div>
        <div className="text-center font-normal text-[#6C6D6D] text-base">
          Enter the OTP sent to your phone or email to create your vendor portal
        </div>
        <form className="space-y-6" onSubmit={handleVerify}>
          <div className="flex justify-center w-auto">
            <OtpInput
              value={otp}
              onChange={setOTP}
              numInputs={6}
              renderSeparator={<span> </span>}
              renderInput={(props) => (
                <input
                  {...props}
                  className="border-solid border-2 !w-10 h-10 !mx-2 rounded-md"
                />
              )}
            />
          </div>
          <Button text="Create account" disabled={isProcessing} loading={isProcessing} type="submit" />
        </form>
        <div className="text-sm font-normal text-center">
          <span className="text-[#192850]">Did not receive OTP?</span>&nbsp;
          <span
            className="text-[#006355] underline cursor-pointer"
            onClick={resendOtp}
          >
            Try again
          </span>
        </div>
      </AuthContainer>
    </>
  );
};

export default EnterOtp;
