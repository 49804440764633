import { useAuth } from 'contexts/AuthContext';
import {
  getAuditTrailData,
  getVendorComplianceStatus,
  getVendorFinancingStatus,
} from 'functions/vendor-functions';
import React from 'react';
import { IVendorCompliance, InvoiceFinanceStatus, VendorType } from 'utils';
import { removeCommas, truncateString } from 'utils/format';
import toast from 'react-hot-toast';
import { InvoiceFinancingModal } from 'components/modals/InvoiceFinancingModal';
import DocumentPreviewModal from 'components/modals/DocumentPreviewModal';
import classNames from 'classnames';
import moment from 'moment';
import { useVendor } from 'contexts/VendorContext';
import { ReactComponent as DocumentIcon } from 'assets/svg/approval-document.svg';
import { ApprovaLoansModal } from 'components/modals/ApproveLoansModal';
import { RejectLoansModal } from 'components/modals/RejectLoansModal';
import InvoiceNotificationBanner from 'components/common/InvoiceNotificationBanner';
import InvoiceDrwaer from 'components/common/InvoiceDrawer';
import AuditTrail from './AuditTrail';

export interface Props {
  invoice: VendorType | undefined;
  isVisible?: boolean;
  setInvoiceTable: () => void;
}

export const InvoicePreview = ({
  invoice,
  isVisible,
  setInvoiceTable,
}: Props) => {
  const { vendor } = useVendor();
  const fileText = invoice?.documentUrl?.split('/').pop() as string;
  const [compStatus, setCompStatus] = React.useState<IVendorCompliance>();
  const [, setIsLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isApprovalOpen, setIsApprovalOpen] = React.useState(false);
  const [isRejectOpen, setIsRejectOpen] = React.useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [isInvoiceFinancing, setIsInvoiceFinancing] = React.useState(false);
  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
  const handleSuccessCancel = () => setIsOpen((prev) => !prev);
  const handleApprovalCancel = () => setIsApprovalOpen((prev) => !prev);
  const handleRejectCancel = () => setIsRejectOpen((prev) => !prev);
  const [url, setUrl] = React.useState('');
  const documentsData = invoice?.supportingDocuments?.flat(Infinity);
  const [disableButton, setDisableButton] = React.useState(false);
  const [auditTrailInfo, setAuditTrailInfo] = React.useState<any>();
  const [isAuditTrailOpen, setIsAuditTrailOpen] = React.useState(false);

  const balance = Number(removeCommas(invoice?.balance || '0'));

  const disableButtonFunc = () => setDisableButton(true);

  const { logout } = useAuth();

  const getPaymentStatus = (status: string) => {
    let billStatus = status;
    if (status?.toLowerCase() === 'open') return (billStatus = 'Unpaid');
    if (status?.toLowerCase() === 'partiallypaid')
      return (billStatus = 'Partially Paid');

    return billStatus;
  };

  const getAmountPaid = () => {
    let amountPaid = 0;

    if (!invoice?.balance) {
      return amountPaid;
    } else {
      const amount =
        Number(removeCommas(invoice?.totalAmount || '0')) -
        Number(removeCommas(String(balance)));
      amountPaid = amount;
      return amountPaid;
    }
  };

  const fetchVendorFinanceSla = async () => {
    const { data, responseError, refreshError } =
      await getVendorFinancingStatus();

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      return;
    }
    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }

    const invoiceSlaData: InvoiceFinanceStatus = data;

    setIsInvoiceFinancing(invoiceSlaData.invoice_financing_sla_status);
  };

  const fetchAuditTrail = async () => {
    const { data, responseError, refreshError } = await getAuditTrailData({
      businessId: invoice?.businessId as string,
      invoiceNumber: invoice?.invoiceNumber as string,
    });

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      return;
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }
    setAuditTrailInfo(data);
    setIsAuditTrailOpen(true);
  };
  const fetchComlianceStatus = async () => {
    setIsLoading(true);

    const { data, responseError, refreshError } =
      await getVendorComplianceStatus();

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }

    setIsLoading(false);
    setCompStatus(data?.vendor as IVendorCompliance);
  };

  React.useEffect(() => {
    fetchComlianceStatus();
    fetchVendorFinanceSla();
    if (isVisible && invoice) {
      fetchAuditTrail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, invoice]);

  const displayButton = () => {
    if (
      invoice?.isBill &&
      invoice?.paymentStatus.toLowerCase() === 'open' &&
      compStatus?.kybStatus &&
      compStatus?.kybStatus?.toLowerCase() === 'activated' &&
      isInvoiceFinancing &&
      !invoice?.invoiceFinancingStatus
    ) {
      return (
        <button
          className="bg-[#006355] font-medium py-3 px-4 pr-5 rounded-md hover:bg-emerald-700 disabled:opacity-[0.4]"
          onClick={() => setIsOpen((prev) => !prev)}
          disabled={disableButton}
        >
          Request financing
        </button>
      );
    } else if (
      (isAcceptedOrRejected || invoice?.invoiceFinancingStatus === 'pending') &&
      !!invoice?.loanNote
    ) {
      return (
        <button
          className="bg-[#006355] font-medium py-3 px-5 pr-5 rounded-md hover:bg-emerald-700 disabled:opacity-[0.4]"
          onClick={() => setIsOpenDrawer((prev) => !prev)}
        >
          View loan terms
        </button>
      );
    } else <div></div>;
  };

  const isAcceptedOrRejected =
    invoice?.invoiceFinancingStatus === 'declined' ||
    invoice?.invoiceFinancingStatus === 'accepted';

  const openDrawer = () => {
    setIsOpenDrawer((prev) => !prev);
  };
  const openApprovalDrawer = () => {
    setIsApprovalOpen((prev) => !prev);
  };
  const openRejectDrawer = () => {
    setIsRejectOpen((prev) => !prev);
  };

  return (
    <>
      {invoice?.invoiceFinancingStatus === 'pending' && !!invoice?.loanNote && (
        <InvoiceNotificationBanner
          clickToOpen={() => setIsOpenDrawer((prev) => !prev)}
        />
      )}
      <div className="md:w-[95%] w-[90%] mt-10 mx-auto bg-white min-h-screen  rounded-lg space-x-6 md:grid-cols-2 grid md:gap-5 md:px-10 px-5 py-5">
        <div className=" mt-4 md:border md:rounded-lg md:border-[#F8F9F9]">
          <div className="flex items-center justify-between pb-2 text-white md:p-5">
            <h1 className="font-semibold text-[#162930] text-xl">
              Bill Information
            </h1>
            {displayButton()}
          </div>
          <div className="flex items-center justify-start gap-10 bg-[#F8F9F9] mt-2 py-4 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3  hidden md:block">
              Total Amount
            </p>
            <p className="text-[#484847CC] uppercase w-1/3 md:hidden">Amount</p>
            <p className="text-[#1D242E] font-semibold">
              ₦{' '}
              {Number(
                removeCommas(invoice?.totalAmount as string)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex items-center justify-start gap-10 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">
              Invoice Number
            </p>
            <p className="text-[#1D242E] font-semibold">
              #{invoice?.invoiceNumber}
            </p>
          </div>
          <div className="flex items-center justify-start gap-10 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">Balance</p>
            <p className="text-[#1D242E] font-semibold">
              ₦
              {Number(invoice?.balance || '0').toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex items-center justify-start gap-10 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">
              Payment Status
            </p>
            <p
              className={classNames(
                'font-medium text-[10px] inline-block px-2 py-1 rounded uppercase',
                {
                  'bg-[#FDEFCB] text-[#D97724] ':
                    invoice?.paymentStatus.toLowerCase() === 'open',
                  'bg-[#BBE5B3] text-[#414F3E] ':
                    invoice?.paymentStatus.toLowerCase() === 'paid',
                  'bg-[#CFE0F5] text-[#087FCA]':
                    invoice?.paymentStatus.toLowerCase() === 'partiallypaid',
                }
              )}
            >
              {getPaymentStatus(invoice?.paymentStatus as string)}
            </p>
          </div>
          <div className="flex items-center justify-start gap-10 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">Merchant</p>
            <p className="text-[#1D242E] font-semibold">
              {vendor?.vendorName || '-'}
            </p>
          </div>
          <div className="flex items-center justify-start gap-10 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">Due Date</p>
            <p className="text-[#1D242E] font-semibold">
              {moment(invoice?.createdAt).format('D, MMM YYYY')}
            </p>
          </div>
          <div className="flex items-center justify-start gap-10 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">Bank</p>
            <p className="text-[#1D242E] font-semibold">
              {vendor?.vendorMerchantDetails?.bankAccountDetails?.bankName ||
                '-'}
            </p>
          </div>
          <div className="flex items-center justify-start gap-10 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">
              Account Number
            </p>
            <p className="text-[#1D242E] font-semibold">
              {vendor?.vendorMerchantDetails?.bankAccountDetails
                ?.accountNumber || '-'}
            </p>
          </div>

          <div className="py-5 mt-5 border-t-2 md:p-5">
            <p className="uppercase text-[#484847CC]">Attached Document(s)</p>

            {Array.isArray(documentsData) && documentsData?.length !== 0 ? (
              <>
                {documentsData.map((docs, index) => (
                  <section key={index}>
                    <p className="text-[#5C696E99] capitalize pt-3">
                      {' '}
                      {docs?.document_name === 'purchaseOrder'
                        ? 'Purchase Order'
                        : docs?.document_name}
                    </p>
                    <div className="flex items-center justify-between p-4 bg-[#FDFDFD] border border-[#ECEBF5] rounded-md mt-2">
                      <div className="flex items-center justify-start">
                        <DocumentIcon />
                        <p className="text-xs md:text-base">
                          {truncateString(
                            docs?.file_locations[0]?.split('/').pop() as string,
                            13
                          )}
                        </p>
                      </div>
                      <div className="flex justify-end text-xs md:text-base">
                        <a
                          className="px-1 cursor-pointer text-[#2DB49E]"
                          onClick={() => {
                            setUrl(docs?.file_locations[0]);
                            setOpenPreviewModal(true);
                          }}
                        >
                          View
                        </a>{' '}
                        |{' '}
                        <p className="px-1 text-[#2DB49E]">
                          <a
                            href={docs?.file_locations[0]}
                            download
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download
                          </a>
                        </p>
                      </div>
                    </div>
                  </section>
                ))}
              </>
            ) : (
              <section>
                <p className="text-[#5C696E99] capitalize pt-3">Invoice</p>
                <div className="flex items-center justify-between p-4 bg-[#FDFDFD] border border-[#ECEBF5] rounded-md mt-2">
                  <div className="flex items-center justify-start">
                    <DocumentIcon />
                    <p className="text-xs md:text-base">{fileText}</p>
                  </div>
                  <div className="flex justify-end text-xs md:text-base">
                    <a
                      className="px-1 cursor-pointer text-[#2DB49E]"
                      onClick={() => {
                        setOpenPreviewModal(true);
                        setUrl(invoice?.documentUrl as string);
                      }}
                    >
                      View
                    </a>{' '}
                    |{' '}
                    <p className="px-1 text-[#2DB49E]">
                      <a
                        href={invoice?.documentUrl}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    </p>
                  </div>
                </div>
              </section>
            )}
            <div className="mt-10">
              <p className="text-[#162930] font-semibold">
                INVOICE FINANCING INFORMATION
              </p>
              <div className="flex items-center justify-start gap-4 mt-5">
                <p className="text-md">INVOICE FINANCING STATUS</p>
                <p
                  className={classNames(
                    'font-medium text-[10px] inline-block px-2 py-1 rounded uppercase',
                    {
                      'bg-[#FDEFCB] text-[#D97724] ':
                        invoice?.invoiceFinancingStatus?.toLowerCase() ===
                        'pending',
                      'bg-[#BBE5B3] text-[#414F3E] ':
                        invoice?.invoiceFinancingStatus?.toLowerCase() ===
                          'approved' ||
                        invoice?.invoiceFinancingStatus?.toLowerCase() ===
                          'accepted',
                      'bg-[#CFE0F5] text-[#087FCA]':
                        invoice?.invoiceFinancingStatus?.toLowerCase() ===
                          'sent' ||
                        !invoice?.invoiceFinancingStatus ||
                        invoice?.invoiceFinancingStatus === 'declined',
                    }
                  )}
                >
                  {invoice?.invoiceFinancingStatus ?? 'N/A'}
                </p>
              </div>
              {!!invoice?.invoiceFinancingStatus && !!invoice?.loanNote && (
                <section>
                  <div className="flex items-center justify-start gap-4 mt-5">
                    <p className="capitalize text-md">AMOUNT RECEIVED</p>
                    <p className="font-semibold">
                      {' '}
                      ₦
                      {invoice?.loanNote?.loanAmount?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      ) ?? 0.0}
                    </p>
                  </div>
                  <div className="flex items-center justify-start gap-4 mt-5">
                    <p className="uppercase text-md">Payment terms</p>
                    <p className="font-semibold">
                      {invoice?.loanNote?.tenor ?? 0} days
                    </p>
                  </div>
                  <div className="flex items-center justify-start gap-4 mt-5">
                    <p className="uppercase text-md">Payment date</p>
                    <p className="font-semibold">
                      {moment(invoice?.loanNote?.createdAt)
                        .add(invoice?.loanNote?.tenor, 'day')
                        .startOf('day')
                        .format('D, MMM YYYY')}{' '}
                    </p>
                  </div>
                </section>
              )}
            </div>
            <div>
              {(isAcceptedOrRejected ||
                invoice?.invoiceFinancingStatus === 'pending') &&
                !!invoice?.loanNote && (
                  <button
                    className="border border-[#DADAE7] rounded-lg text-center p-2 mt-3"
                    onClick={() => setIsOpenDrawer((prev) => !prev)}
                  >
                    View loan details
                  </button>
                )}
            </div>
          </div>
        </div>
        <section>
          {isAuditTrailOpen && isVisible && invoice && (
            <AuditTrail info={auditTrailInfo} />
          )}
        </section>
        <section>
          <div className="md:w-[36%] text-[#162930] uppercase font-semibold mt-5 md:px-5">
            Payment History
          </div>
          <div className="flex items-center justify-start gap-3 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">
              Total Amount
            </p>
            <p className="text-[#1D242E] font-semibold">
              ₦
              {Number(
                removeCommas(invoice?.totalAmount as string)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex items-center justify-start gap-3 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">
              Amount Paid
            </p>
            <p className="text-[#1D242E] font-semibold">
              ₦
              {getAmountPaid()?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) || '0.00'}
            </p>
          </div>
          <div className="flex items-center justify-start gap-3 py-2 md:px-5">
            <p className="text-[#484847CC] uppercase w-1/3 text-xs">
              Balance Due
            </p>
            <p className="text-[#1D242E] font-semibold">
              ₦
              {Number(
                removeCommas(invoice?.balance as string) || '0'
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
          {!!invoice?.billNumber && !!invoice?.businessId && (
            <button
              className="border border-[#DADAE7] rounded-lg md:w-1/4 text-center p-2 md:mx-5 mt-3"
              onClick={() => setInvoiceTable()}
            >
              View Transaction(s)
            </button>
          )}
        </section>

        <InvoiceDrwaer
          compStatus={compStatus}
          invoice={invoice}
          isOpenDrawer={isOpenDrawer}
          vendor={vendor}
          setIsOpenDrawer={openDrawer}
          setIsApprovalOpen={openApprovalDrawer}
          setIsRejectOpen={openRejectDrawer}
        />

        <DocumentPreviewModal
          open={openPreviewModal}
          url={url}
          closeModal={() => setOpenPreviewModal(false)}
        />
        <InvoiceFinancingModal
          isOpen={isOpen}
          fetchAuditTrail={fetchAuditTrail}
          handleSuccessCancel={handleSuccessCancel}
          invoiceNumber={invoice?.invoiceNumber}
          billNumber={invoice?.billNumber}
          vendorRef={invoice?.vendorRef}
          disableFunc={disableButtonFunc}
        />
        <ApprovaLoansModal
          isOpen={isApprovalOpen}
          handleSuccessCancel={handleApprovalCancel}
          billNumber={invoice?.billNumber}
        />
        <RejectLoansModal
          isOpen={isRejectOpen}
          handleSuccessCancel={handleRejectCancel}
          billNumber={invoice?.billNumber}
        />
      </div>
    </>
  );
};
