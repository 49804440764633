import React from 'react';
import AuthContainer from '../../components/auth/AuthContainer';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../utils';
import ConfirmationImage from 'assets/png/confirmation-rocket.png';

const SignupComplete = () => {
  return (
    <AuthContainer>
      <div className="flex justify-center">
        <img src={ConfirmationImage} alt="Confirmation" />
      </div>{' '}
      <h3 className="text-center font-normal text-[#6C6D6D] text-base">
        Signup was successful 💪
      </h3>
      <div className="text-base text-center">
        Your account was successfully created.{' '}
        <span className="text-[#006355] underline">
          <Link to={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.LOGIN}`}>Sign in</Link>
        </span>{' '}
      </div>
    </AuthContainer>
  );
};

export default SignupComplete;
