import React from 'react';
import UploadDocumentIcon from '../icons/UploadDocumentIcon.';
import InvoiceItem from './InvoiceItem';
import { VendorInvoice } from '../../utils';

interface Props {
  invoices: VendorInvoice[];
  isLoading: boolean;
}
const InvoicesList: React.FC<Props> = ({ invoices, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div>
          {invoices.length > 0 ? (
            <div className="grid gap-4 md:gap-2 md:grid-cols-4">
              {invoices.map((invoice) => {
                return (
                  <div key={invoice._id}>
                    <InvoiceItem invoice={invoice} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-center h-[400px]">
              <div className="flex flex-col items-center justify-center space-y-4">
                <UploadDocumentIcon />
                <h1 className="text-[#07000F] font-semibold text-[20px]">
                  No document uploaded yet
                </h1>
                <span className="font-normal text-sm text-[#484847] text-center md:w-[80%]">
                  When your document have been uploaded for approval they shown
                  up here
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InvoicesList;
