import { useState } from 'react';
import { Modal } from 'antd';
import InvoiceSuccessModal from './InvoiceSuccessModal';
import { requestInvoiceFinancing } from 'functions/vendor-functions';
import toast from 'react-hot-toast';
import { useAuth } from 'contexts/AuthContext';

export interface InvoiceFinancingModalProps {
  isOpen: boolean;
  fetchAuditTrail: () => void;
  handleSuccessCancel: () => void;
  invoiceNumber?: string;
  billNumber?: string;
  vendorRef?: string;
  disableFunc: () => void;
}

export const InvoiceFinancingModal = ({
  fetchAuditTrail,
  handleSuccessCancel,
  isOpen,
  invoiceNumber,
  billNumber,
  vendorRef,
  disableFunc,
}: InvoiceFinancingModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { logout } = useAuth();

  const openModal = () => {
    handleSuccessCancel();
    setIsSuccessModalOpen((prev) => !prev);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen((prev) => !prev);
  };

  const handleRequestFinancing = async () => {
    setIsLoading(true);
    const { data, responseError, refreshError } = await requestInvoiceFinancing(
      {
        billNumber: billNumber,
        vendorRef: vendorRef,
      }
    );
    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      return;
    }
    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
      return;
    }
    if (data !== undefined) {
      disableFunc();
      openModal();
      setIsLoading(false);
      fetchAuditTrail();
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={handleSuccessCancel}
        cancelButtonProps={{
          className: 'h-[50px] font-semibold !text-[#004b40]',
        }}
        okText={`${isLoading ? 'Loading...' : 'Continue'}`}
        okButtonProps={{
          className: 'bg-[#006355]  mt-12 h-[50px] font-semibold',
        }}
        onOk={handleRequestFinancing}
      >
        <div className="text-center font-semibold text-md text-[#07000F] border-b-2 pb-2">
          Request financing
        </div>
        <div className="mt-4 space-y-5 text-[#727E8F]">
          <p>
            Get paid for your invoice early. By requesting for invoice
            financing, Duplo will pay you on behalf of your customer, so you can
            get access to your funds on time while your customer can enjoy the
            benefits of paying at a later time.
          </p>
          <p className="pt-3">
            Once you have made your request, we will need to verify that you
            meet our minimum eligibility requirement. You will be notified on
            the status of your request within 48 hours.
          </p>
        </div>
      </Modal>

      <InvoiceSuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        handleSuccessCancel={closeSuccessModal}
        invoiceNumber={invoiceNumber}
        headerText="Request sent successfully!"
        subText="Your invoice financing request has been successfully received. 
        You will be notified on the status of your request within 24 to 48 hours."
        isInvoice
      />
    </>
  );
};
