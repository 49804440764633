import classNames from 'classnames';
import React from 'react';

interface IProps {
  label?: string;
  value?: string;
  setValue: (val: string) => void;
  options: { option: string; value: string }[];
  labelStyles?: string;
  className?: string;
}

const SelectField: React.FC<IProps> = ({
  label,
  value,
  setValue,
  options,
  labelStyles,
  className,
}) => {
  return (
    <div className="flex flex-col space-y-2">
      <span
        className={classNames('text-sm font-light text-[#192850]', labelStyles)}
      >
        {label}
      </span>
      <select
        className={classNames(
          'border-2 rounded-md h-12 outline-[#006355] p-2',

          className
        )}
        onChange={(e) => setValue(e.target.value)}
      >
        <option value="">{options?.length ? 'Select' : 'No Data'}</option>
        {options?.map((option, index) => {
          const isSelected = option.value === value;
          return (
            <option selected={isSelected} key={index} value={option.value}>
              {option.option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectField;
