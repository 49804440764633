import React from 'react';
import { Select } from 'antd';
import { DropdownOptionModel } from 'utils';

interface IProps {
  dropdownOption: DropdownOptionModel[];
  onChange: (s: string) => void;
  selectedOption?: string;
  label?: string | React.ReactElement;
  disabled?: boolean;
}

const DropdownComponent = ({
  dropdownOption,
  onChange,
  selectedOption,
  label,
  disabled,
}: IProps) => {
  return (
    <div>
      <div className="text-sm font-semibold text-[#192850]">{label}</div>
      <Select
        onChange={onChange}
        options={dropdownOption}
        disabled={disabled}
        className="w-full h-12 text-sm"
        value={selectedOption}
        defaultValue={selectedOption}
      />
    </div>
  );
};

export default DropdownComponent;
