import React from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

interface IProps {
  imgSrc: string;
  setCropper: Function;
}

const CropImageComponent: React.FC<IProps> = ({ imgSrc, setCropper }) => {
  return (
    <div>
      <Cropper
        style={{ height: 300, width: 'auto' }}
        zoomTo={0.5}
        aspectRatio={1}
        initialAspectRatio={1}
        preview=".img-preview"
        src={imgSrc}
        viewMode={1}
        scaleX={30}
        scaleY={30}
        cropBoxResizable={false}
        minCropBoxHeight={30}
        minCropBoxWidth={30}
        background={false}
        responsive={true}
        scalable={false}
        autoCropArea={1}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        guides={true}
      />
    </div>
  );
};

export default CropImageComponent;
