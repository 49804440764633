/* eslint-disable prettier/prettier */
import React from 'react';
import { NavLinkExtras, SidenavLink, getIcon } from '../../utils';
import { NavLink } from 'react-router-dom';
import { CloseSquare } from 'react-iconly';
import { useSideNav } from '../../contexts/NavContext';
import { Logout } from 'react-iconly';
import { useAuth } from '../../contexts/AuthContext';

interface IProps {
  navLinks: SidenavLink[];
}
const SideNav: React.FC<IProps> = ({ navLinks }) => {
  const { toggleSideNav } = useSideNav();
  const { logout } = useAuth();

  return (
    <div className="h-full">
      <div className="flex items-center justify-between h-[10%] border-b px-8">
        {getIcon('logo_big')}
        <i className="md:hidden" onClick={() => toggleSideNav(false)}>
          <CloseSquare />
        </i>
      </div>
      {/* Display nav links */}
      <div className="h-[60%] border-b p-8 overflow-y-auto">
        <ul className="space-y-4">
          {navLinks.map((navlink, index) => (
            <li key={index} onClick={() => toggleSideNav(false)}>
              <NavLink
                className={`${({ isActive }: { isActive: boolean }) =>
                  isActive
                    ? 'active'
                    : 'inactive'} flex items-center space-x-4 font-normal text-sm text-[#4A5361] p-3 rounded`}
                to={navlink.path}
              >
                <i>{<navlink.icon />}</i>
                <span>{navlink.text}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      {/* Display Nav extras like settings, logout */}
      <div className="p-8 overflow-y-auto">
        <ul className="space-y-4">
          {NavLinkExtras.map((navlink, index) => (
            <li key={index} onClick={() => toggleSideNav(false)}>
              <NavLink
                className={`${({ isActive }: { isActive: boolean }) =>
                  isActive
                    ? 'active'
                    : 'inactive'} flex items-center space-x-4 font-normal text-sm text-[#4A5361] p-3 rounded`}
                to={navlink.path}
              >
                <i>{<navlink.icon />}</i>
                <span>{navlink.text}</span>
              </NavLink>
            </li>
          ))}

          <li
            className="flex items-center space-x-4 font-normal text-sm text-[#4A5361] p-3 rounded cursor-pointer"
            onClick={logout}
          >
            <i>{<Logout />}</i>
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
