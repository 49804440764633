import { ACCOUNT_SETTINGS_PATHS, ROUTE_PATHS } from './constants';
import Login from 'views/auth/Login';
import Signup from 'views/auth/Signup';
import EnterOtp from 'views/auth/EnterOtp';
import ForgotPassword from 'views/auth/ForgotPassword';
import NewPassword from 'views/auth/NewPassword';
import OperationSuccessfull from 'views/auth/ResetSuccessfull';
import Dashboard from 'views/private/Dashboard';
import Transactions from 'views/private/Transactions';
import InvoiceManagement from 'views/private/InvoiceManagement';
import Reports from 'views/private/Reports';
import Settings from 'views/private/Settings';
import Logout from 'views/auth/Logout';
import Business from 'views/private/settings/Business';
import Security from 'views/private/settings/Security';
import Preferences from 'views/private/settings/Preferences';
import SignupComplete from 'views/auth/SignupComplete';
import Compliance from 'views/private/Compliance';
import ComplianceKyb from 'views/private/settings/Compliance';

export interface AppRoute {
  path: string;
  element: any;
  name?: string;
}
export const AuthRoutes: AppRoute[] = [
  {
    path: ROUTE_PATHS.LOGIN,
    element: Login,
  },
  {
    path: ROUTE_PATHS.SIGNUP,
    element: Signup,
  },
  {
    path: ROUTE_PATHS.VERIFY_OTP,
    element: EnterOtp,
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    element: ForgotPassword,
  },
  {
    path: ROUTE_PATHS.NEW_PASSWORD,
    element: NewPassword,
  },
  {
    path: ROUTE_PATHS.OPERATION_SUCCESSFUL,
    element: OperationSuccessfull,
  },
  {
    path: ROUTE_PATHS.SIGNUP_COMPLETE,
    element: SignupComplete,
  },
];

export const AccountRoutes: AppRoute[] = [
  {
    path: ROUTE_PATHS.DASHBOARD,
    element: Dashboard,
  },
  {
    path: ROUTE_PATHS.TRANSACTIONS,
    element: Transactions,
  },
  {
    path: ROUTE_PATHS.INVOICE_MANAGEMENT,
    element: InvoiceManagement,
  },
  {
    path: ROUTE_PATHS.REPORTS,
    element: Reports,
  },
  {
    path: ROUTE_PATHS.SETTINGS,
    element: Settings,
  },
  {
    path: ROUTE_PATHS.COMPLIANCE,
    element: Compliance,
  },
  {
    path: ROUTE_PATHS.LOGOUT,
    element: Logout,
  },
];

export const AccountSettingsRoutes: AppRoute[] = [
  {
    path: ACCOUNT_SETTINGS_PATHS.BUSINESS,
    element: Business,
  },
  {
    path: ACCOUNT_SETTINGS_PATHS.SECURITY,
    element: Security,
  },
  {
    path: ACCOUNT_SETTINGS_PATHS.PREFERENCES,
    element: Preferences,
  },
  {
    path: ACCOUNT_SETTINGS_PATHS.COMPLIANCE,
    element: ComplianceKyb,
  },
];
