import React, { useState } from 'react';
import AuthContainer from '../../components/auth/AuthContainer';
import Button from '../../components/common/Button';
import PasswordField from '../../components/common/PasswordField';
import axios from 'axios';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { resetPasswordSchema } from 'utils/validationSchema';
import { ResetPassword } from 'utils';
import toast from 'react-hot-toast';
import URL_CONFIG from 'config';

const NewPassword = () => {
  const initialValues = {
    new_password: '',
    confirm_password: '',
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const id = searchParams.get('id');

  // if (!token) {
  //   return <Navigate to="/auth/login" replace />;
  // }

  const handleFormSubmit = async (values: ResetPassword) => {
    setIsProcessing(true);

    try {
      const response = await axios.post(
        `${URL_CONFIG.API_ENDPOINT}/auth/password-reset`,
        {
          reset_token: token,
          vendor_id: id,
          password: values.new_password,
        }
      );

      if (response.data.status === 'SUCCESS') {
        toast.success('password reset successful, kindly check your mail');
        setIsComplete(true);
      }
    } catch (error: any) {
      const errObj = error.response.data;
      toast.error(`${errObj.error_message}`);
    }

    setIsProcessing(false);
  };

  if (isComplete) return <Navigate to="/auth/login" replace />;

  return (
    <AuthContainer headerText="Set new password 🔐">
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordSchema}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit }: FormikProps<ResetPassword>) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <PasswordField name="new_password" label="Password" />
            <PasswordField name="confirm_password" label="Confirm Password" />
            <Button
              text="Reset password"
              disabled={isProcessing}
              type="submit"
            />
          </form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default NewPassword;
