import { useField } from 'formik';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormInputProps } from 'utils';
import { IoInformationCircleOutline } from 'react-icons/io5';

interface IProps extends FormInputProps {
  label?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  setValue: (val: string) => void;
  limit?: number;
  formatToAmount?: boolean;
  delimeter?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: 'password' | 'tel' | 'text' | undefined;
  showInfoIcon?: boolean;
  toolTipId?: string;
}

const TextFieldNumber: React.FC<IProps> = ({
  label,
  value,
  setValue,
  disabled = false,
  placeholder,
  delimeter = false,
  limit,
  onChange,
  type = 'text',
  showInfoIcon,
  toolTipId,
  ...props
}) => {
  // Define a function to handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Ensure only numbers are allowed and limit to 10 characters
    const inputText = limit
      ? e.target.value.replace(/^[1-9]\d*(\.\d+)?$/g, '').slice(0, limit)
      : e.target.value.replace(/\D\./g, '');
    setValue(inputText);
  };
  const [field, meta] = useField(props);

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-sm font-light text-[#192850] flex justify-start items-center gap-1">
        <span>{label}</span>
        {showInfoIcon && (
          <IoInformationCircleOutline data-tooltip-id={toolTipId} size={17} />
        )}
      </div>
      <NumericFormat
        className="border-2 rounded-md h-12 outline-[#006355] p-2"
        type={type}
        {...field}
        onChange={(e) => {
          if (typeof onChange === 'function') {
            onChange(e);
          }
          /* @HINT: `onChange` for <Formik /> from `useField()` */
          if (!e.defaultPrevented) {
            field.onChange(e);
          }
        }}
        value={value}
        onInput={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        thousandSeparator={delimeter}
        decimalScale={2}
        decimalSeparator="."
        min={5}
      />
      {meta.touched && meta.error ? (
        <span className="text-sm text-red-700">{meta.error}</span>
      ) : null}
    </div>
  );
};

interface IPropsNum {
  label?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  setValue: (val: string) => void;
  limit?: number;
  formatToAmount?: boolean;
  delimeter?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: 'password' | 'tel' | 'text' | undefined;
  showInfoIcon?: boolean;
  toolTipId?: string;
}

export const NumberField: React.FC<IPropsNum> = ({
  label,
  value,
  setValue,
  disabled = false,
  placeholder,
  delimeter = false,
  limit,
  type = 'text',
  showInfoIcon,
  toolTipId,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Ensure only numbers are allowed and limit to 10 characters
    setValue(e.target.value.replace(/\D\./g, ''));
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-sm font-light text-[#192850] flex justify-start items-center gap-1">
        <span>{label}</span>
        {showInfoIcon && (
          <IoInformationCircleOutline data-tooltip-id={toolTipId} size={17} />
        )}
      </div>
      <NumericFormat
        className="border-2 rounded-md h-12 outline-[#006355] p-2"
        type={type}
        value={value}
        onInput={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        thousandSeparator={delimeter}
        decimalScale={2}
        decimalSeparator="."
        min={5}
        maxLength={limit}
      />
    </div>
  );
};

export default TextFieldNumber;
