import React, { useRef, useState } from 'react';
import AuthContainer from 'components/auth/AuthContainer';
import PasswordField from 'components/common/PasswordField';
import Button from 'components/common/Button';
import PhoneField from 'components/common/PhoneField';
import FormFooter from 'components/common/FormFooter';
import { Navigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {
  PHONE_CODES,
  ROUTE_PATHS,
  SignUpInterface,
  SignUpSubmitInterface,
} from '../../utils';
import { Formik, FormikProps } from 'formik';
import { signupSchema } from '../../utils/validationSchema';
import InputComponent from '../../components/common/InputComponent';
import toast from 'react-hot-toast';
import { removeHyphens } from 'utils/format';
import config from 'config';
import { rudderAnalytics } from 'utils/rudderEventGenerator';

export default function Signup() {
  const [searchParams] = useSearchParams();
  const vendorName = searchParams.get('name') || '';
  const vendorEmail = searchParams.get('email') || '';
  const token = searchParams.get('token') || '';
  const phone_number = searchParams.get('phone_number') || '';
  const [phoneCode] = PHONE_CODES.filter(({ code }) => code === 'NG');
  const [isSignupComplete, setIsSignupComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const redirectUrl = useRef(ROUTE_PATHS.AUTH + ROUTE_PATHS.VERIFY_OTP);
  const isInvited = vendorName ? true : false;

  const initialValues = {
    business_name: vendorName || '',
    phone_number: phone_number.substring(1) || '',
    email: vendorEmail || '',
    confirm_password: '',
    password: '',
  };

  const handleFormSubmit = async (values: SignUpSubmitInterface) => {
    const path = isInvited ? 'signup-invited' : 'signup';
    setIsProcessing(true);

    try {
      const payload: SignUpInterface = {
        vendorName: values.business_name,
        email: values.email,
        phoneCode: phoneCode?.dial_code,
        password: values.password,
        confirmPassword: values.confirm_password,
        token: token,
        phoneNumber: removeHyphens(values.phone_number),
      };
      const response = await axios.post(
        `${config.API_ENDPOINT}/auth/${path}`,
        payload
      );
      if (response?.data?.status === 'SUCCESS') {
        toast.success('account created successfully');
        if (config.PROD) {
          rudderAnalytics.track(
            'form_submit:vendor_activated',
            {
              label: 'vendor_activated',
              page: document.title,
              page_url: window.location.href,
              page_search: window.location.search,
              vendor_name: vendorName,
              vendor_email: vendorEmail,
              vendor_phone: removeHyphens(values.phone_number),
            },
            {
              integrations: {
                All:
                  config.API_ENDPOINT?.search(/(localhost|staging)\./) === -1,
              },
            },
            () => {
              redirectUrl.current += isInvited
                ? `?flag=skip`
                : `?token=${response.data.otp_token}&email=${values.email}`;
              setIsSignupComplete(true);
            }
          );
        } else {
          redirectUrl.current += isInvited
            ? `?flag=skip`
            : `?token=${response.data.otp_token}&email=${values.email}`;
          setIsSignupComplete(true);
        }
      }
    } catch (error: any) {
      const errObj = error?.response?.data;
      toast.error(
        `${
          errObj?.error_message ||
          'An unkown error occurred, please contact support.'
        }`
      );
    }
    setIsProcessing(false);
  };

  if (isSignupComplete) {
    return <Navigate to={redirectUrl.current} replace />;
  }

  return (
    <AuthContainer headerText="Welcome to Duplo for Vendors 🌟">
      <Formik
        initialValues={initialValues}
        validationSchema={signupSchema}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit }: FormikProps<SignUpSubmitInterface>) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <InputComponent
              label="Business Name"
              name="business_name"
              type="text"
              disabled={!!vendorName}
            />
            <InputComponent
              label="Email Address"
              name="email"
              type="text"
              disabled={!!vendorEmail}
            />

            <PhoneField
              name="phone_number"
              label="Phone Number"
              type="tel"
              disabled={!!phone_number}
            />
            <PasswordField name="password" label="Password" />
            <PasswordField name="confirm_password" label="Confirm Password" />
            <Button
              text="Sign up"
              disabled={isProcessing}
              loading={isProcessing}
            />
          </form>
        )}
      </Formik>
      <FormFooter
        text="Already have an account?"
        link={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.LOGIN}`}
        linkText="Sign in"
      />
    </AuthContainer>
  );
}
