import React, { ReactNode, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { NavLinks, ROUTE_PATHS } from '../../utils';
import SideNav from 'components/nav/SideNav';
import TopNav from 'components/nav/TopNav';
import MobileTopNav from 'components/nav/MobileTopNav';
import { useSideNav } from 'contexts/NavContext';
import { useVendor } from 'contexts/VendorContext';
import { getVendorDetails } from 'functions/vendor-functions';
import toast from 'react-hot-toast';

interface IProps {
  redirectPath?: string;
  children: ReactNode;
}

const PrivateRoute: React.FC<IProps> = ({
  redirectPath = `${ROUTE_PATHS.AUTH}${ROUTE_PATHS.LOGIN}`,
  children,
}) => {
  const { accessToken } = useAuth();
  const { showSideNav } = useSideNav();

  const { logout } = useAuth();
  const { initVendor } = useVendor();

  const fetchVendor = async () => {
    const { data, responseError, refreshError } = await getVendorDetails();
    if (responseError) {
      toast.error(responseError.message);
    }
    // logout if refreshToken failed to refresh
    if (refreshError) {
      logout();
    }
    // set vendor context
    initVendor(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!accessToken) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div className="relative h-screen md:flex">
      <div
        className={`${
          showSideNav ? 'absolute z-[1000]' : 'hidden'
        } h-full bg-[#F8FFFE] w-full top-0 left-0 md:border-r md:block md:w-[20%]`}
      >
        <SideNav navLinks={NavLinks} />
      </div>
      <div className="h-full md:w-[84%]">
        <div className="h-[10%] border-b">
          <MobileTopNav />
          <TopNav />
        </div>
        <div className="h-[90%] mt-2 overflow-y-auto py-6 px-4 md:p-10">
          {children ? <>{children}</> : <Outlet />}
        </div>
      </div>
    </div>
  );
};

export default PrivateRoute;
