import URL_CONFIG from 'config';
import requestWithRetry from './requests';
import {
  AddressType,
  FilterInterface,
  IReqFinance,
  LoanInfoTypeRejection,
  LoanInfotype,
  VendorInfoType,
  VendorType,
} from 'utils';

export async function getVendorDetails() {
  const accessToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user') || '');
  const config = {
    method: 'get',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/${user?.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {},
  };
  const { data, responseError, refreshError } = await requestWithRetry(config);

  return { data, responseError, refreshError };
}

export async function updateVendorDetails(reqBody: any) {
  const accessToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user') || '');
  const config = {
    method: 'patch',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/${user?.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}
export async function updateVendorShippingDetails(reqBody: AddressType) {
  const accessToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user') || '');
  const config = {
    method: 'patch',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/shipping-address/${user?.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}
export async function updateVendorBillingDetails(reqBody: AddressType) {
  const accessToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user') || '');
  const config = {
    method: 'patch',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/billing-address/${user?.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}
export async function updateVendorInformation(reqBody: VendorInfoType) {
  const accessToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user') || '');
  const config = {
    method: 'patch',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/vendor-information/${user?.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}
export async function updateLoanStatus(
  reqBody: LoanInfotype | LoanInfoTypeRejection
) {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'patch',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/invoices/financing/loan`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}

export async function uploadVendorLogoInformation(reqBody: any) {
  const accessToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user') || '');
  const config = {
    method: 'post',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/business-logo/${user?.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}
export async function uploadFileRequest(reqBody: any) {
  const accessToken = localStorage.getItem('accessToken');
  // const user = JSON.parse(localStorage.getItem('user') || '');
  const config = {
    method: 'post',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/upload-file`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}

export async function uploadKYBFiles(reqBody: any) {
  const accessToken = localStorage.getItem('accessToken');
  // const user = JSON.parse(localStorage.getItem('user') || '');
  const config = {
    method: 'post',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/kyb/complete`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}

interface IInvoiceProps {
  isBill?: boolean;
  filters?: FilterInterface;
}

export async function getVendorInvoices({ isBill, filters }: IInvoiceProps) {
  const accessToken = localStorage.getItem('accessToken');
  let url = '';
  if (isBill) {
    url = `${URL_CONFIG.API_ENDPOINT}/vendors/invoices?limit=${filters?.limit}&page=${filters?.page}&isBill=${isBill}`;
  } else {
    url = `${URL_CONFIG.API_ENDPOINT}/vendors/invoices?limit=${filters?.limit}&page=${filters?.page}`;
  }
  const config = {
    method: 'get',
    url: url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {},
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
}

export const ChangePassword = async (reqBody: any) => {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'post',
    url: `${URL_CONFIG.API_ENDPOINT}/auth/change-password`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
};
export const getTransactions = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'get',
    url: `${URL_CONFIG.API_ENDPOINT}/transactions`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {},
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
};

export const getVendorComplianceStatus = async () => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    method: 'get',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/me`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {},
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
};

export const getAuditTrailData = async ({
  businessId,
  invoiceNumber,
  billNumber,
}: Partial<VendorType>) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    method: 'get',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/invoices/audit-logs?business_id=${businessId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {},
    params: {
      bill_number: billNumber,
      invoice_number: invoiceNumber,
      perPage: 10,
      page: 1,
    },
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
};

export const getVendorFinancingStatus = async () => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    method: 'get',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/business/invoice-financing-status`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {},
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
};

export const requestInvoiceFinancing = async (reqBody: IReqFinance) => {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'post',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/invoices/financing`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: reqBody,
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
};

export const getSignedUrl = async (
  reqBody: {
    fileName: string;
    fieldName: string;
    contentType: string;
  }[]
) => {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'post',
    url: `${URL_CONFIG.API_ENDPOINT}/vendors/kyb/generate-presigned-urls`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      files: reqBody,
    },
  };

  const { data, responseError, refreshError } = await requestWithRetry(config);
  return { data, responseError, refreshError };
};
