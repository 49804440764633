import { useState } from 'react';
import AuthContainer from 'components/auth/AuthContainer';
import Button from 'components/common/Button';
import FormFooter from 'components/common/FormFooter';
import axios from 'axios';
import { ROUTE_PATHS, ResetEmail } from 'utils';
import toast from 'react-hot-toast';
import URL_CONFIG from 'config';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { resetPasswordEmail } from 'utils/validationSchema';
import InputComponent from 'components/common/InputComponent';

const ForgotPassword = () => {
  const initialValues: ResetEmail = {
    email: '',
  };
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(values: ResetEmail) {
    setIsProcessing(true);
    try {
      const response = await axios.get(
        `${URL_CONFIG.API_ENDPOINT}/auth/password-reset/${values.email}`
      );
      if (response.data) {
        toast.success('reset password link sent to mail');
        navigate('/auth/operation-successful');
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
    setIsProcessing(false);
  }
  return (
    <AuthContainer headerText="Forgot password 🔐">
      <div className="text-[#6C6D6D] text-sm py-3">
        Enter your email and we'll send you a link to reset your password
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordEmail}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }: FormikProps<ResetEmail>) => (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <InputComponent label="Email Address" name="email" type="text" />
            <Button
              text="Send reset link"
              disabled={isProcessing}
              loading={isProcessing}
              type="submit"
            />
          </form>
        )}
      </Formik>
      <FormFooter
        text="Remember password?"
        link={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.LOGIN}`}
        linkText="Click here"
      />
    </AuthContainer>
  );
};

export default ForgotPassword;
