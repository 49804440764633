import React, { ReactNode } from 'react';
import { Alert } from 'antd';
import { ReactComponent as Icon } from 'assets/svg/exclamation-mark.svg';

interface Props {
  title?: string;
  description: string | ReactNode;
  action: ReactNode;
}

const KYBAlert: React.FC<Props> = ({ description, action }) => (
  <Alert
    className="bg-[#EAF1FA] text-[#003C88] rounded-lg"
    showIcon
    description={<span className="text-[#003C88] text-sm">{description}</span>}
    type="success"
    action={action}
    closable
    banner
    icon={<Icon />}
  />
);

export default KYBAlert;
