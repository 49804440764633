import { Modal } from 'antd';

interface ISuccessModal {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children?: React.ReactNode;
}

const SuccessModal = ({
  open,
  handleClose,
  children,
  title = '',
}: ISuccessModal) => {
  return (
    <>
      <div>
        <Modal
          closable={false}
          maskClosable={false}
          footer={null}
          onCancel={handleClose}
          open={open}
          title={
            <div className="text-lg text-center text-[#07000F] pb-5 border-b">
              {title}
            </div>
          }
        >
          {children}
        </Modal>
      </div>
    </>
  );
};

export default SuccessModal;
