import Avatar from 'components/common/Avatar';
import { useVendor } from 'contexts/VendorContext';
import { useLocation } from 'react-router-dom';
import { formatBreadCrumbs } from 'utils/format';

function TopNav() {
  const location = useLocation();

  const navPageName = formatBreadCrumbs(location?.pathname);
  const { vendor } = useVendor();

  return (
    <div className="items-center justify-between hidden h-full px-10 md:flex">
      <div className="font-medium text-sm text-[#4A5361] capitalize">
        {navPageName?.toLowerCase() === 'kyb' ? 'Compliance' : navPageName}
      </div>
      {!vendor?.logoUrl ? (
        <Avatar name={vendor?.vendorName as string} />
      ) : (
        <img
          src={vendor?.logoUrl}
          alt="Business Logo"
          className="block w-10 h-10 bg-blue-500 rounded-full overflow-clip"
        />
      )}
    </div>
  );
}

export default TopNav;
