import React from 'react';
import { getIcon } from '../../utils';
import { useSideNav } from '../../contexts/NavContext';
import { getInitials } from 'utils/format';
import { useVendor } from 'contexts/VendorContext';

function MobileTopNav() {
  const { toggleSideNav } = useSideNav();
  const { vendor } = useVendor();

  return (
    <div className="flex items-center justify-between px-4 py-8 md:hidden">
      <div onClick={() => toggleSideNav(true)}>{getIcon('hamburger')}</div>
      <div className="w-8 h-8 text-black bg-[#2DB49E] rounded-full flex justify-center items-center uppercase text-lg font-bold">
        {getInitials(vendor?.vendorName as string)}
      </div>
    </div>
  );
}

export default MobileTopNav;
