import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Vendor } from '../utils';

interface VendorContextType {
    vendor: Vendor | null;
    initVendor: (state: Vendor) => void;
}

interface IProps {
    children: ReactNode;
}

const VendorContext = createContext<VendorContextType | undefined>(undefined);

export const VendorProvider: React.FC<IProps> = ({ children }) => {
    const [vendor, setVendor] = useState<Vendor | null>(null);

    const initVendor = (state: Vendor) => setVendor(state);

    return (
        <VendorContext.Provider value={{ vendor, initVendor }}>
            {children}
        </VendorContext.Provider>
    );
};

export const useVendor = () => {
    const context = useContext(VendorContext);
    if (context === undefined) {
        throw new Error('useSideNav must be used within an NavProvider');
    }
    return context;
};
