export const removeHyphens = (number: string) => {
  return number?.replaceAll('-', '');
};

export const removeCommas = (number: string) => {
  return number?.replaceAll(',', '');
};

export const truncateString = (str: string, num: number): string => {
  if (str && str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};
export const getInitials = (name: string) => {
  if (name) {
    const nameSplit = name?.split(' ');
    const firstInitials = nameSplit[0]?.charAt(0);
    const secondInitials = nameSplit[1]?.charAt(0);

    return (
      (firstInitials ? firstInitials : '') +
      (secondInitials ? secondInitials : '')
    );
  }

  return 'O';
};

export const formatBreadCrumbs = (path: string) => {
  return path.split('/').pop()?.replaceAll('-', ' ');
};

export const getChangedValues = <T extends Object>(
  original: T,
  updated: Partial<T>
): Partial<T> => {
  const changedValues: Partial<T> = {};

  for (const key in updated) {
    if (Object.hasOwn(updated, key) && original[key] !== updated[key]) {
      changedValues[key] = updated[key];
    }
  }

  return changedValues;
};

export const capitaliseFirstLetterOfAllWordsInSentence = (sentence: string) => {
  return sentence
    ?.split(' ')
    ?.map((word: string) => {
      return word[0]?.toUpperCase() + word?.substring(1);
    })
    .join(' ');
};
