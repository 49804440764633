import UploadSuccessIcon from '../icons/UploadSuccessIcon';
import moment from 'moment';
import { removeCommas, truncateString } from 'utils/format';
import { Modal } from 'antd';
import classNames from 'classnames';
import { VendorInvoice } from 'utils';

export interface InvoicePreviewProps {
  invoice: VendorInvoice;
  isOpen: boolean;
  handleSuccessCancel: () => void;
  purchaseOrderDoc: string;
  invoiceDoc: string;
}

const UploadedDocumentPreview = ({
  invoice,
  isOpen,
  handleSuccessCancel,
  purchaseOrderDoc,
  invoiceDoc,
}: InvoicePreviewProps) => {
  return (
    <Modal open={isOpen} footer={null} onCancel={handleSuccessCancel}>
      <div className="text-center font-semibold text-md text-[#07000F]">
        Uploaded Document
      </div>
      <section className="bg-[bg-[#F8F9F9]">
        <div className="px-3 py-3">
          <div className="flex items-start justify-between mt-6">
            <div>
              <p className="text-[#484847CC] font-light text-sm leading-4">
                Invoice document
              </p>
              <span className="text-[#006355] font-semibold">
                <div className="flex items-center">
                  <div>
                    <UploadSuccessIcon />
                  </div>
                  <div>{truncateString(invoiceDoc, 13)}</div>
                </div>
              </span>
            </div>
            <div>
              <p className="text-[#484847CC] font-light text-sm leading-4">
                Invoice number
              </p>
              <span className="text-[#1D242E] font-semibold float-right">
                {invoice?.invoiceNumber}
              </span>
            </div>
          </div>
          {(invoice.purchaseOrderNumber || purchaseOrderDoc) && (
            <div className="flex items-center justify-between mt-6">
              <div>
                <p className="text-[#484847CC] font-light text-sm leading-4">
                  Purchase order
                </p>
                {purchaseOrderDoc ? (
                  <div className="text-[#006355] font-semibold">
                    <div className="flex items-center">
                      <div>
                        <UploadSuccessIcon />
                      </div>
                      <div>{truncateString(purchaseOrderDoc, 13)}</div>
                    </div>
                  </div>
                ) : (
                  <div>-</div>
                )}
              </div>
              <div>
                <p className="text-[#484847CC] font-light text-sm leading-4">
                  Purchase order number
                </p>
                <span className="text-[#1D242E] font-semibold float-right">
                  {invoice?.purchaseOrderNumber ?? '-'}
                </span>
              </div>
            </div>
          )}
          <div className="flex items-start justify-between mt-6">
            <div>
              <p className="text-[#484847CC] font-light text-sm leading-4">
                Date created
              </p>
              <span className=" text-[#1D242E] font-semibold">
                {moment(invoice?.createdAt).format('DD/MM/yyyy')}
              </span>
            </div>

            <div>
              <p className="text-[#484847CC] font-light text-sm leading-3 mb-1">
                Document status
              </p>
              <span
                className={classNames(
                  'font-medium text-[10px] inline-block px-2 rounded float-right',
                  {
                    'bg-[#FDEFCB] text-[#D97724] ':
                      invoice.status.toLowerCase() === 'pending',
                    'bg-[#BBE5B3] text-[#414F3E] ':
                      invoice.status.toLowerCase() === 'approved',
                    ' bg-[#CFE0F5] text-[#087FCA]':
                      invoice.status.toLowerCase() === 'declined',
                  }
                )}
              >
                {invoice?.status}
              </span>
            </div>
          </div>

          <div className="mt-6">
            <p className="text-[#484847CC] font-light text-sm leading-4">
              Total Amount
            </p>
            <span className="text-[#1D242E] font-semibold">
              ₦
              {Number(removeCommas(invoice?.totalAmount)).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                }
              ) || '0.00'}
            </span>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default UploadedDocumentPreview;
