import AuthContainer from '../../components/auth/AuthContainer';
import ConfirmationImage from 'assets/png/confirmation-image1.png';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../utils';
import React from 'react';

const OperationSuccessfull = () => {
  return (
    <AuthContainer headerPosition="center">
      <div className="flex justify-center py-5">
        <img src={ConfirmationImage} alt="Confirmation" />
      </div>
      <h1 className="text-[#192850] font-bold text-lg text-center">
        Password reset link sent 🔐
      </h1>
      <div>
        <p className="text-center text-[#6C6D6D] font-normal text-sm">
          A password reset link has been sent to your email, kindly check your
          mail and follow the instructions
        </p>{' '}
        <br />
        <p className="text-sm text-center text-[#6C6D6D]">
          <span className="text-[#006355] underline">
            <Link to={`${ROUTE_PATHS.AUTH}${ROUTE_PATHS.LOGIN}`}>Sign in</Link>
          </span>{' '}
          using your primary email address
        </p>
      </div>
    </AuthContainer>
  );
};

export default OperationSuccessfull;
