/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { redirect } from 'react-router-dom';
import { ROUTE_PATHS } from '../../utils';

const Logout: React.FC = () => {
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        redirect(ROUTE_PATHS.AUTH + ROUTE_PATHS.LOGIN);
    };

    useEffect(() => {
        handleLogout();
    }, []);

    return (
        <div>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default Logout;
