import React from 'react';

export default function UploadSuccessIcon() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M27.3335 13.5865H23.4802C20.3202 13.5865 17.7468 11.0132 17.7468 7.85317V3.99984C17.7468 3.2665 17.1468 2.6665 16.4135 2.6665H10.7602C6.6535 2.6665 3.3335 5.33317 3.3335 10.0932V21.9065C3.3335 26.6665 6.6535 29.3332 10.7602 29.3332H21.2402C25.3468 29.3332 28.6668 26.6665 28.6668 21.9065V14.9198C28.6668 14.1865 28.0668 13.5865 27.3335 13.5865Z"
                fill="#006355"
            />
            <path
                d="M21.0666 2.94682C20.5199 2.40015 19.5732 2.77349 19.5732 3.53349V8.18682C19.5732 10.1335 21.2266 11.7468 23.2399 11.7468C24.5066 11.7602 26.2666 11.7602 27.7732 11.7602C28.5332 11.7602 28.9332 10.8668 28.3999 10.3335C26.4799 8.40015 23.0399 4.92015 21.0666 2.94682Z"
                fill="#006355"
            />
            <path
                d="M16.3731 19.6267C15.9864 19.24 15.3464 19.24 14.9598 19.6267L13.9998 20.5867V15C13.9998 14.4533 13.5464 14 12.9998 14C12.4531 14 11.9998 14.4533 11.9998 15V20.5867L11.0398 19.6267C10.6531 19.24 10.0131 19.24 9.62643 19.6267C9.23976 20.0133 9.23976 20.6533 9.62643 21.04L12.2931 23.7067C12.3064 23.72 12.3198 23.72 12.3198 23.7333C12.3998 23.8133 12.5064 23.88 12.6131 23.9333C12.7464 23.9733 12.8664 24 12.9998 24C13.1331 24 13.2531 23.9733 13.3731 23.92C13.4931 23.8667 13.5998 23.8 13.7064 23.7067L16.3731 21.04C16.7598 20.6533 16.7598 20.0133 16.3731 19.6267Z"
                fill="#006355"
            />
        </svg>
    );
}
