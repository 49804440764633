import React from 'react';

export default function BackButtonIcon() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.076 2H9.938C5.192 2 2 5.332 2 10.288V21.726C2 26.668 5.192 30 9.938 30H22.076C26.822 30 30 26.668 30 21.726V10.288C30 5.332 26.822 2 22.076 2Z"
                fill="#F6F6F8"
            />
            <path
                d="M13.1591 15.6113L17.5592 11.1609C17.7713 10.9464 18.1155 10.9464 18.3276 11.1609L18.8409 11.6801C19.0528 11.8944 19.053 12.2416 18.8418 12.4564L15.3545 15.9999L18.8415 19.5436C19.053 19.7584 19.0526 20.1056 18.8406 20.3199L18.3274 20.8391C18.1153 21.0536 17.7711 21.0536 17.559 20.8391L13.1591 16.3885C12.947 16.1739 12.947 15.8258 13.1591 15.6113Z"
                fill="#4A5361"
            />
        </svg>
    );
}
