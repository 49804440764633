import React, { useState } from 'react';
import { FormInputProps } from '../../utils';
import { useField } from 'formik';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

interface IProps extends FormInputProps {
  label: string;
  value?: string;
  setValue?: (val: string) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const PasswordField: React.FC<IProps> = ({
  label,
  value,
  onChange,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [field, meta] = useField(props);

  return (
    <>
      <div className="flex flex-col py-2">
        <span className="text-sm font-light text-[#192850]">{label}</span>
        <div
          className={`border-2 ${
            isActive ? 'border-[#006355]' : 'border-gray-200'
          } rounded-md h-12 p-2 flex justify-between items-center`}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
        >
          <input
            className="outline-none w-[90%]"
            type={`${showPassword ? 'text' : 'password'}`}
            defaultValue={value}
            {...field}
            onChange={(e) => {
              if (typeof onChange === 'function') {
                onChange(e);
              }
              /* @HINT: `onChange` for <Formik /> from `useField()` */
              if (!e.defaultPrevented) {
                field.onChange(e);
              }
            }}
            {...props}
          />
          <i
            className="cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <FaRegEye color="#DCDDE5" />
            ) : (
              <FaRegEyeSlash color="#DCDDE5" />
            )}
          </i>
        </div>
      </div>
      {meta.touched && meta.error ? (
        <span className="text-sm text-red-700">{meta.error}</span>
      ) : null}
    </>
  );
};

export default PasswordField;
