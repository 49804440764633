import PasswordField from 'components/common/PasswordField';
import { resetPasswordSecuritySchema } from 'utils/validationSchema';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import Button from 'components/common/Button';
import { useNavigate } from 'react-router-dom';
import { ChangePassword } from 'functions/vendor-functions';
import { useAuth } from 'contexts/AuthContext';

export interface IPassword {
  password: string;
  new_password: string;
  confirm_password: string;
}

export default function Security() {
  const initialValues = {
    password: '',
    new_password: '',
    confirm_password: '',
  };

  const [isProcessing, setIsProcessing] = React.useState(false);
  const { logout } = useAuth();

  const navigate = useNavigate();

  const handleFormSubmit = async (values: IPassword) => {
    setIsProcessing(true);
    const payload = {
      oldPassword: values.password,
      newPassword: values.new_password,
      confirmPassword: values.confirm_password,
    };
    const { data, responseError, refreshError } = await ChangePassword(payload);

    if (responseError) {
      const errObj = responseError?.response?.data;
      const errorMessage = (errObj as any)?.error_message
        ? (errObj as any)?.error_message
        : 'Something went wrong';
      toast.error(errorMessage);
      setIsProcessing(false);
      return;
    }

    if (data) {
      toast.success(data?.message || 'password change successfully');
      setIsProcessing(false);
    }

    if (refreshError) {
      logout();
      return;
    }
  };

  return (
    <div className="p-4 space-y-8">
      <h3 className="item-header-large">Security</h3>
      <span className="text-sm font-normal text-gray-500">
        Here you can reset your password should it be compromised.
      </span>
      <div className="md:w-1/2 space-y-6">
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordSecuritySchema}
          onSubmit={(values, helpers) => {
            helpers.resetForm();
            handleFormSubmit(values);
          }}
        >
          {({ handleSubmit }: FormikProps<IPassword>) => (
            <form onSubmit={handleSubmit}>
              <PasswordField name="password" label="Password" />
              <PasswordField name="new_password" label="New Password" />
              <PasswordField name="confirm_password" label="Confirm Password" />
              <div className="flex items-center space-x-4 md:w-[70%] mt-5">
                <button
                  className="w-full p-3 text-sm font-semibold text-gray-600 border rounded-lg"
                  onClick={() => navigate('/account/settings/business')}
                >
                  Cancel
                </button>
                <Button
                  className="text-white font-semibold text-sm p-3 w-full rounded-lg bg-[#006355]"
                  disabled={isProcessing}
                  text="Save Changes"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
